import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as TransactionIcon } from "../icons/report-2.svg";
import InputText1 from "../components/InputText1";
import { jwtDecode } from "jwt-decode";
import Button from "../components/Button";
import { useReactToPrint } from "react-to-print"; // Import the printing hook
import DataTable from "react-data-table-component";
import { formatCurrency } from "../tools/tool";
import LoadingModal from "../components/LoadingModal";
import { adminServices } from "../services/AdminServices";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import withAuthorization from "../components/withAuthorization";
const AccountabilityReport = () => {
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
        return `${year}-${month}-${day}`;
    };

    const [dateToday, setDateToday] = useState(formatDate(new Date()));
    const [userInfo, setUserInfo] = useState({});
    const printRef = useRef(); // Ref for the section to be printed
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
        const token = localStorage.getItem("appToken");
        if (token) {
            const user = jwtDecode(token);
            setUserInfo(user);
            setUserId(user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
        }
    }, []);

    useEffect(()=>{
        if(userId){
            loadAccountabilityReport();
        }
    },[userId])

    // Print function
    const handleExportToPDF = () => {
        const input = printRef.current; // Target the ref content
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("portrait", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            pdf.save(`AccountabilityReport-${dateToday}.pdf`);
        });
    };

    const loadAccountabilityReport = async ()=>{
        setLoading(true);
        let form = new FormData();

        form.append("params",`'${dateToday}',${userId}`);

        await adminServices.storedProcedure("AccountabilityReport",form).then(response => {
            setTransactions(response.data);
        }).catch(error => {
            if(error.status == 401){
                window.location.href = "/";
            }
        }).finally(()=>{
            setLoading(false);
        })
    }

    return (
        <div>
            <LoadingModal show={loading} message={"Loading Report"} />
            <div
                style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <TransactionIcon
                    style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "10px",
                        background: "transparent",
                    }}
                />
                Daily Accountability Report
            </div>
            <hr />
            <div className="d-flex justify-content-start align-items-center mb-3">
                <InputText1
                    title="Date"
                    placeholder="Select Date"
                    name="date"
                    type="date"
                    value={dateToday}
                    onChange={async (e) => {
                        setDateToday(e.target.value)
                        setLoading(true);
                        let form = new FormData();

                        form.append("params",`'${e.target.value}',${userId}`);

                        await adminServices.storedProcedure("AccountabilityReport",form).then(response => {
                            setTransactions(response.data);
                        }).catch(error => {
                            if(error.status == 401){
                                window.location.href = "/";
                            }
                        }).finally(()=>{
                            setLoading(false);
                        })
                    }}
                />
                <Button variant="primary" size="sm" onClick={handleExportToPDF}>
                    Export to PDF
                </Button>
            </div>
            <hr />
            {/* Content to print */}
            <div
                ref={printRef} // Attach the ref here
                className="d-flex justify-content-start flex-column mb-3 w-100 bg-white h-100 p-5 pt-5"
            >
                <div className="row w-100 mb-5">
                    <div className="row w-100 text-center">
                        <h1>Daily Accountability Report</h1>
                    </div>
                </div>
                <div className="row w-100 mt-1">
                    <div className="col fw-bold">Transaction Date</div>
                    <div className="col-4">{new Date(dateToday).toLocaleDateString()}</div>
                    <div className="col fw-bold">Admin User</div>
                    <div className="col-4">
                        {userInfo &&
                            userInfo[
                                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                            ]}
                    </div>
                </div>
                <div className="row w-100 mt-2">
                    <div className="col fw-bold">Total Transactions</div>
                    <div className="col-4">{transactions && transactions.length}</div>
                    <div className="col fw-bold">Total Amount</div>
                    <div className="col-4">
                        { transactions && formatCurrency(transactions.reduce((total, item) => {
                        return item.gross_amount >= 0 ? total + item.gross_amount : total;
                    }, 0)) }
                    </div>
                </div>

                <div className="row w-100 mt-5">
                    <h4>Transaction List:</h4>
                    <DataTable 
                        columns={[
                            {name: "Reference No", selector: row=>row.ref_no, sortable:false},
                            {name: "Sender", selector: row=>row.Sender, sortable:false},
                            {name: "Net Amount", selector: row=>formatCurrency(row.net_amount), sortable:false, right:true, headerStyle:{ 'textAlign': 'center' }},
                            {name: "Interest Rate", selector: row=>formatCurrency(row.interest_rate), sortable:false},
                            {name: "Gross Amount", selector: row=>formatCurrency(row.gross_amount), sortable:false},
                            {name: "Transaction Type", selector: row=>row.type, sortable:false},
                        ]}
                        pagination={false}
                        striped
                        data={transactions}
                    />
                </div>

                <div className="row w-100 mt-5">
                    <div className="col fw-bold">Admin Signature</div>
                    <div className="col-4" style={{borderBottom : "1px solid #000"}}></div>
                    <div className="col fw-bold">Verified By</div>
                    <div className="col-4" style={{borderBottom : "1px solid #000"}}></div>
                </div>

            </div>
        </div>
    );
};

export default withAuthorization(AccountabilityReport, 26);
