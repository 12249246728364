import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar";
import DataTable from "react-data-table-component";
import {ReactComponent as SupplierIcon} from "../../icons/users.svg";
import { adminServices } from "../../services/AdminServices";
import { useAsyncError, useNavigate } from "react-router-dom";
import LoadingModal from "../LoadingModal";
import AlertModal from "../AlertModal";
import Button from "../Button";
import UserFormComponent from "./UserForm";
import { jwtDecode } from "jwt-decode";
const UserComponent = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [email_, setEmail_] = useState("");
    // let _email = "";
    useEffect(() => {
        setLoading(true);
        initializeColumns();
        getSuppliers();

        var token = localStorage.getItem("appToken");
        
        setEmail_(jwtDecode(token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"]);

        return () => {
            console.log("Page initialized");
        }
    },[]);

    const initializeColumns = () => {
        const _columns = [
            { name: 'Last Name', selector: row => row.LastName, sortable: true, },
            { name: 'First Name', selector: row => row.FirstName, sortable: true, },
            { name: 'Email Address', selector: row => row.EmailAddress, sortable: true, },
            { name: 'Phone Number', selector: row => row.MobileNumber, sortable: true, },
            { name: 'Assigned Role', selector: row => row.AssignedRole, sortable: true, },
            {name : 'Super User ?', selector: row=>row.IsAdmin, sortable: true},
        ];

        setColumns(_columns);
    }

    const onEditClick = (e)=>{
        setSelectedData(e);
        setShowFormModal(true);
    }

    const getSuppliers = async() => {
        let form = new FormData();
        form.append('params','');
        await adminServices.storedProcedure('GetAllUsersWithRoles',form).then((res)=>{
            if(res.status === 200){
                if(email_){
                    const filteredUsers = res.data.filter(user => user.EmailAddress !== email_);
                    setData(filteredUsers);
                    setFilteredData(filteredUsers);
                    setLoading(false);

                }

                
            }
        }).catch((error)=>{

            if(error.status === 401){
                navigate('/');
            }

        })
    }

    const handleAfterSubmit = (password)=>{ 
        setSelectedData(null);
        
        switch(password){
            case "204" : 
                setAlertMessage("User updated successfully.");
                break;
            case "409":
                setAlertMessage("User already exists with this email address.");
                break;
            case "406":
                setAlertMessage("Email already exists for a different user");
                break;
            default:
                setAlertMessage("User saved successfully, an email has been sent with his/her credentials." );

                break;
        }
        setShowAlert(true);
    }

    return (<div>
        <UserFormComponent show={showFormModal} data={selectedData}  
        onClose={()=>{ setShowFormModal(false); setSelectedData(null); }}  
        onAfterSubmit={handleAfterSubmit}
        onError={()=>{
            setAlertMessage("Error saving user");
            setShowAlert(true);
        }}
        />
        <LoadingModal show={loading} message={"Loading Users..."} />
        <AlertModal show={showAlert} message={alertMessage} onClose={ async () => { setShowAlert(false); setAlertMessage(""); await getSuppliers(); }}    />
        {/* <div className="flexRow" style={{backgroundColor: '#deafaf'}}>
            <SupplierIcon className="inv-icon crumb"/>
            <h2>Suppliers&nbsp;</h2>
            <div style={{backgroundColor:'red'}}>
                <Button variant={"secondary"} size={'xs'} onClick={() => { setShowFormModal(true); setSelectedData(null); }}> + </Button>
            </div>
        </div> */}
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <SupplierIcon className="inv-icon crumb"/>
                <h2>Users&nbsp;</h2>
            </div>
            <div style={{marginRight: '10px'}}>
                <Button variant={"primary"} size={'xs'} onClick={() => { setShowFormModal(true); setSelectedData(null); }}> + Add User </Button>
            </div>
        </div>

        <div className="flexRow filterDiv">
            <SearchBar
            placeholder="Search Users"
            onChange={(e)=>{
                const value = e.target.value.toLowerCase();
                const filtered = data.filter(item => 
                  Object.keys(item).some(key => 
                    item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                  ) 
                );
                setFilteredData(filtered);
              }}
            />
        </div>

        <div className="spacer-v"></div>
        <div className="tableContainer">
            <DataTable pagination={true}
                columns={columns}
                data={filteredData}
                onRowClicked={onEditClick}
                highlightOnHover
                pointerOnHover/>
        </div>
    </div>)
}

export default UserComponent;