import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Button2 from "../components/Button2";
import InputText2 from "../components/InputText2";
import { ReactComponent as PhotoIcon } from "../icons/photo.svg";
import { ReactComponent as SuccessIcon } from "../icons/success.svg";
import PDLList from "../components/PDLList";
import Dropdown from "../components/Dropdown";
import { adminServices } from "../services/AdminServices";
import AlertModal from "../components/AlertModal";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
import { use } from "react";
document.title = 'PDL Administration | Merchant Onboarding';

const MerchantPage_Add = () => {
  const navigate = useNavigate();
  
  
  const [token, setToken] = useState('');
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState("");
  const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]);
  const [serviceAmount, setServiceAmount] = useState(0);
  const [unit, setUnit] = useState('');

  const [pdlId, setPDLId] = useState(0);
  const [pdlNumber, setPDLNumber] = useState('0000000000');
  const [firstName, setFirstName] = useState('----------');
  const [middleName, setMiddleName] = useState('----------');
  const [lastName, setLastName] = useState('----------');
  const [gender, setGender] = useState('------');
  const [facilityNumber, setFacilityNumber] = useState('----------'); 
  const [openPDLList, setOpenPDLList] = useState(false);
  const [merchantId, setMerchantId] = useState(0);
  const [selectedCat, setSelectedCat] = useState(0);
  const [location, setLocation] = useState('');
  const [posUser, setPosUser] = useState('');
  const [posPassword, setPosPassword] = useState('');
  const [quadrant, setQuadrant] = useState('');

  
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [submitted, setSubmitted] = useState(false);

  const [merchantType, setMerchantType] = useState('');



  // Function to handle adding a new service to the list

  useEffect(()=>{
    getToken();
    getServiceCategories();
  },[]);

  const getToken = ()=>{
    setToken(localStorage.getItem('adminServicesToken'));
  }

  const getServiceCategories= ()=>{
    adminServices.serviceCategories().then((r)=>{
      console.log(r.data);
      let _opt = [];
        _opt.push({
          value: 0,
          label: "--Select Category--"
        });
          for(var i of r.data){
             _opt.push({
              value: i.id,
              label: i.category
            });
          }

          setOptions(_opt);
    }).catch((e)=>{
      console.log(e);
    })
  }

  const addService = () => {
    if (newService.trim() !== "") {
      setServices([...services, { service : newService.trim(), amount: serviceAmount, unit: unit }]);
      setNewService(""); // Clear input after adding
      setServiceAmount(0);
    }
    
  };

  const registerMerchant = ()=>{

      if(pdlId == 0){
        setAlertMessage("Please select a PDL");
        setShowAlert(true);
        setSubmitted(false);
        return;
      }


      if(merchantType == ''){
        setAlertMessage("Please select a Merchant Type");
        setShowAlert(true);
        setSubmitted(false);
        return;
      }

      if(name == ''){
        setAlertMessage("Please enter a Merchant Name");
        setShowAlert(true);
        setSubmitted(false);
        return;
      }

      if(description == ''){
        setAlertMessage("Please enter a Description");
        setShowAlert(true);
        setSubmitted(false);
        return;
      }

      if(location == ''){
        setAlertMessage("Please enter a Location");
        setShowAlert(true);
        setSubmitted(false);
        return;
      }

      if(merchantType == "Services"){
        if(selectedCat == 0){
          setAlertMessage("Please select a category");
          setShowAlert(true);
          setSubmitted(false);
          return;
        }
      }

      if(merchantType == "Store"){
         if(posUser == ""){
          setAlertMessage("Please enter a Username");
          setShowAlert(true);
          setSubmitted(false);
          return;
         }

         if(posPassword == ""){
          setAlertMessage("Please enter a Password");
          setShowAlert(true);
          setSubmitted(false);
          return;
         }
      }

      var postData = {
        "clientId": pdlId,
        "type": merchantType,
        "merchantName": name,
        "description": description,
        "location": location,
        "categoryId" : selectedCat,
        "quadrant" : quadrant,
        "servicesNavigation" : [],
        "posUsers" : [],
        "outlets" : []
      };

      if(merchantType == "Store"){
        postData.posUsers.push({
          username: posUser,
          password: posPassword
        });

        postData.outlets.push({
          "outletName" : name,
          "address" : location,
          "merchant" : "",
          "contactNo" : "-"
        });

        postData.servicesNavigation = [];
        postData.categoryId = null;
      }

      else{
        services.map((s)=>{
            var ss = {
              "serviceCatId": selectedCat,
              "service1": s.service,
              "price": s.amount,
              "unit": s.unit,
              "description": "-"
            }
            postData.servicesNavigation.push(ss);
        });

        postData.outlets = [];
        postData.posUsers = [];
      }
      setSubmitted(true);
      adminServices.registerMerchant(token, postData).then((r)=>{
        if(r.status == 201){
          window.location.href="/merchant/edit/" + r.data.id;
        }
      }).catch((e)=>{
          //alert("Error Saving Merchant");
          if(merchantType == "Store"){
            setAlertMessage("POS Username already exists");
          }
          else{
            setAlertMessage("Error Saving Merchant");
          }
          setShowAlert(true);
          setSubmitted(false);
      });

      
  }

  const selectPDL = ()=>{
    setOpenPDLList(true);
  }

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle form submission (e.g., send data to server)
    // Clear form fields after submission

    

    // setName("");
    // setPhone("");
    // setAddress("");
    // setServices([]);
    // setNewService("");
  };

  //modal function
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openSuccessModal = () => {
    if(!submitted){
      registerMerchant();
    }
    
    //setModalIsOpen(true);
  };

  const closeSuccessModal = () => {
    setModalIsOpen(false);
    navigate("/merchant");
  };

  //image handler
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSelectedRow = (e)=>{
    console.log(e);
    setPDLId(e.id);
    setPDLNumber(e.pdlNumber);
    setFirstName(e.firstName);
    setMiddleName(e.middleName);
    setLastName(e.lastName);
    setGender(e.gender == "M" ? "Male" : "Female");
    setFacilityNumber(e.facilityNumber);
    setOpenPDLList(false);
  }

  const onCategoryChange = (e)=>{
    console.log(e.target.value);
    setSelectedCat(e.target.value);
  }

  return (
    <div>
      <AlertModal
        show={showAlert}
        title="Add Merchant"
        message={alertMessage}
        onClose={()=>{ setShowAlert(false); }}
      />
      <PDLList token={getToken && token}
        isOpen={openPDLList}
        onClose={()=>{setOpenPDLList(false)}}
        selectedInfo={handleSelectedRow}
        ></PDLList>
      <Modal
        className="viewMerchantModal r medium"
        isOpen={modalIsOpen}
        onRequestClose={closeSuccessModal}
      >
        {/* <CloseIcon
          color="black"
          height="25px"
          className="alertCloseButton"
          onClick={closeSuccessModal}
        /> */}
        <div className="flexRow">
          <div style={{ width: "70px" }} className="flexRow">
            <SuccessIcon color="lime" />
          </div>
          <div>
            <p className="xl">Merchant Added!</p>
          </div>
        </div>
      </Modal>

      <p className="xl semibold">Add Merchant</p>
      <div style={{ height: "10px" }}></div>

      <div className="flexRow">
        <p>Enter merchant details and services below:</p>
        <div className="spacer"></div>
        <Button2
          style={{
            margin: "5px",
            width: "150px",
            backgroundColor: "#61dd48",
          }}
          onClick={openSuccessModal}
          type="submit"
          text={"Save merchant"}
        ></Button2>
      </div>

      <div style={{ height: "10px" }}></div>

      <div className="line2"></div>

      <div style={{ height: "10px" }}></div>

      <div style={{ height: "20px" }}></div>

      <div className="addMerchantCard">
        <div className="merchantForm">
          <div className="l semibold">
            PDL Information:
          </div>
          <label>
            <div style={{marginBottom:20, marginTop: 20}}>
                <input type="button" 
                  value={"Select a PDL"}
                  onClick={selectPDL}
                  style={{
                    backgroundColor: '#b0c4de',
                    borderColor: '#b0c4de',
                    fontSize: 15,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontVariant: 'all-petite-caps',
                    color: '#555'
                  }}
                />
            </div>
            <div>
               <div style={{display:'flex',justifyContent:'space-between',marginBottom: 10}}>
                  <div className="bold">PDL Number</div>
                  <div>{pdlNumber}</div>
               </div>
               <div style={{display:'flex',justifyContent:'space-between',marginBottom: 10}}>
                  <div className="bold">First Name</div>
                  <div>{firstName}</div>
               </div>
               <div style={{display:'flex',justifyContent:'space-between',marginBottom: 10}}>
                  <div className="bold">Middle Name</div>
                  <div>{middleName}</div>
               </div>
               <div style={{display:'flex',justifyContent:'space-between',marginBottom: 10}}>
                  <div className="bold">Last Name</div>
                  <div>{lastName}</div>
               </div>
               {/* <div style={{display:'flex',justifyContent:'space-between',marginBottom: 10}}>
                  <div className="bold">Gender</div>
                  <div>{gender}</div>
               </div> */}
               <div style={{display:'flex',justifyContent:'space-between',marginBottom: 10}}>
                  <div className="bold">Facility Number</div>
                  <div>{facilityNumber}</div>
               </div>
            </div>
          </label>
          <div>
          <strong>Merchant Type</strong>
          <div style={{width:300,marginTop:-17,marginLeft:300}}>
            
            <b>Services</b><input style={{marginLeft:20}} type='radio' name="pin" value={"Yes"} onClick={()=>{ 
              setMerchantType('Services');
              setServices([...services, { service : "Generic Services", amount: 0, unit: "service" }]);
              }}/>
              <br />
              <b>Store</b><input style={{marginLeft:43}} type='radio' name="pin" value={"No"} onClick={()=>{ 
              setMerchantType('Store');
              setServices([]);
              }} />
          </div>
          </div>
        </div>
        <div className="merchantForm">
          <p className="l semibold">Enter Merchant Details:</p>
          <div style={{ height: "20px" }}></div>
          <form onSubmit={handleSubmit}>
            <InputText2
              title="Name"
              type="text"
              placeholder="Merchant Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <InputText2
              title="Store Name"
              type="text"
              placeholder="Store Name"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />

            <InputText2
              title="Location"
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
            
            {merchantType == 'Services' && <Dropdown
              title={"Quadrant"}
              data = {[
                {value: "N/A", label: "Not Applicable"},
                {value: "Q1", label: "Quadrant 1"},
                {value: "Q2", label: "Quadrant 2"},
                {value: "Q3", label: "Quadrant 3"},
                {value: "Q4", label: "Quadrant 4"}
              ]}
              onChange={(e) => setQuadrant(e.target.value)}
              value={quadrant}
            />}
            
          </form>

            {merchantType == 'Store' && (<div className="merchantForm">
            <p className="l semibold">Add Store Details:</p>
            <div style={{ height: "20px" }}></div>

            <div className="flexRow">
              <InputText2
                title="POS Username"
                type="text"
                value={posUser}
                onChange={(e) => setPosUser(e.target.value)}
                placeholder="Enter POS Username"
              />
            </div>

            
            <div className="flexRow">
              <InputText2
                title="POS Password"
                type="password"
                value={posPassword}
                onChange={(e) => setPosPassword(e.target.value)}
                placeholder="Enter POS Password"
              />
            </div>

          </div>)}


          {merchantType == 'Services' && ( <div className="flexRow">
          {options && <Dropdown
              title={"Category"}
              data = {options}
              className="r"
              onChange={onCategoryChange}
            >
            </Dropdown>}
          </div>)}

        </div>

        {/* {merchantType == 'Services' && (<div className="merchantForm">
          <p className="l semibold">Add Services Offered:</p>
          <div style={{ height: "20px" }}></div>

          <div className="flexRow">
          {options && <Dropdown
              title={"Category"}
              data = {options}
              className="r"
              onChange={onCategoryChange}
            >
            </Dropdown>}
          </div>

          
          <div className="flexRow">
            <InputText2
              title="Services"
              type="text"
              value={newService}
              onChange={(e) => setNewService(e.target.value)}
              placeholder="Enter a service (Ex. Tech, Food Supply, etc.)"
            />
          </div>

          
          <div>
            <InputText2
              title="Unit"
              type="text"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              placeholder="Unit"
            />
          </div>

          <div>
            <InputText2
              title="Amount"
              type="number"
              value={serviceAmount}
              onChange={(e) => setServiceAmount(e.target.value)}
              placeholder="0.00"
            />
          </div>

          <div>
            <Button2
              onClick={addService}
              style={{
                margin: "5px",
                width: "150px",
                backgroundColor: "#202020",
              }}
              type="button"
              text={"Add Service"}
            ></Button2>
            <ul>
              {services.map((service, index) => (
                <li key={index}>{service.service} - {"Php " + parseFloat(service.amount).toFixed(2) + " per - " + service.unit}</li>
              ))}
            </ul>
          </div>
        </div>)} */}

        

        
      </div>
    </div>
  );
};

export default withAuthorization(MerchantPage_Add,14);
