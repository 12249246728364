import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Button2 from "../components/Button2";
import InputText2 from "../components/InputText2";
import { ReactComponent as PhotoIcon } from "../icons/photo.svg";
import { ReactComponent as SuccessIcon } from "../icons/success.svg";
import PDLList from "../components/PDLList";
import Dropdown from "../components/Dropdown";
import { adminServices } from "../services/AdminServices";
import AlertModal from "../components/AlertModal";
import { useParams } from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import InputText1 from "../components/InputText1";
import DataTable from "react-data-table-component";
import Button from "../components/Button";
import ServiceFormComponent from "../components/merchants/ServiceForm";
import { formatCurrency } from "../tools/tool";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
import DisabledModal from "../components/DisabledModal";
import ConfirmationDialog from "../components/ConfirmationDialog";
document.title = 'PDL Administration | Merchant Onboarding';

const MerchantPage_Edit = () => {
  const navigate = useNavigate();
  const {merchantId} =  useParams();
  const [loading, setLoading] = useState(false);
  const [merchantInfo, setMerchantInfo] = useState({});
  const [merchantName, setMerchantName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [location, setLocation] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedCat, setSelectedCat] = useState(0);
  const [services, setServices] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [display, setDisplay] = useState(false);
  const [serviceData, setServiceData] = useState(null);
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState("");
  const [passwd, setPasswd] = useState("");
  const [status,setStatus] = useState(null);
  const [quadrant, setQuadrant] = useState("");

  // Confirmation Dialog
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");


  useEffect(() => {
    getServiceCategories();
  },[]);

  useEffect(() => {
    getMerchantInfo();
  },[merchantId]);

  useEffect(() => {
    if(merchantInfo){
      if(merchantInfo.merchantType =="Services")
      {
        getServices();
      }
      getPOSUserData();
    }
  },[merchantInfo])

  const onCategoryChange = (e)=>{
    if(e.target.value != 0 && e.target.value != merchantInfo.categoryId){
      setDisplay(true);
    }
    else{
      setDisplay(false);
    }
    setSelectedCat(e.target.value);
    
  }

  const getServiceCategories= ()=>{
      adminServices.serviceCategories().then((r)=>{
        console.log(r.data);
        let _opt = [];
        for(var i of r.data){
            _opt.push({
            value: i.id,
            label: i.category
          });
        }

        setOptions(_opt);
      }).catch((e)=>{
        console.log(e);
      })
  }

  const getMerchantInfo = async () => {
    setLoading(true);
     let formData = new FormData();
     formData.append("params", merchantId);

     await adminServices.storedProcedure("getMerchantInfo", formData).then(response => {
      setMerchantInfo(response.data[0]);
      setMerchantName(response.data[0].merchantName);
      setStoreName(response.data[0].storeName);
      setLocation(response.data[0].location);
      setSelectedCat(response.data[0].categoryId);
      setStatus(response.data[0].status);
      setQuadrant(response.data[0].quadrant);

      if(response.data.length == 0){
        navigate('/merchant/add');
      }
     }).catch(error => {
        error.status == 401 ? navigate('/') : console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  }

  const getPOSUserData = async () => {
    setLoading(true);
     let formData = new FormData();
     formData.append("params", merchantId);

     await adminServices.storedProcedure("getPOSAccount", formData).then(response => {

      setUserName(response.data[0].username);
      setPasswd(response.data[0].password);
      setUserId(response.data[0].userID);
      
     }).catch(error => {}).finally(() => {
      setLoading(false);
     });
  };

  const getServices = async () => {
    setLoading(true);
     let formData = new FormData();
     if(merchantInfo){
        formData.append("params", `${merchantId},${merchantInfo.categoryId}`);

        await adminServices.storedProcedure("get_services_by_merchant_and_category_id", formData).then(response => {
        
          setServices(response.data);
        }).catch(error => {
  
        }).finally(() => {
          setLoading(false);
        });
     }
     
  }

  const  handleSave = () => {
    if(merchantName == "" || storeName == "" || location == ""){
      setShowAlert(true);
      setAlertMessage("Please fill all required fields.Merchant Name, Store Name, and Location are required.");
      return;
    }

    setLoading(true);
    let formData = new FormData();
    formData.append("params", `${merchantId},'${merchantName.replace(/'/g, "''")}','${storeName.replace(/'/g, "''")}','${location.replace(/'/g, "''")}',${selectedCat},'${quadrant}'`);

    adminServices.storedProcedure("UpdateMerchantDetails",formData).then(response => {
      setLoading(false);
      window.location.reload();
    }).catch(error => {

      

       if(error.status == 401){
          window.location.href = "/";
       }

       setAlertMessage(error.message);
       setShowAlert(true);

    }).finally(() => {
      setLoading(false);
    })
  }

  const handleRowClick = (row) => {
      if(row.service == "Generic Services" &&  row.unit == "service"){
         setShowAlert(true);
         setAlertMessage("Generic Service cannot be edited.");
         return;
      }

      let service = {
          "merchantId" : merchantId,
          "categoryId": selectedCat,
          "serviceId": row.id,
          "serviceName": row.service,
          "price": row.price,
          "unit": row.unit,
          "status" : row.status
      }

      setServiceData(service);
      setShowServiceForm(true);

  }

  const handleAfterSubmit = (action) =>{
    setShowAlert(true);
    if(action == "delete"){
      setAlertMessage("Service has been deleted successfully.");
      return;
    }
    if(action == "disabled"){
      setAlertMessage("Service cannot be deleted, but was set to disabled.");
      return
    }
    else{
      setAlertMessage("Service has been saved successfully.");
    }
  }

  const handleReset = async() => {

      if(userName == "" || passwd == ""){
        setShowAlert(true);
        setAlertMessage("Please fill all required fields. Username and Password are required.");
        return;
      }

      let formData = new FormData();
      formData.append("params", `${userId},'${userName}','${passwd}'`);

      await adminServices.storedProcedure("UpdatePostAccount",formData).then(response => {
        if(response.data[0].message == "User successfully updated"){
          setAlertMessage("User successfully updated");
          setShowAlert(true);
        }
        else{
          setAlertMessage(response.data[0].message);
          setShowAlert(true);
        }
      }).catch(error => {
        if(error.status == 401){
          window.location.href = "/";
        }
      }).finally(() => {
        setLoading(false);
      });


  };

  const handleEnable = async() => {
      setStatus(null);
      setLoading(true);
      let formData = new FormData();
      formData.append("params", `1,${merchantId}`);
      await adminServices.storedProcedure("setMerchantStatus",formData).then(response => {
        getMerchantInfo();
      }).catch(error => {
        if(error.status == 401){
          window.location.href = "/";
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleDisable = async() => {

    setShowConfirmation(true);
    setConfirmationMessage("Are you sure you want to disable this merchant?");
  
    
  }


  return (<div>
    <LoadingModal show={loading} message="Please wait" />
    <DisabledModal show={status == 0} onEnable={handleEnable} btnLabel={"Enable Merchant"} message={"Merchant (" + merchantName + ") is inactive. Please set to active first to edit data."}/>
    <AlertModal show={showAlert} title={"Edit Merchant Data"} message={alertMessage} onClose={() => setShowAlert(false)} />
    
    
    {/* Confirmation Dialog For Disabling Merchant*/}
    <ConfirmationDialog show={showConfirmation} message={confirmationMessage} onCancel={() => setShowConfirmation(false)} onConfirm={async ()=> {
      setLoading(true);
      setShowConfirmation(false);
      let formData = new FormData();
      formData.append("params", `0,${merchantId}`);
  
      await adminServices.storedProcedure("setMerchantStatus",formData).then(response => {
        navigate("/merchant");
      }).catch(error => {
        if(error.status == 401){
          window.location.href = "/";
        }
      }).finally(() => {
        setLoading(false);
      });
    }} />


    <ServiceFormComponent show={showServiceForm}
      onClose={() => {setShowServiceForm(false); getServices();}}
      data={serviceData}
      onAfterSubmit={handleAfterSubmit}

      onError={()=>{
        setShowAlert(true);
        setAlertMessage("An error occurred while saving the service. Please try again later.");
      }}
    />
    <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
        Merchant Information
    </div>
    
    {/* PDL Information */}
    <div className="d-flex justify-content-start align-items-center mb-3">

      <div className="col-2 d-flex align-items-center justify-content-between">
        <div className="fw-bold">
          PDL Name
        </div>
        <div className="fw-italic">
          {merchantInfo && merchantInfo.pdlName}
        </div>
      </div>

      <div className="col-2"></div>

      <div className="col-2 d-flex align-items-center justify-content-between">
        <div className="fw-bold">
          PDL Number
        </div>
        <div className="fw-italic">
          {merchantInfo && merchantInfo.pdlNumber}
        </div>
      </div>

    </div>
    
    {/* Merchant Information */}
    <div className="d-flex justify-content-start align-items-center mb-3">

      <InputText1
       title="Merchant Name"
       placeholder="Enter Merchant Name"
       name="merchantName"
       value={merchantName}
       onChange={(e) => setMerchantName(e.target.value)}
      />

    <InputText1
       title="Store Name"
       placeholder="Enter Store Name"
       name="merchantName"
       value={storeName}
       onChange={(e) => setStoreName(e.target.value)}
    />

    <InputText1
       title="Location"
       placeholder="Enter Location"
       name="location"
       value={location}
       onChange={(e) => setLocation(e.target.value)}
    />

    </div>

    <div className="d-flex justify-content-start align-items-center mb-3">
      <div className="col-2 align-items-center justify-content-between">
      {merchantInfo.merchantType == "Services" && <Dropdown
              title={"Category"}
              data = {options}
              className="r"
              onChange={onCategoryChange}
              value={selectedCat}
            >
            </Dropdown>}
          {merchantInfo.merchantType == "Services" && display && <span className="text-danger">All services will be updated under this category on Save.</span> }
      </div>
      <div className="col-2">
        {merchantInfo.merchantType == "Services" && <Dropdown
          title={"Quadrant"}
          data = {[
            {value: "N/A", label: "Not Applicable"},
            {value: "Q1", label: "Quadrant 1"},
            {value: "Q2", label: "Quadrant 2"},
            {value: "Q3", label: "Quadrant 3"},
            {value: "Q4", label: "Quadrant 4"}
          ]}
          onChange={(e) => setQuadrant(e.target.value)}
          value={quadrant}
        />  }
      </div>
      <div className="col d-flex align-items-center justify-content-end">
        <div className="btn-group">
            <Button size={"lg"} onClick={handleSave}>
              Save Merchant Info
            </Button>
            <Button size={"lg"} variant={"danger"} onClick={handleDisable}>
              Disable Merchant
            </Button>
        </div>
      </div>
    </div>

    {merchantInfo.merchantType == "Services" && <div className="card mb-3">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="col">
            Available Services
          </div>
          <Button size={"xs"} variant={"success"} onClick={()=>{
             setServiceData({
                "merchantId": merchantId,
                "categoryId": selectedCat,
                "serviceName": "",
                "serviceId" : null,
                "price": "",
                "unit": "",
                "status" : 1
             });
             setShowServiceForm(true);
          }}> + </Button>
        </div>
        <div className="card-body">
          <DataTable
            columns={[
              {"name" : "Service Name/Product Name", selector: (row)=>row.service ,sortable : true},
              {"name" : "Price", selector: (row)=>formatCurrency(row.price) ,sortable : true,right: true},
              {"name" : "Unit", selector: (row)=>row.unit ,sortable : true},
              {"name" : "Date Added", selector: (row)=>new Date(row.reg_date).toLocaleString() ,sortable : true},
              {"name" : "Status", selector: (row)=>row.status == 1 ? "Active" : "Inactive" ,sortable : true},
            ]}
            data={services}
            onRowClicked={handleRowClick}
            pagination
            highlightOnHover
            pointerOnHover
            striped

          />
        </div>
    </div>}

    {merchantInfo.merchantType == "Store" && <div className="card mb-3">
      <div className="card-header d-flex justify-content-between align-items-center">
           <div className="col">
            POS User Data
          </div>
      </div>
      <div className="card-body">
      <InputText1
        title="Username"
        placeholder="Enter Username"
        name="merchantName"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
       <InputText1
        title="Password"
        placeholder="Enter Password"
        name="merchantName"
        value={passwd}
        onChange={(e) => setPasswd(e.target.value)}
        type="password"
      />
      </div>
      <div className="card-footer d-flex justify-content-end align-items-center">
        <Button size={"lg"} variant={"success"} onClick={handleReset}>Update POS Account</Button>
      </div>
    </div>}

  </div>);
};

export default withAuthorization(MerchantPage_Edit,15);
