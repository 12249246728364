import DataTable from "react-data-table-component";
import Dropdown from "../components/Dropdown";
import React, { useEffect, useState } from "react";
import InputText1 from "../components/InputText1";
import { adminServices } from "../services/AdminServices";
import EditableCell from "../components/EditableCell";
import Button2 from "../components/Button2";
import ConfirmationDialog from "../components/ConfirmationDialog";
import UnauthorizedComponent from "../components/UnauthorizedComponent";


const RFQPage = ()=>{
    const  [columns, setColumns] = useState([]);
    const  [suppliers, setSuppliers] = useState([]);
    const  [products, setProducts] = useState([]);
    const  [showConfirm, setShowConfirm]= useState(false);
    const  [confirmMessage, setConfirmMessage] = useState('');
    const  [supplierId, setSupplierId] = useState(0);
    const [rfqId, setRfqId] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    useEffect(()=>{
        getSuppliers();
    },[]);

    

    const getSuppliers = async ()=>{
        var form = new FormData();
        form.append("params",'');
        await adminServices.storedProcedure("get_suppliers",form).then((r)=>{
            var options  = [{value: "", label: "Select a supplier"}];
            r.data.forEach(element => {
                options.push(element);
            });
            console.log(options);
            setSuppliers(options);
        }).catch((e)=>{
            if(e.response.status == 401){
                window.location.href="/";
            }
        })
    }

    const saveValue = (id, newValue) => {
        setProducts(products.map(item => item.id === id ? { ...item, qty: newValue } : item));
        // var newTotal = int.pa
        // setProducts(products.map(item => item.id === id ? { ...item, total: newTotal } : item));
    };

    const deleteRow = (row) => {
        //console.log(id);
        setProducts(products.filter(item => item.id !== row.id));
        console.log(products);
        console.log(products);
    };

    if(localStorage.getItem('currentRole') != 1) {
        return (<UnauthorizedComponent />);
    }

    return (<div>
        <ConfirmationDialog show={showConfirm}  message={confirmMessage} onCancel={()=>{ setShowConfirm(false)}} onConfirm={()=>{
            window.location.href = "/rfq-list";
        }} />
        <h3>Request for Quotation</h3>
        <div style={{width: '30%'}}>
            <Dropdown title={"Supplier"} data={suppliers} onChange={ async (e)=>{
                var form = new FormData();

                form.append('params',e.target.value);
                setSupplierId(e.target.value);

                await adminServices.storedProcedure('products_by_suppliers', form).then((r)=>{
                    setProducts(r.data);
                }).catch((r)=>{

                });
            }} />
        </div>
        <div class='spacer'></div>
        { (<div>
            <DataTable title="Product List" columns={[
                { name: '-', cell: row => <a style={{textDecoration:'none'}} href="javascript:void(0);" onClick={() => deleteRow(row)}>remove</a>, sortable: false },
                { name: 'ID', selector: data=>data.id , sortable: true },
                { name: 'Product Name', selector: data=>data.product_name, sortable: true },
                { name: 'Sku', selector: data=>data.sku, sortable: true },
                { name: 'Unit Of Measure', selector: data=>data.unitOfMeasure , sortable: true },
                { name: 'Price', selector: data=>"P "+ data.price.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }), sortable: true },
                { name: 'Qty', cell: row => <EditableCell item={"qty"} row={row} onSave={saveValue} type="number" />, sortable: true },
            ]}
            data={products} />
            <div className="flexRow" style={{marginTop: 20}}>
                <Button2
                    text={"Cancel"}
                    style={{ width: "200px", backgroundColor: "#cc2121" }}
                    onClick={()=>{

                    setConfirmMessage("Are you sure you want to exit this page?");
                    setShowConfirm(true);

                    } }
                />
                &nbsp;
                <Button2
                    text={"Submit"}
                    onClick={async ()=>{
                        if(!submitting){
                            setSubmitting(true);
                            await adminServices.addRFQ({ "supplierId" : supplierId}).then( async (r)=> {
                                var id = r.data.rfqId;
                                setRfqId(id);
                                
                               await Promise.all(products.map( async (e)=> {
                                var form = {
                                    rfqId : id,
                                    productId : e.id,
                                    quantity : e.qty,
                                    price : e.price
                                }
    
                                await adminServices.addRFQDetails(form).then((r)=>{
                                    //i++;
                                });
                            }))
    
                            await adminServices.emailRFQ(rfqId).then((r)=>{
                                window.location.href  = "/rfq-list";
                            }).catch((e)=>{
                                console.log(e);
                            });
                            })
                        }
                        
                    }}
                    style={{ width: "200px", backgroundColor: "#61dd48" }}
                />
                {submitting && (<div style={{color:'green'}}>&nbsp;Submitting, please wait!</div>)}
            </div>
        </div>)}
    </div>);
}

export default RFQPage;