import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable from "react-data-table-component";
import {ReactComponent as TransactionIcon} from '../icons/transactions.svg';
import { useNavigate } from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import SearchBar from "../components/SearchBar";
import Button from "../components/Button";
import {formatCurrency} from '../tools/tool';
import InputText1 from "../components/InputText1";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import IcarusServices from "../services/IcarusServices";
import withAuthorization from "../components/withAuthorization";
const IcarusWebApp = () => {
  const navigate = useNavigate();
  const [padalaList, setPadalaList] = useState(null);
  const [data, setData] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [topUpData, setTopUpData] = useState([]);
  const [padalaData, setPadalaData] = useState([]);

  const [selected, setSelected] = useState("topup");

  let columns = [
    { name: 'Transaction Date', selector: data=>new Date(data.transaction_date).toLocaleString(), sortable: true },
    { name: 'Transaction Type', selector: data=>data.type, sortable: true,width: '200px' },
    { name: 'PDL Number', selector: data=>data.type === 'Padala'? data.pdlNumber : 'N/A', sortable: true, defaultSortAsc: false, width: '150px' },
    { name: 'Amount', selector: data=>formatCurrency(data.amount), sortable: true, right: true,
    headerStyle: {
      textAlign: 'center',
    }, },
    { name: 'Reference Number' , selector: data=>data.referenceNo, sortable: true },
    { name: 'Sender', selector: data=>data.accountName, sortable: true },
  ];

  useEffect(()=>{
    setLoading(true);
    getPadala();
  },[]);

  

  const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'IcarusWebApp_Transactions.csv'); link.click(); };

  const getPadala = async ()=>{
    setLoading(true)
    var form = new FormData();
    form.append('params','');
    await IcarusServices.storedProcedure('icarus_transactions',form).then((r)=>{

      let topUp = r.data.filter(item => item.type == 'Topup');
      let padala = r.data.filter(item => item.type == 'Padala');

      setPadalaList(null);
      setPadalaList(r.data);
      setData(r.data);
      setFilteredData(topUp);

      setTopUpData(topUp);
      setPadalaData(padala);

      let gT = topUp.reduce((total, item) => total + item.amount, 0)
      setGrandTotal( gT ) ;

      setLoading(false);

    }).catch((e)=>{
      if(e.status == 401){
        window.location.href = '/';
      }
    });
    

  }

  const PadalaRange = async ()=>{
    var form = new FormData();
    form.append('params',`'${dateFrom}','${dateTo}'`);
    await IcarusServices.storedProcedure('icarus_transactions_range',form).then((r)=>{

      let topUp = r.data.filter(item => item.type == 'Topup');
      let padala = r.data.filter(item => item.type == 'Padala');
      setTopUpData(topUp);
      setPadalaData(padala);

      setPadalaList(null);
      setPadalaList(r.data);
      setData(topUp);
      setFilteredData(topUp);

      let gT = topUp.reduce((total, item) => total + item.amount, 0)
      setGrandTotal( gT ) ;

      
      setLoading(false);

    }).catch((e)=>{
      if(e.status == 401){
        window.location.href = '/';
      }
    });
    

  }

  return (
    <div className="m-5">
      <LoadingModal show={loading} message={"Loading Padala Report"} />
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
          Icarus Web App Transactions ({formatCurrency(grandTotal)})
      </div>

      <div className="flexRow" style={{width:'50%'}}>
          <InputText1 title={"Date From"} type="date" value={dateFrom} onChange={(e)=>{
            setDateFrom(e.target.value);
          }}  />

         <InputText1 title={"Date To"} type="date" value={dateTo} onChange={async(e)=>{
            setDateTo(e.target.value);
          }}  />

          <div style={{marginTop:18}}>
            <Button variant={"primary"} onClick={PadalaRange}>Search</Button>
          </div>

      </div>

      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Transactions"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);

              let gT = filtered.reduce((total, item) => total + item.amount, 0)
              setGrandTotal( gT ) ;
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
      </div>
      <br />
      <div className="d-flex justify-content-start mb-3">
        <Button variant={selected == "topup" ? "primary" : "secondary"} size={'xs'} onClick={()=>{
          setFilteredData(topUpData);
          setData(topUpData);
          let gT = topUpData.reduce((total, item) => total + item.amount, 0)
            setGrandTotal( gT ) ;
            setSelected("topup")
        }}> Top up </Button> 
        &nbsp;&nbsp;
        <Button variant={selected == "padala" ? "primary" : "secondary"} size={'xs'} onClick={()=>{
          setFilteredData(padalaData);
          setData(padalaData);
          let gT = padalaData.reduce((total, item) => total + item.amount, 0)
            setGrandTotal( gT ) ;
            setSelected("padala")
        }}> Padala </Button>
      </div>
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination={false}
          highlightOnHover
          style={{zIndex:0}}
          defaultSortFieldId="reg_date"
      />
    </div>
  );
};

export default withAuthorization(IcarusWebApp,18);
