import React from 'react';
import UnauthorizedComponent from './UnauthorizedComponent';

const withAuthorization = (WrappedComponent, pageId) => {
    return class extends React.Component {
        render() {
            const availablePages = JSON.parse(localStorage.getItem('pages')) || [];
            const isAuthorized = availablePages.includes(pageId);

            return isAuthorized ? <WrappedComponent {...this.props} /> : <UnauthorizedComponent />;
        }
    };
};

export default withAuthorization;
