import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import { adminServices } from "../../services/AdminServices";
import InputText1 from '../InputText1';
import InputText2 from '../InputText2';
import Button from '../Button';
import LoadingModal from "../LoadingModal";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '80%', margin: 'auto', 
        height : '350px'
      
    },
 };

const EmailRecipientsComponent = ({show,onClose})=>{
    const [setting, setSetting] = useState('');
    const [cashOut, setCashOut] = useState('');
    const [adminApprover, setAdminApprover] = useState('');
    const [releasing, setReleasing] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        getRecipients();
    },[]);

    const getRecipients = async () => {
        setLoading(true);
        await adminServices.recipients().then(response => {
            let data = response.data[0];
            setSetting(data.setting);
            setCashOut(data.cashOut); 
            setAdminApprover(data.admin);
            setReleasing(data.releasing);
            setLoading(false);
        }).catch(error => {
            if(error.status == 401){
                window.location.href = "/";
            }
        });
    }

    const handleSave = async () => {
        setLoading(true);
        const data = {
            recordId: 1,
            setting: setting,
            cashOut: cashOut,
            admin: adminApprover,
            releasing: releasing
        }
        await adminServices.updateRecipients(data).then(response => {
            setLoading(false);
            onClose();
        }).catch(error => {
            if(error.status == 401){
                window.location.href = "/";
            }
            console.log(error);
        });
    }

    return (
        <Modal
            
            isOpen={show}
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            onRequestClose={onClose}
        >
            <LoadingModal show={loading} message={"Loading..."} />
         <div style={{display:"flex",justifyContent:'space-between',zIndex:999}}>
                <h3>Automated Email Recipients</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <div className="flexRow">
                <InputText1 title={"Settings Approver"} value={setting} onChange={(e)=>{ setSetting(e.target.value) }} />
                <InputText1 title={"Cash Out Approver"} value={cashOut} onChange={(e)=>{ setCashOut(e.target.value) }} />
                <InputText1 title={"Admin Approver"} value={adminApprover} onChange={(e)=>{ setAdminApprover(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Releasing"} value={releasing} onChange={(e)=>{ setReleasing(e.target.value) }} />
            </div>

            <div className="flexRow" style={{justifySelf:'end'}}>
                    <div>
                        {validated ? <p style={{color:'red',marginTop:10}}>Please fill all fields&nbsp;</p> : ""}
                    </div>
                    <Button variant={"success"} size={'md'} onClick={handleSave}>
                        Save
                    </Button>
                    &nbsp;
                    <Button variant={"secondary"} size={'md'} onClick={onClose}>
                        Cancel
                    </Button>
            </div>
        </Modal>
    )
}

export default EmailRecipientsComponent;