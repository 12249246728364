import React from "react";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import UserComponent from "../components/users/Users";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import {ReactComponent as RoleIcon} from "../icons/role.svg";
import {ReactComponent as UsersIcon} from "../icons/users.svg";
import { jwtDecode } from "jwt-decode";
import DataTable from "react-data-table-component";
import { adminServices } from "../services/AdminServices";
import Button from "../components/Button";
const UserPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [roles, setRoles] = useState([]);
  const [pages, setPages] = useState([]);
  const [allPages, setAllPages] = useState([]);
  const [filterPages, setFilterPages] = useState([]);
  const [showRoles, setShowRoles] = useState(false);
  const [selectedRole, setSelectedRole]  = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const[selectedPage, setSelectedPage] = useState("0");
  const [addRole,setAddRole] = useState("");
  let _email = "";
  useEffect(()=>{
    var token = localStorage.getItem("appToken");
    setIsAdmin(jwtDecode(token)["isAdmin"] === "1");
    
    _email = jwtDecode(token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
    
  },[]);

  useEffect(() => {
    if(showRoles){
      getRoles();
      getPages();
    }
    else{
      setFilterPages([]);
      setAllPages([]);
      setRoles([]);
    }
  },[showRoles])

  const getRoles = async() => {
    let form = new FormData();
    form.append("params",'');
    await adminServices.storedProcedure("getUserRoles",form).then(response => {
      if(response.status === 200){
        setRoles(response.data);
      }
    }).catch(error => {
      if(error.status === 401){
        window.location.href = "/";
      }
    })
  };

  const getPages = async() => {
    let form = new FormData();
    form.append("params",'');
    await adminServices.storedProcedure("getPages",form).then(response => {
      if(response.status === 200){
        setAllPages(response.data);
        setFilterPages(response.data);
      }
    }).catch(error => {
      if(error.status === 401){
        window.location.href = "/";
      }
    })
  };

  if(!isAdmin) {
    return (<UnauthorizedComponent />);
  }


  const handleRoleClick = async (r) => {
    setSelectedRole(r.role_name);
    setSelectedRoleId(r.role_id);
    let form = new FormData();
    form.append("params", r.role_id);
    await adminServices.storedProcedure("getRolePages", form).then(response => {
        if (response.status === 200) {
            // Set the pages from response.data
            setPages(response.data);

            let filtered = getPagesNotInRolePages(allPages, response.data);
            setFilterPages(filtered);
            
        }
    });
}
const getPagesNotInRolePages = (a, b) => {
  const rolePageIds = b.map(page => page.pageId);
  return a.filter(page => !rolePageIds.includes(page.pageId));
};
  

  return (
    <div className="mainContainer">
      <div className="d-flex justify-content-end align-items-center mb-3">
        {!showRoles && <div onClick={()=>{ setShowRoles(true) }}>
          <RoleIcon style={{ width: "30px", height: "30px" }} /> Go To User Roles
        </div>}
      </div>
      {!showRoles && <UserComponent />}

      {showRoles && <div>
        <div onClick={()=>{ setShowRoles(false) }} className="mb-3">
          <UsersIcon style={{ width: "30px", height: "30px" }} />Go To Users
        </div>
         <h3 style={{alignItems: "center",display: "flex",justifyContent: "start"}}><RoleIcon style={{ width: "30px", height: "30px" }} />&nbsp;User Roles</h3>
         <div className="d-flex justify-content-end mb-3">
            
        </div>
        <br />
        <div className="d-flex justify-content-between mb-3">
          <div className="col-5 mr-1" style={{height: "100%"}}>
            <div className="d-flex justify-content-start align-items-center mb-3">
              <span>{"Add Role: "}</span>
              &nbsp;
              <input className="form-control w-50" type="text" placeholder="Enter Role Name" value={addRole} onChange={(e)=>{ setAddRole(e.target.value) }} />
              &nbsp;
              <Button variant="primary" size="xs" onClick={async ()=>{
                if(addRole.trim() !== ""){
                   let form = new FormData();
                  form.append("params", `'${addRole}'`);
                  await adminServices.storedProcedure("AddRole", form).then(async response => {
                    if(response.status === 200){
                      await getRoles();
                      setAddRole("");
                    }
                  }).catch(error => {
                      console.log(error);
                      if(error.status === 401){
                        window.location.href = "/";
                      }
                  });
                }
              }} >Add Role</Button>
            </div>
            <DataTable
              data={roles}
              columns={[
                {name: "Role", selector: row=>row.role_name, sortable: true},
                {name:"Number of Users", selector:row=>row.numberOfUsers, sortable: true}
              ]}
              striped
              pointerOnHover
              onRowClicked={handleRoleClick}/>
          </div>

          <div className="col-6" style={{height: "100%"}}>
            <div className="d-flex justify-content-start align-items-center mb-3">
              <span>{"Selected Role: " + selectedRole}</span>
              &nbsp;
              <select className="form-control w-50" value={selectedPage} onChange={(e)=>{ setSelectedPage(e.target.value) }}>
                <option value="0" selected disabled>Select Page</option>
                {filterPages && filterPages.map(page => {
                  return <option key={page.pageId} value={page.pageId}>{page.pageName}</option>
                } )}
              </select>
              &nbsp;
              <Button variant="primary" size="xs" onClick={async ()=>{
                let form = new FormData();
                form.append("params",`${selectedRoleId},${selectedPage}`);
                await adminServices.storedProcedure("AddUserRolePage", form).then(async response => {
                  if(response.status === 200){
                    handleRoleClick({role_name: selectedRole, role_id: selectedRoleId});
                  }
                });
              }} >Add Page</Button>
            </div>
            <DataTable
                data={pages}
                columns={
                  [{name: "Page", selector: row=>row.pageName, sortable: true},
                  {name: "Action", cell: row=> { return (<Button variant="danger" size="xs" onClick={async ()=>{ 
                      var x = window.confirm("Are you sure you want to delete this page from the selected role?");
                      if(x){
                        let form = new FormData();
                        form.append("params", `${row.recordId}`);
                        await adminServices.storedProcedure("DeleteRolePage", form).then(async response => {
                          if(response.status === 200){
                            handleRoleClick({role_name: selectedRole, role_id: selectedRoleId});
                          }
                        });
                      }
                  }}>Delete</Button>) }, sortable: true}]
                }
                pagination
                striped
                pointerOnHover
              />
          </div>
        </div>  

      </div>}
      {/* Table Footer */}
    </div>
  );
};

export default UserPage;
