import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import Button1 from "../components/Button1";
import Modal from "react-modal";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";

const KioskStatusReports = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(()=>{

    getPadala();

  },[]);

  

  setTimeout(()=>{
    getPadala();
  },60000);

  const getPadala = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('kiosk_status_report',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      console.log(parseInt(r.data[1].as_of));

      var _col = [
        { name: 'App Device ID', selector: data=>data.app_device_id, sortable: true },
        { name: 'Kiosk Name', selector: data=>data.kiosk_name, sortable: true },
        { name: 'Device Status', selector: data=>data.as_of == null ? "New" : parseInt(data.as_of) >=5 ? "Offline" :  data.device_status , sortable: true },
        { name: 'Printer Status', selector: data=>data.printer_status, sortable: true },
        { name: 'FP Scanner Status', selector: data=>data.scanner_status, sortable: true},
        { name: 'Total Transactions', selector: data=>data.totalTransactions, sortable: true},
        { name: 'Last Update', selector: data=>data.as_of != null ? data.as_of +  " ago" : "", sortable: true },
        { name: 'Status', selector: data=>data.device_status_id == 4 ? "Disabled" : "Active", sortable: true },
    ]

    setColumns(_col);

    }).catch((e)=>{
      if(e.response.status == 401){
        window.location.href="/";
      }
    })

  }

  return (
    <div>
      <Modal show={true} />
      <h2>Kiosk Status Report</h2>
      
      <div style={{width: 150,marginBottom:20}}>
        <Button1 onClick={()=>{
          window.location.href="/kiosk-add"
        }
        } text="Add Kiosk" />
      </div>
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
          onRowClicked={(e)=>{
            // console.log(e);
            window.location = "/kiosk-edit/" + e.kiosk_id;
          }}
      />
    </div>
  );
};

export default withAuthorization(KioskStatusReports,19);
