import { useEffect, useState } from "react";
import { adminServices } from "../services/AdminServices";
import Button2 from "../components/Button2";
import DataTable from "react-data-table-component";
import ConfirmationDialog from "../components/ConfirmationDialog";
import AlertModal from "../components/AlertModal";
import { Navigate, redirect, Route } from "react-router-dom";
import {ReactComponent as ProfileIcon} from "../icons/profile-view.svg";
import {ReactComponent as BackIcon} from "../icons/back.svg";
import { useNavigate } from 'react-router-dom'
import LoadingModal from "../components/LoadingModal";
import VoidModal from "./VoidModal";
import SearchBar from "../components/SearchBar";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import Button from "../components/Button";
import {v4 as uuidv4} from 'uuid';
import InputText1 from "../components/InputText1";
import Modal from "react-modal";
import withAuthorization from "../components/withAuthorization";
import { formatCurrency } from "../tools/tool";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '30%', margin: 'auto', 
        height : '100%',
        backgroundColor:'transparent',
        border: 0
    },
  };

const PDLTransactions = ()=>{
    const navigate = useNavigate();
    const [pdlNumber, setPdlNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName,setMiddleName] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [facilityNumber, setFacilityNumber] = useState('');
    const [quadrant, setQuadrant] = useState('');
    const [pin, setPin] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [options, setOptions] = useState([]);
    const [transactions, setTransactions] = useState(null);
    const [columns, setColumns] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert2, setShowAlert2] = useState(false);
    const [alertMessage2, setAlertMessage2] = useState('');

    const [noPin, setNoPin] = useState(false);

    const [showVoid, setShowVoid] = useState(false);
    const [voidRefNo, setVoidRefNo] = useState('');

    const [makeTransfer, setMakeTransfer] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0);
    const [formSeqNo, setFormSeqNo] = useState("");
    const [validationMessage, setValidationMessage] = useState("");
    const [isValidated, setIsValidate] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    
    const [confirmVoid, setConfirmVoid] = useState(false);
    const [voidMessage, setVoidMessage] = useState('');
    const [toVoid, setToVoid] = useState({});
    const [loading, setLoading] = useState(false);

    const [remark, setRemark] = useState('');

    const [locked, setLocked] = useState(false);

    const threeDaysAgo = new Date(); threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

    const data = JSON.parse(atob(localStorage.getItem('selectedPDL')));

    useEffect(()=>{
        //getData();
        setLoading(true);
        setPdlNumber(data.pdlNumber);
        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setDob(data.dob);
        setFacilityNumber(data.facilityNumber);
        setGender(data.gender);
        setStatusId(data.statusId);
        setQuadrant(data.quadrant);
        getClientStatuses();
        getClientTransactions();

        if((data.leftFingerPrint === null && data.rightFingerPrint === null) && (data.pin == null || data.pin === '3973e022e93220f9212c18d0d0c543ae7c309e46640da93a4a0314de999f5112')){
            setLocked(true);
        }

        if(data.leftFingerPrint != null && data.rightFingerPrint != null){
            setNoPin(true);
        }

    },[]);

    
    

    const updateClient = async()=>{

        
    
        if(firstName === ''){
            setShowConfirm(false);
            setAlertMessage("First Name is required");
            setShowAlert(true);
            return;
        }
        
        if(middleName === ''){
            setShowConfirm(false);
            setAlertMessage("Middle Name is required");
            setShowAlert(true);
            return;
        }

        if(lastName === ''){
            setShowConfirm(false);
            setAlertMessage("Last Name is required");
            setShowAlert(true);
            return;
        }
        
        const age = new Date().getFullYear() - new Date(dob).getFullYear();
        
        if(age < 18){

            setShowConfirm(false);
            setAlertMessage("Age must be at least 18 years old");
            setShowAlert(true);
            return;
        }

        if(facilityNumber === ''){
            setShowConfirm(false);
            setAlertMessage("Facility Number is required");
            setShowAlert(true);
            return;
        }

        var form = data;
        form.firstName = firstName;
        form.middleName = middleName;
        form.lastName = lastName;
        form.dob = dob;
        form.gender = gender;
        form.facilityNumber = facilityNumber;
        form.pin = pin == '' ? data.pin : pin;
        form.statusId = statusId;
        form.leftFingerPrint = data.leftFingerPrint;
        form.rightFingerPrint = data.rightFingerPrint;
        form.quadrant = quadrant;

        

        delete form.walletAmount;

        setShowConfirm(false);

        await adminServices.updatePDL(form).then((r)=>{
            if(r.status === 204){
                var append = pin != '' ? "\n\rTemporary PIN: "  + pin + ", kindly take note" : ""
                setAlertMessage("PDL Profile Updated" + append);
                setShowAlert(true);
            }
        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href="/";
            }
            else{
                setAlertMessage(e.response.message);
                setShowAlert(true);
            }
        });
        
    }

    const getClientStatuses = async ()=>{
        var form = new FormData();
        form.append('params','');
        await adminServices.storedProcedure('get_client_statuses',data).then((r)=>{

            setOptions(r.data);

        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href='/';
            }
        });
    }

    const handleButtonClick = (e)=>{
        setToVoid(e);
        // setVoidMessage("Are you sure you want to void this transaction?\n\r" + `${ e.transaction_type} : ${e.amount.toLocaleString('en-US', {
        //     minimumFractionDigits: 2,
        //     maximumFractionDigits: 2,
        //   })}`);
        // setConfirmVoid(true);
        setShowVoid(true);
        setVoidRefNo(e.ref_no);
    }

    const getClientTransactions = async ()=>{
        setLoading(true);
        var form = new FormData();
        form.append('params',data.id);
        setColumns([]);
        await adminServices.storedProcedure('get_client_transactions_by_id',form).then((r)=>{
            setTransactions(r.data);
            setFilteredData(r.data);
            var _col = [
                { name: 'ID', selector: data=>data.id, sortable: true, width: '75px' },
                { name: 'Date', selector: data=>new Date(data.timestamp).toLocaleString(), sortable: true },
                { name: 'Type', selector: data=>data.transaction_type, sortable: true, width: '100px' },
                { name: 'Reference No', selector: data=>data.ref_no, sortable: true, width: '150px' },
                { name: 'Amount', selector: data=>formatCurrency(data.amount), sortable: true,right: true, headerStyle: {
                    textAlign: 'center'
                } },
                { name: 'Remarks', selector: data=>data.remarks, sortable: true },
                { name: 'Action', cell: row => { 
                    const timestampDate = new Date(row.tx_date);
                    const cashOut = [8,9,10].includes(row.transaction_type_id);
                    if(cashOut){
                        return "";
                    }
                    return timestampDate > threeDaysAgo && row.voided === 0 ? ( <button type="button" onClick={() => handleButtonClick(row)}>Void</button> ) : null; }, sortable: false }
            ]
            setColumns(_col);
            setLoading(false);
        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href='/';
            }
        });
    }

    const getClientPurchases = async ()=>{
        setLoading(true);
        var form = new FormData();
        form.append('params',data.id);
        await adminServices.storedProcedure('get_client_purchases',form).then((r)=>{
            setTransactions(r.data);
            setFilteredData(r.data);
            setColumns([]);
            var _col = [
                { name: 'ID', selector: data=>data.id, sortable: true, width: '75px' },
                { name: 'Date', selector: data=>new Date(data.timestamp).toLocaleString(), sortable: true },
                { name: 'Type', selector: data=>data.transaction_type, sortable: true, width: '100px' },
                { name: 'Reference No', selector: data=>data.ref_no, sortable: true, width: '150px' },
                { name: 'Amount', selector: data=>formatCurrency(data.amount), sortable: true,right: true, headerStyle: {
                    textAlign: 'center'
                } },
                { name: 'Remarks', selector: data=>data.remarks, sortable: true },
                { name: 'Action', cell: row => { 
                    const timestampDate = new Date(row.tx_date);
                    const cashOut = [8,9,10].includes(row.transaction_type_id);
                    if(cashOut){
                        return "";
                    }
                    return timestampDate > threeDaysAgo && row.voided === 0 ? ( <button type="button" onClick={() => handleButtonClick(row)}>Void</button> ) : null; }, sortable: false }
            ]
            setColumns(_col);
            setLoading(false);
        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href='/';
            }
        });
    }

    const getClientPadala = async ()=>{
        setLoading(true);
        var form = new FormData();
        form.append('params',data.id);
        setColumns([]);
        await adminServices.storedProcedure('get_client_padala_transactions',form).then((r)=>{
            setTransactions(r.data);
            setFilteredData(r.data);
            var _col = [
                { name: 'ID', selector: data=>data.id, sortable: true, width: '75px' },
                { name: 'Date', selector: data=>new Date(data.timestamp).toLocaleString(), sortable: true, width: '200px' },
                { name: 'Type', selector: data=>data.transaction_type, sortable: true },
                { name: 'Reference No', selector: data=>data.ref_no, sortable: true },
                { name: 'Net Amount', selector: data=>formatCurrency(data.net_amount), sortable: true,right:true, headerStyle: { textAlign: 'center' } },
                { name: 'Interest Rate', selector: data=>formatCurrency(data.interest_rate), sortable: true,right:true, headerStyle: { textAlign: 'center' } },
                { name: 'Gross Amount', selector: data=>formatCurrency(data.gross_amount), sortable: true,right:true, headerStyle: { textAlign: 'center' } },
                { name: 'Bank Reference', selector: data=>data.bank_reference, sortable: true },  
                { name: 'Remarks', selector: data=>data.remarks + "-" + data.bank_reference, sortable: true },
                { name: 'Action', cell: row => { 
                    const timestampDate = new Date(row.tx_date); 
                    const cashOut = [8,9,10].includes(row.transaction_type_id);
                    if(cashOut){
                        return "";
                    }
                    return  timestampDate > threeDaysAgo && row.voided === 0  ? ( <button type="button" onClick={() => handleButtonClick(row)}>Void</button> ) : null; 
                }, sortable: false }
            ]
            setColumns(_col);
            setLoading(false);
        }).catch((e)=>{
            if(e.response.status === 401){
                window.location.href='/';
            }
        });
    }

    const voidTransaction= async ()=>{

        var form = {
            "id": toVoid.id,
            "clientId": toVoid.client_id,
            "transactionTypeId": toVoid.transaction_type_id == 3 ? 5 : 4,
            "refNo": toVoid.ref_no,
            "amount": toVoid.amount,
            "remarks": toVoid.remarks + ";Voided",
            "timestamp": toVoid.timestamp,
        }

        await adminServices.updateClientTransaction(form).then(async (r)=>{
            if(r.status == 204){
                var amt = toVoid.transaction_type_id == 1 ? toVoid.amount * -1 : Math.abs(toVoid.amount);

                delete form.id;
                form.amount = amt;
                form.transactionTypeId = toVoid.transaction_type_id == 1 ? 7 : 6;
                form.remarks = "Voided from transaction id:" + toVoid.id;
                
                await adminServices.addClientTransaction(form).then((r)=>{

                    setAlertMessage("Transaction Successfully Voided");
                    setShowAlert(true);
                    setConfirmVoid(false);
                    getClientTransactions();
                    
                }).catch((e)=>{
                    if(e.response.status != 401){
                        setAlertMessage("Error Crediting Transaction");
                        setShowAlert(true);
                        setConfirmVoid(false);
                    }
                    else{
                        window.location.href = "/";
                    }
                })
            }
        }).catch((e)=>{
            if(e.response.status != 401){
                setAlertMessage("Error Voiding Transaction");
                setShowAlert(true);
                setConfirmVoid(false);
            }
            else{
                window.location.href = "/";
            }
        })

    }
   
    const generateRandomNumber = () => {
        const min = 100000;
        const max = 999999;
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        return number;
    };

    const handleTransfer = ()=>{
          setMakeTransfer(true);
      }
    
    const validateForm = ()=>{
        
        if(transferAmount <= 0){
          setIsValidate(false);
          setValidationMessage("Cash Out Amount should be at least 1");
          return false;
        }
    
        if(formSeqNo === ""){
          setIsValidate(false);
          setValidationMessage("Form Sequence Number Required.");
          return false;
        }

        if(remark === ""){
            setIsValidate(false);
            setValidationMessage("Remarks Required.");
            return false;
        }
    
        return true;
      }
    
      const handleSubmit = async()=>{
          if(validateForm()){
            setLoading(true);
            const formData = new FormData();
    
            let uuid = uuidv4();
            let refNo = uuid.split('-')[4];
            let remarks = "Cash Out Request SQN: " + formSeqNo + ";PDL Number: " + pdlNumber + `(${firstName} ${lastName});Remarks: ${remark}`;
            formData.append('params',`${data.id},8,'${refNo.toUpperCase()}',${transferAmount},'${remarks}'`);
            
            await adminServices.storedProcedure('TransferFundClient',formData).then(async response => {
              if(response.data[0].Status == "202"){
                

                let form = new FormData();
                form.append("ReferenceNo", refNo.toUpperCase());
                form.append("TraceNo", formSeqNo);
                form.append("PdlNumber", pdlNumber);
                form.append("PdlName", `${firstName} ${lastName}`);
                form.append("Amount", transferAmount);
                form.append("Remarks", remark);

                await adminServices.emailApproval(form).then(response => {
                    setIsSuccess(true);
                    setShowAlert2(true);
                    setAlertMessage2("PDL Cash Out Request has been submitted Ref No: " + refNo.toUpperCase());
                }).catch(error => {

                }).finally(()=>{
                    setLoading(false);
                });

              }
              else{
                setShowAlert2(true);
                setAlertMessage2("Error while transferring funds to IPX. Please try again later.");
                setIsSuccess(false);
              }
            }).catch(error => {
              if(error.status === 401){
                window.location.href = "/";
              }
            }).finally(()=>{
              setMakeTransfer(false);
            });
          }
    
          
      }
      
      const handleClose = ()=>{  
        
        setShowAlert(false);
    
        if(isSuccess){ 
          window.location.href = "/pdl-management";
        }
      }

    return (<div>
        <LoadingModal show={loading} message={"Loading PDL Transactions..."} />
        <AlertModal show={showAlert2} title="Cash Out Request" message={alertMessage2} onClose={handleClose} />
        <Modal style={customStyles} isOpen={makeTransfer} onRequestClose={()=>{ setMakeTransfer(false); }}>
            <div className="card">
                <div className="card-header">Make a cash out request</div>
                <div className="card-body d-flex flex-column align-item-center">
                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Amount to Transfer"} onChange={(e)=>{
                          setValidationMessage("");
                          setIsValidate(true);
                          if(e.target.value == "e"){
                            setIsValidate(false);
                            setValidationMessage("Invalid Amount");
                          }

                          if(e.target.value == ""){
                            setIsValidate(false);
                            setValidationMessage("Cash Out Amount should be at least 1");
                          }

                          if(e.target.value <=0){
                            setIsValidate(false);
                            setValidationMessage("Cash Out Amount should be at least 1");
                          }

                          if(e.target.value > data.walletAmount){
                            setIsValidate(false);
                            setValidationMessage("Cash Out Amount cannot be greater than PDL Wallet");
                          }

                          setTransferAmount(e.target.value);

                      }} value={transferAmount} type="number" />
                    </div>
                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Form Sequence Number"} onChange={(e)=>{
                          const regex = /^[a-zA-Z0-9]*$/;
                          if (regex.test(e.target.value)) {
                            setFormSeqNo(e.target.value.toUpperCase());
                          }
                          
                      }} value={formSeqNo} />
                    </div>

                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Remarks"} onChange={(e)=>{
                          setRemark(e.target.value);
                          
                      }} value={remark} placeholder={""}/>
                    </div>

                    <div className="text-danger">
                        {!isValidated && validationMessage}
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                    <Button variant={"success"} onClick={handleSubmit}>Submit</Button>
                    &nbsp;
                    <Button variant={"danger"} onClick={()=>{ 
                       setMakeTransfer(false);
                    }}>Cancel</Button>
                </div>
            </div>
        </Modal>
        <VoidModal toVoid={toVoid} voidEvent={voidTransaction} refNo={voidRefNo} show={showVoid} onClose={()=>{ setShowVoid(false); }}  />
        <div style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', marginBottom: '20px'}}>
            <div>
                <BackIcon style={{ width: "50px", height: "50px",background:'transparent',marginRight: '20px' }} onClick={()=>{
                navigate('/pdl-management');
                }}/>
                <ProfileIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
                {firstName} {lastName}
            </div>
            <div className="d-flex justify-content-end">
                <Button variant={"primary"} size={"lg"} onClick={handleTransfer}> Cash Out Request </Button>
            </div>
        </div>
        <br></br>
        <ConfirmationDialog 
            title={"Void Transaction"}
            show={confirmVoid}
            message={voidMessage}
            onCancel={()=>{ setConfirmVoid(false); }}
            onConfirm={()=>{ voidTransaction() }}
        >

        </ConfirmationDialog>
        <AlertModal
            show={showAlert}
            title={"PDL Updates"}
            onClose={()=>{ setShowAlert(false); }}
            message={alertMessage}
        >
        </AlertModal>
        <ConfirmationDialog
        show={showConfirm}
        title={"PDL Profile"}
        message={"Are you sure you want to update the PDL Profile?"}
        onCancel={()=>{ setShowConfirm(false); }}
        onConfirm={()=>{ updateClient() }}
        ></ConfirmationDialog>
        <div style={{
            display:"flex",
            flexDirection:'row'
        }}>
        
            <table width={'70%'} class='pdl-info'>
                    <tr>
                        <th>PDL Number</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                    </tr>
                    <tr>
                        <td>
                            {pdlNumber}
                        </td>
                        <td>
                            <input class='pdl-text' type='text' value={firstName} onChange={(e)=>{ 
                                    const regex = /^[a-zA-Z0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                        setFirstName(e.target.value)
                                    }
                                }} />
                        </td>
                        <td>
                            <input class='pdl-text' type='text' value={middleName} onChange={(e)=>{ 
                                    const regex = /^[a-zA-Z0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                        setMiddleName(e.target.value)
                                    }
                                    
                                }} />
                        </td>
                        <td>
                            <input class='pdl-text' type='text' value={lastName} onChange={(e)=>{ 
                                    
                                    const regex = /^[a-zA-Z0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                        setLastName(e.target.value)
                                    }

                                 }} />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Date Of Birth</th>
                        <th>Gender</th>
                        <th>Facility Number</th>
                        <th>Quadrant</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input class='pdl-text' type='date' value={dob} onChange={(e)=>{ setDob(e.target.value) }} style={{width:'50%'}} />
                        </td>
                        <td>
                            <select  class='pdl-text' onChange={(e)=>{setGender(e.target.value)}} style={{width:'70%'}}>
                                <option value='M' selected={ data.gender === 'M' ? 'selected': '' }>Male</option>
                                <option value='F' selected={ data.gender === 'F' ? 'selected': '' }>Female</option>
                            </select>
                        </td>
                        <td>
                            
                            <input class='pdl-text' type='text' value={facilityNumber} onChange={(e)=>{ 
                               
                                    const regex = /^[a-zA-Z0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                        setFacilityNumber(e.target.value)
                                    }
                                }}  />
                        </td>
                        <td>
                            <select class='pdl-text' value={quadrant} onChange={(e)=>{setQuadrant(e.target.value)}} style={{width:'100%'}}>
                                 <option value={"N/A"}>Not Applicable</option>
                                 <option value={"Q1"}>Quadrant 1</option>
                                 <option value={"Q2"}>Quadrant 2</option>
                                 <option value={"Q3"}>Quadrant 3</option>
                                 <option value={"Q4"}>Quadrant 4</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={4}>Status</th>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <select class='pdl-text' onChange={(e)=>{ setStatusId(e.target.value) }} style={{width:'50%'}}>
                                    {options && options.map((option) => (
                                        <option key={option.status_id} value={option.status_id} selected={ data.statusId === option.status_id ? 'selected' : '' }>
                                            {option.description}
                                        </option>
                                    ))}
                            </select>
                        </td>
                        {!noPin &&<td>
                            <strong>Enable Pin?</strong>&nbsp;
                            Yes<input type='radio' name="pin" value={"Yes"} onClick={()=>{ setPin(generateRandomNumber()); }}/>
                            No<input type='radio' name="pin" value={"No"} onClick={()=>{ setPin(''); }} />
                        </td>}
                        <td>
                            <Button2
                                text={"Update PDL"}
                                onClick={()=>{ 
                                    if(locked){
                                        setAlertMessage("PDL is not fully registered. Please complete onboarding process before updating profile.");
                                        setShowAlert(true);
                                        return;
                                    }
                                    setShowConfirm(true);
                                 }}
                                style={{ width: "200px", backgroundColor: "#61dd48" }}
                            />
                        </td>
                    </tr>
            </table>
           
        </div>
        <div style={{width:'100%'}}>
            <br />
            <h3>PDL Transactions</h3>
            <div className="flexRow">
                <div className="flexRow filterDiv">
                <SearchBar
                    placeholder="Search Transaction"
                    onChange={(e)=>{
                    const value = e.target.value.toLowerCase();
                    const filtered = transactions.filter(item => 
                        Object.keys(item).some(key => 
                        item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                        ) 
                    );
                    setFilteredData(filtered);
                    }}
                />
                </div>
                &nbsp;&nbsp;
                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
                >
                </div>
                <div className="spacer"></div>
                <input type="button" value={"All Transactions"} onClick={async ()=>{
                    await getClientTransactions();
                }} />
                <input type="button" value={"Padala"} onClick={async ()=>{
                    await getClientPadala();
                }} />
                <input type="button" value={"Purchases"} onClick={async ()=>{
                    await getClientPurchases();
                }} />
            </div>
            <br />
            <div>
                
            </div>
            <DataTable 
                columns={columns}
                data={filteredData}
                pagination={false}
                overflowY="scroll"
                striped
                persistTableHead
                highlightOnHover
            />
        </div>
    </div>);

}

export default withAuthorization(PDLTransactions,3);