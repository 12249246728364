import React from 'react';
import Button from './Button';

const DisabledModal = ({ show, message, btnLabel ,onEnable }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="jmodal-overlay">
      <div className="jmodal-content">
        
        <div className='d-flex justify-content-start align-items-center mb-2'>
            <a href='javascript:void(0)' onClick={()=>{ window.history.back(); }} className='text-dark text-decoration-none'> <i className="fa fa-arrow-left"></i>Go Back </a>
        </div>
        <h3>Disabled Data</h3>
        <p>{message}</p>
        <div className="jmodal-buttons">
         <Button variant={"success"} classNames={"btn-sm"} size={"sm"} onClick={onEnable}>{btnLabel}</Button>
        </div>
      </div>
      
    </div>
  );
};
export default DisabledModal;