import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import {adminServices} from "../services/AdminServices";
import InputText1 from './InputText1';
import Button from './Button';
import LoadingModal from "./LoadingModal";
import { jwtDecode } from "jwt-decode";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '500px', margin: 'auto', 
        height : '500px'
      
    },
 };

const PasswordResetForm = ({show,onClose})=>{
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    var token = localStorage.getItem("appToken");
    let userId = jwtDecode(token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

    useEffect(()=>{
        
    },[]);

    const validateForm = () => {
        if(oldPassword == "" || newPassword == "" || confirmPassword == ""){
            return false;
        }

        if(newPassword != confirmPassword){
            window.alert("New Password and Confirm Password do not match. Please try again.");
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {
        if(validateForm()){
            setLoading(true);
            let formData = new FormData();
            formData.append("UserId", userId);
            formData.append("OldPassword", oldPassword);
            formData.append("NewPassword", newPassword);

            await adminServices.passwordReset(formData).then((response) => {
                if(response.status === 200){
                    setLoading(false);
                    window.alert("Password reset successful");
                    window.location.reload();
                }
            }).catch(error => {

                if(error.status == 401){
                    window.location.href = "/";
                }

                if(error.status == 409){
                    setLoading(false);
                    window.alert("Old Password is incorrect. Please try again.");
                }

                if(error.status == 404){
                    setLoading(false);
                    window.alert("User data does not exist. Please try again.");
                }

                if(error.status == 500){
                    setLoading(false);
                    window.alert("Internal Server Error. Please try again.");
                }

            })
            
        }
        else{
            setValidated(true);
        }
    }

    return (
        <Modal
            
            isOpen={show}
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            onRequestClose={onClose}
        >
        <LoadingModal show={loading} message={"Saving data..."} />
         <div style={{display:"flex",justifyContent:'space-between',zIndex:999}} className="mb-3">
                <h3>Reset Password</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <div className="flexRow">
                <InputText1 type="password" title={"Old Password"} value={oldPassword} onChange={(e)=>{ setOldPassword(e.target.value) }} />
            </div>
            <div className="flexRow">
                <InputText1 type="password" title={"New Password"} value={newPassword} onChange={(e)=>{ setNewPassword(e.target.value) }} />
            </div>
            <div className="flexRow">
                <InputText1 type="password" title={"Confirm Password"} value={confirmPassword} onChange={(e)=>{ setConfirmPassword(e.target.value) }} />
            </div>

            <div className="flexRow" style={{justifySelf:'end'}}>
                    <div>
                        {validated ? <p style={{color:'red',marginTop:10}}>Please fill all fields&nbsp;</p> : ""}
                    </div>
                    <Button variant={"success"} size={'md'} onClick={handleSubmit}>
                        Submit
                    </Button>
                    &nbsp;
                    <Button variant={"secondary"} size={'md'} onClick={onClose}>
                        Cancel
                    </Button>
            </div>
        </Modal>
    )
}

export default PasswordResetForm;