import { useState } from "react";
import InputText1 from "../components/InputText1";
import Button2 from "../components/Button2";
import AlertModal from "../components/AlertModal";
import { adminServices } from "../services/AdminServices";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";

const KioskPageAdd = ()=>{
    const [deviceId, setDeviceID] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [kioskName, setKioskName] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [kioskPin, setKioskPin] = useState('');
    const [quadrants, setQuadrants] = useState([]);
    

    const handleCheckboxChange = (value) => {
        setQuadrants((prev) => {
            if (prev.includes(value)) {
                return prev.filter((item) => item !== value); // Remove the item if it's already in the array
            } else {
                return [...prev, value]; // Add the item if it's not in the array
            }
        });
    };

    return (<div style={{width:'80%'}}>
         <h2>Add Kiosk Terminal</h2>
         <AlertModal title={"Add Kiosk Terminal"} show={showAlert} message={alertMessage} onClose={()=>{setShowAlert(false)}}></AlertModal>
        <form className="padalaForm">
        <InputText1 title={"Kiosk Device ID"}
            value={deviceId}
            placeholder={"App Device ID"}
            onChange={(e)=>{
                setDeviceID(e.target.value);
            }}
        />

        <InputText1 title={"Serial Number ID"}
            value={serialNumber}
            placeholder={"Serial Number"}
            onChange={(e)=>{
                setSerialNumber(e.target.value);
            }}
        />

        <InputText1 title={"Kiosk Name"}
            value={kioskName}
            placeholder={"Kiosk Name"}
            onChange={(e)=>{
                setKioskName(e.target.value);
            }}
        />

        <InputText1 title={"Kiosk Password"}
            value={kioskPin}
            type="password"
            placeholder={"Assign a Kiosk Password"}
            onChange={(e)=>{
                setKioskPin(e.target.value);
            }}
        />

        
        </form>

        <div className="w-100 d-flex justify-content-start mt-3 mb-3">
            <div className="row">
                <div className="col">
                    Not Applicable
                </div>
                <div className="col">
                    <input type="checkbox" checked={ quadrants.includes("N/A") ? true : false } value={"N/A"} onClick={() => handleCheckboxChange("N/A")} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Quadrant 1
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q1"} checked={ quadrants.includes("Q1") ? true : false } onClick={() => handleCheckboxChange("Q1")} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Quadrant 2
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q2"} checked={ quadrants.includes("Q2") ? true : false } onClick={() => handleCheckboxChange("Q2")} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Quadrant 3
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q3"} checked={ quadrants.includes("Q3") ? true : false } onClick={() => handleCheckboxChange("Q3")} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Quadrant 4
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q4"} checked={ quadrants.includes("Q4") ? true : false }onClick={() => handleCheckboxChange("Q4")} />
                </div>
            </div>

            
        </div>

        <Button2
            text={"Submit"}
            onClick={async ()=>{
               if(deviceId === ''){
                setAlertMessage('Kiosk Device ID Required');
                setShowAlert(true);
                return;
               }

               if(serialNumber === ''){
                setAlertMessage('Serial Number Required');
                setShowAlert(true);
                return;
               }

               if(kioskName === ''){
                setAlertMessage('Kiosk Name Required');
                setShowAlert(true);
                return;
               }

               var form  = {
                    "appDeviceId" : deviceId,
                    "serialNumber" : serialNumber,
                    "kioskName" : kioskName,
                    "devicePin" : kioskPin,
                    "quadrants" : quadrants.join('|')
               }

               await adminServices.addKiosk(form).then((r)=>{
                if(r.status == 201 || r.status == 204 || r.status == 200){
                    window.location.href="/kiosk-status-reports";
                }
               }).catch((e)=>{
                    if(e.status == 401){
                        window.location.href = "/";
                    }

                    if(e.status == 500){
                        setAlertMessage("An error occured, please check for duplicate entries");
                        setShowAlert(true);
                    }
               });
                
            }}
            style={{ width: "200px", backgroundColor: "#61dd48" }}
            />
    </div>);
}

export default withAuthorization(KioskPageAdd,20);