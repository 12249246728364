import React, { useEffect } from "react";
import { Form, Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as DashboardIcon } from "../icons/dashboard.svg";
// import { ReactComponent as ProfileIcon } from "../icons/profile.svg";
import { ReactComponent as UserGroupIcon } from "../icons/user-group.svg";
import { ReactComponent as UsersIcon } from "../icons/users.svg";
import { ReactComponent as ReceiptIcon } from "../icons/receipt.svg";
import { ReactComponent as LoadIcon } from "../icons/load.svg";
import { ReactComponent as SupportIcon } from "../icons/support.svg";
import { ReactComponent as FlagIcon } from "../icons/flag.svg";
// import { ReactComponent as SettingsIcon } from "../icons/settings.svg";
import { ReactComponent as ShoppingBagIcon } from "../icons/shopping-bag.svg";
import { ReactComponent as ReceiptHistoryIcon } from "../icons/receipt-history.svg";
import { ReactComponent as MerchantIcon } from "../icons/merchant.svg";
import { ReactComponent as ColumnIcon } from "../icons/column.svg";
// import { ReactComponent as LogOutIcon } from "../icons/logout.svg";
import { ReactComponent as ReportIcon} from "../icons/report.svg";
import {ReactComponent as TransactionIcon} from "../icons/transactions.svg";
import {ReactComponent as InvertoryIcon} from "../icons/inventory.svg";
import {ReactComponent as KioskIcon} from   "../icons/kiosk.svg";
import {ReactComponent as POSIcon} from "../icons/pos.svg";
import {ReactComponent as VoidIcon} from "../icons/void.svg";
import {ReactComponent as CategoryIcon} from "../icons/category.svg";
import {ReactComponent as Report2Icon} from "../icons/report-2.svg";
import { jwtDecode } from "jwt-decode";
import { adminServices } from "../services/AdminServices";
const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [selected, setSelected] = useState("dashboard");
  const [pages,setPages] = useState([]);
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  useEffect(()=>{
      var token = localStorage.getItem("appToken");
      const r = jwtDecode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      setRole(r);
      setIsAdmin(jwtDecode(token)["isAdmin"] === "1");

      getRolePages(r);

      localStorage.setItem("currentRole", r);
  },[]);

  const getRolePages = async (r)=>{
    let form = new FormData();

    form.append('params',r);

    await adminServices.storedProcedure("getRolePages",form).then(response => {
        let pageIds = response.data.map(item => item.pageId);
        setPages(pageIds);
        localStorage.setItem("pages", JSON.stringify(pageIds));
    })
  }

  return (
    <div className={`sidebar ${isActive ? "active" : ""}`}>
      
      <div className="logo-placeholder l t-offwhite">
        {/* <img
          src={require("../tmpImage/logoplaceholder.jpg")}
          style={{ width: "50px", borderRadius: "100px", flex: 1 }}
        /> */}
        {/* <Link to="/" className="link black">
          Admin Booth
        </Link> */}
      </div>
      <div className="line"></div>

      {pages && pages.includes(1) &&  <Link to="/dashboard" className={`sidebarItem ${selected === 'dashboard'? 'activated' : ''}`} onClick={()=>{ setSelected("dashboard")  }}>
        <DashboardIcon className="icon t-darkgray" />
        <p className="link r medium">Dashboard</p>
      </Link>}

      {pages && pages.includes(26) &&  <Link to="/accountability-report" className={`sidebarItem ${selected === 'accountability'? 'activated' : ''}`} onClick={()=>{ setSelected("accountability")  }}>
        <Report2Icon className="icon t-darkgray" />
        <p className="link r medium">Daily Accountability Report</p>
      </Link>}

      {pages && pages.includes(2) && <Link to="/pdl-management" className={`sidebarItem ${selected === 'pdl-management' ? 'activated' : ''}`} onClick={()=>{ setSelected("pdl-management")  }}>
        <UserGroupIcon className="icon t-darkgray" />
        <p className="link r medium">PDL Management</p>
      </Link>}

      {pages && pages.includes(24) && <Link to="/cashout-requests" className={`sidebarItem ${selected === 'cashout-requests' ? 'activated' : ''}`} onClick={()=>{ setSelected("cashout-requests")  }}>
        <ReceiptHistoryIcon className="icon t-darkgray" />
        <p className="link r medium">PDL Cash Out Requests</p>
      </Link>}

      {pages && pages.includes(4) && <Link to="/padala-reports" className={`sidebarItem ${selected === 'padala-reports' ? 'activated' : ''}`} onClick={()=>{ setSelected("padala-reports")  }}>
        <TransactionIcon className="icon t-darkgray" />
        <p className="link r medium">Padala Reports</p>
      </Link>}
      {/* {role == "3" && <Link to="/beucor-padala-reports" className={`sidebarItem ${selected === 'beucor-padala-reports' ? 'activated' : ''}`} onClick={()=>{ setSelected("beucor-padala-reports")  }}>
      <TransactionIcon className="icon t-darkgray" />
        <p className="link r medium">Bucor Padala Reports</p>
      </Link>} */}
      
      {/* <div className="line"></div> */}
      {pages && pages.includes(5) &&  <Link to="/kiosk-reports" className={`sidebarItem ${selected === 'kiosk-reports' ? 'activated' : ''}`} onClick={()=>{ setSelected("kiosk-reports")  }}>
        <ReceiptIcon className="icon t-darkgray" />
        <p className="link r medium">Kiosk Sales Report</p>
      </Link>}

      { pages && pages.includes(6) &&  <Link to="/void-transactions" className={`sidebarItem ${selected === 'void-transactions' ? 'activated' : ''}`} onClick={()=>{ setSelected("void-transactions")  }}>
        <VoidIcon className="icon t-darkgray" />
        <p className="link r medium">Void Transactions</p>
      </Link>}

      {pages && pages.includes(7) &&  <Link to="/floating-padala-reports" className={`sidebarItem ${selected === 'floating-padala-reports' ? 'activated' : ''}`} onClick={()=>{ setSelected("floating-padala-reports")  }}>
        <ReportIcon className="icon t-darkgray" />
        <p className="link r medium">Floating Padala Report</p>
      </Link>}

      

      {pages && pages.includes(9) &&  <Link to="/load-money" className={`sidebarItem ${selected === 'load-money' ? 'activated' : ''}`} onClick={()=>{ setSelected("load-money")  }}>
        <LoadIcon className="icon t-darkgray" />
        <p className="link r medium">Load Money</p>
      </Link>}
     
      
      {/* <Link to="/load-merchant" className={`sidebarItem ${selected === 'pdl-management' ? 'activated' : ''}`} onClick={()=>{ setSelected("pdl-management")  }}>
        <LoadIcon className="icon t-darkgray" />
        <p className="link r medium">Load Money</p>
      </Link> */}
      {/* <div className="line"></div> */}
      {pages && pages.includes(12) &&  <Link to="/inventory" className={`sidebarItem ${selected === 'inventory' ? 'activated' : ''}`} onClick={()=>{ setSelected("inventory")  }}>
        <InvertoryIcon  className="icon t-darkgray" />
        <p className="link r medium">Inventory</p>
      </Link>}

      {pages && pages.includes(13) &&   <Link to="/merchant" className={`sidebarItem ${selected === 'merchant' ? 'activated' : ''}`} onClick={()=>{ setSelected("merchant")  }}>
        <MerchantIcon className="icon t-darkgray" />
        <p className="link r medium">Merchants</p>
      </Link>}

      {pages && pages.includes(27) &&   <Link to="/categories" className={`sidebarItem ${selected === 'categories' ? 'activated' : ''}`} onClick={()=>{ setSelected("categories")  }}>
        <CategoryIcon className="icon t-darkgray" />
        <p className="link r medium">Categories</p>
      </Link>}

      {pages && pages.includes(16) &&   <Link to="/merchant-padala-reports" className={`sidebarItem ${selected === 'merchant-padala' ? 'activated' : ''}`} onClick={()=>{ setSelected("merchant-padala")  }}>
        <TransactionIcon className="icon t-darkgray" />
        <p className="link r medium">Merchant Padala Report</p>
      </Link>}

      

      {pages && pages.includes(17) &&   <Link to="/pos-sales-reports" className={`sidebarItem ${selected === 'pos-sales-reports' ? 'activated' : ''}`} onClick={()=>{ setSelected("pos-sales-reports")  }}>
        <POSIcon className="icon t-darkgray" />
        <p className="link m medium">POS Sales Report</p>
      </Link>}

      {pages && pages.includes(18) &&   <Link to="/icarus-web" className={`sidebarItem ${selected === 'icarus-web' ? 'activated' : ''}`} onClick={()=>{ setSelected("icarus-web")  }}>
        <TransactionIcon className="icon t-darkgray" />
        <p className="link r medium">Icarus Web App</p>
      </Link>}

      {(isAdmin) &&   <Link to="/users-page" className={`sidebarItem ${selected === 'users-page' ? 'activated' : ''}`} onClick={()=>{ setSelected("users-page")  }}>
        <UserGroupIcon className="icon t-darkgray" />
        <p className="link m medium">Users</p>
      </Link>}     
      {/* <NavLink
        to="/merchant"
        className={`sidebarItem ${selected === 'pdl-management' ? 'activated' : ''}`} onClick={()=>{ setSelected("pdl-management")  }}
        activeClassName="sidebarItemActive"
      >
        <MerchantIcon className="icon t-darkgray" />
        <p className="link r medium">Merchants</p>
      </NavLink> */}
      <div className="spacer"></div>
      <div className="line"></div>
      {pages && pages.includes(19) &&   <Link to="/kiosk-status-reports" className={`sidebarItem ${selected === 'kiosk-status-reports' ? 'activated' : ''}`} onClick={()=>{ setSelected("kiosk-status-reports")  }}>
        <KioskIcon className="icon t-darkgray" />
        <p className="link m medium">Kiosk Status Report</p>
      </Link>}

      {pages && pages.includes(22) &&   <Link to="/pos-status-reports" className={`sidebarItem ${selected === 'pos-status-reports' ? 'activated' : ''}`} onClick={()=>{ setSelected("pos-status-reports")  }}>
        <POSIcon className="icon t-darkgray" />
        <p className="link m medium">POS Status Report</p>
      </Link>}
      
      
      {/* <Link to="/support-help" className={`sidebarItem ${selected === 'support-help' ? 'activated' : ''}`} onClick={()=>{ setSelected("support-help")  }}>
        <SupportIcon className="icon t-darkgray" />
        <p className="link m medium">Support</p>
      </Link> */}
      
      <div className={`sidebarItem`} onClick={handleToggle}>
        <ColumnIcon className="icon t-darkgray" />
        <div className="spacer link r medium">Collapse Sidebar</div>
      </div>

      {/* <div className={`sidebarItem ${selected === 'pdl-management' ? 'activated' : ''}`} onClick={()=>{ setSelected("pdl-management")  }}>
        <SettingsIcon className="icon t-darkgray" />
        <Link to="/system-settings" className="link r medium">
          System Settings
        </Link>
      </div> */}
      {/* <div className={`sidebarItem ${selected === 'pdl-management' ? 'activated' : ''}`} onClick={()=>{ setSelected("pdl-management")  }}>
        <ProfileIcon className="icon t-darkgray" />
        <Link to="/profile" className="link r medium">
          Profile
        </Link>
      </div>
      <div className={`sidebarItem ${selected === 'pdl-management' ? 'activated' : ''}`} onClick={()=>{ setSelected("pdl-management")  }}>
        <LogOutIcon className="icon t-darkgray" />
        <Link to="/profile" className="link r medium">
          Logout
        </Link>
      </div> */}
    </div>
  );
};

export default Sidebar;
