import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import "../../src/App.css";

const AppLayout = ({ children }) => {
  return (
    <div className="layout">
      <div style={{zIndex: 9999999}}>
        <Sidebar />
      </div>
      <div className="pageContent">
        <Header />
        <main className="regular t-darkgray content">{children}</main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default AppLayout;
