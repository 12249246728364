import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { walletServices } from "../services/WalletService";
import {ReactComponent as UserGroupIcon} from "../icons/user-group.svg";
import { useNavigate } from 'react-router-dom';
import LoadingModal from "../components/LoadingModal";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
import SearchBar from "../components/SearchBar";
import { formatCurrency } from "../tools/tool";
 
const PDLManagementPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  // setTimeout(()=>{
  //   getPDLProfiles();
  // },60000)

  useEffect(()=>{
    setLoading(true);
      getPDLProfiles();
  },[]);

  

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filteredRows = data.filter((row) =>
      row.pdlNumber.toLowerCase().includes(query) ||
      row.firstName.toLowerCase().includes(query) ||
      row.lastName.toLowerCase().includes(query)
    );
    setFilteredData(filteredRows);


    

  };


  const Facility = (fN) =>{
    let fac = "";
    switch(fN){
      case "Q1":
        fac =  "Quadrant 1";
        break;
        case "Q2":
        fac =  "Quadrant 2";
        break;
        case "Q3":
        fac =  "Quadrant 3";
        break;
        case "Q4":
        fac =  "Quadrant 4";
    }
    return fac;
  }
    


  const getPDLProfiles = ()=>{
      walletServices.pdlList().then((r)=>{
          setData(r.data);
          setFilteredData(r.data);
          var _col = [
              { name: 'ID', selector: data=>data.id, sortable: true, width: "80px" },
              { name: 'Last Name', selector: data=>data.lastName, sortable: true },
              { name: 'First Name', selector: data=>data.firstName, sortable: true },
              { name: 'Middle Name', selector: data=>data.middleName, sortable: true },
              { name: 'PDL Number', selector: data=>data.pdlNumber, sortable: true },
              { name: 'Gender', selector: data=>data.gender == "M" ? "Male" : "Female", sortable: true },
              { name: 'Facility Number', selector: data=>Facility(data.facilityNumber), sortable: true },
              { name: 'Wallet Balance', selector: data=>formatCurrency(data.walletAmount), sortable: true },
          ]
          setColumns(_col);
          setLoading(false);
      }).catch((r)=>{
          console.log(r);
          if(r.status === 401){
              window.location.href="/"
          }
      });
  }

  const handleSelectedRow = (row)=>{
    console.log(row);
    localStorage.setItem('selectedPDL',btoa(JSON.stringify(row)));
    //window.location.href = "/pdl-transactions";
    navigate('/pdl-transactions');
  }

  return <div>
    <LoadingModal show={loading}message={"Loading PDL Profiles..."} />
    <div className="sc-fPXMhL QAQQD" style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
        <UserGroupIcon style={{ width: "50px", height: "50px", marginRight: "10px" }} />
        PDL Profiles
    </div>

    <div className="flexRow">
      <div className="flexRow filterDiv">
        <SearchBar
          placeholder="Search PDL Profiles"
          onChange={(e)=>{
            const value = e.target.value.toLowerCase();
            const filtered = data.filter(item => 
              Object.keys(item).some(key => 
                item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
              ) 
            );
            setFilteredData(filtered);

          }}
        />
      </div>
      &nbsp;&nbsp;
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
      </div>
      <div className="spacer"></div>
    </div>

      <br/>
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination = {false}
          highlightOnHover
          onRowClicked={handleSelectedRow}
          pointerOnHover
          striped
          persistTableHead
          overflowX="scroll"
      />
  </div>;
};

export default withAuthorization(PDLManagementPage, 2);
