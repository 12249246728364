import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar";
import DataTable from "react-data-table-component";
import {ReactComponent as SupplierIcon} from "../../icons/category.svg";
import { adminServices } from "../../services/AdminServices";
import { useAsyncError, useNavigate } from "react-router-dom";
import LoadingModal from "../LoadingModal";
import CategoryFormComponent from "./CategoriesForm";
import AlertModal from "../AlertModal";
import Button from "../Button";

const CategoryComponent = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();

    const [selected, setSelected] = useState("active");
    
    const [activeList, setActiveList] = useState([]);
    const [inactiveList, setInactiveList] = useState([]);

    useEffect(() => {
        setLoading(true);
        initializeColumns();
        getCategories();

        return () => {
            console.log("Page initialized");
        }
    },[]);

    const initializeColumns = () => {
        const _columns = [
            { name: 'Category', selector: row => row.category, sortable: true, },
            { name: 'Description', selector: row => row.description, sortable: true, },
            { name: 'Status', selector: row => row.status_id == 1 ? 'Active' : 'Inactive', sortable: true, },
        ];

        setColumns(_columns);
    }

    const onEditClick = (e)=>{
        setSelectedData(e);
        setShowFormModal(true);
    }

    const getCategories = async() => {
        let form = new FormData();
        form.append('params','');
        await adminServices.storedProcedure('get_service_categories',form).then((res)=>{
            if(res.status === 200){
                setData(res.data);
                setActiveList(res.data.filter(item => item.status_id == 1));
                setInactiveList(res.data.filter(item => item.status_id == 0));
                setData(activeList);
                setLoading(false);
            }
        }).catch((error)=>{

            if(error.status === 401){
                navigate('/');
            }

        })
    }

    return (<div>
        <CategoryFormComponent show={showFormModal} data={selectedData}  
        onClose={()=>{ setShowFormModal(false); setSelectedData(null); }}  
        onAfterSubmit={()=>{ 
            setSelectedData(null);
            setAlertMessage("Category saved successfully");
            setShowAlert(true);
        }}

        onError={()=>{
            setAlertMessage("Error saving category");
            setShowAlert(true);
        }}
        />
        <LoadingModal show={loading} message={"Loading categories..."} />
        <AlertModal show={showAlert} message={alertMessage} onClose={ async () => { setShowAlert(false); setAlertMessage(""); await getCategories(); }}    />
       
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <SupplierIcon className="inv-icon crumb"/>
                <h2>Categories&nbsp;</h2>
            </div>
            <div style={{marginRight: '10px'}}>
                <Button variant={"primary"} size={'xs'} onClick={() => { setShowFormModal(true); setSelectedData(null); }}> + Add Category </Button>
            </div>
        </div>

        <div className="spacer-v"></div>
        <div className="row mb-2">
            <div className="">
                <Button variant={selected === "active" ? "primary" : "secondary"} size={'xs'} onClick={async () => { setData(activeList); setSelected("active"); }}> Active </Button>
                &nbsp;
                <Button variant={selected === "inactive" ? "primary" : "secondary"} size={'xs'} onClick={async () => { setData(inactiveList); setSelected("inactive"); }}> Inactive </Button>
            </div>
        </div>    
        <div className="tableContainer">
            <DataTable 
                pagination={false}
                columns={columns}
                data={data}
                onRowClicked={onEditClick}
                pointerOnHover/>
        </div>
    </div>)
}

export default CategoryComponent;