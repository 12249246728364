import React, { useEffect, useState } from "react";
import { ReactComponent as DashboardIcon } from "../icons/dashboard.svg";
import MotherWalletCard from "../components/dashboard/MotherWalletCard";
import BucorWallet from "../components/dashboard/BuCorWallet";
import ForReprocessCard from "../components/dashboard/ForReprocessCard";
import ItemRequestsCard from "../components/dashboard/ItemRequestsCard";
import ServiceFees from "../components/dashboard/ServiceFees";
import PosFees from "../components/dashboard/PosFees";
import MerchantPadalaRates from "../components/dashboard/MerchantPadalaRates";
import IPXWalletCard from "../components/dashboard/IPXWalletCard";
import { jwtDecode } from "jwt-decode";
import IPXFundTransferCard from "../components/dashboard/IPXFundTransferCard";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
const HomePage = () => {
  const [role, setRole] = useState("");
  let pages = JSON.parse(localStorage.getItem('pages'));
  useEffect(()=>{
      var token = localStorage.getItem("appToken");

      setRole(jwtDecode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
  },[]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
        <DashboardIcon style={{ width: "50px", height: "50px", marginRight: "10px" }} />
        <h2>Admin Booth Dashboard</h2>
      </div>
      <p>Welcome to the dashboard!</p>
      {role == "1"  && <div className="flexRow" style={{paddingTop: "50px", justifyContent: "center"}}>
        <IPXWalletCard />
        <IPXFundTransferCard />
      </div>}
      {role == "1" && <div className="flexRow" style={{paddingTop: "10px", justifyContent: "center"}}>
        <MotherWalletCard />
        {/* <BucorWallet /> */}
        <ServiceFees />
      </div>}

      {role == "1" && <div className="flexRow" style={{paddingTop: "10px", justifyContent: "center"}}>
        <PosFees />
        {/* <BucorWallet /> */}
        <MerchantPadalaRates />
      </div>}

      {(role == "1" || role == "3") && <div className="flexRow" style={{padding: "10px", justifyContent: "center"}}>
        <ForReprocessCard />
        {role == "1" && <ItemRequestsCard />}
      </div>}
    </div>
  );
};

export default withAuthorization(HomePage,1);
