import React, { useEffect, useState } from 'react';
import InputText1 from '../components/InputText1';
import Button2 from '../components/Button2';
import PDLList from '../components/PDLList';
import { adminServices } from '../services/AdminServices';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../components/AlertModal';
import ConfirmationDialog from '../components/ConfirmationDialog';
import InputNum from '../components/InputNum';
import UnauthorizedComponent from '../components/UnauthorizedComponent';
import withAuthorization from '../components/withAuthorization';
import { jwtDecode } from 'jwt-decode';

const ReprocessPadala = ()=>{
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState({});
    const [data, setData] = useState({});
    const [interestRate, setInterestRate] = useState(0);
    const [receiver, setReceiver] = useState('');
    const [pdl, setPdl] = useState('');
    const [openPDLList, setOpenPDLList] = useState(false);
    const [dateTime, setDateTime] = useState(new Date())
    const [clientId, setClientId] = useState(0);
    const [processed_by, setProcessedBy] = useState("");
    const [showAlert,setShowAlert] = useState(false);
    const [titleAlert,setTitleAlert] = useState("");
    const [messageAlert,setMessageAlert] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('appToken');
        if(token){
             const decoded = jwtDecode(token);
        }
        const params = new URLSearchParams(window.location.search);
        const queryParamsObj = {};
        for (let [key, value] of params.entries()) {
          queryParamsObj[key] = value;
        }
        setQueryParams(queryParamsObj);

        if (queryParamsObj.q) {
            parseData(queryParamsObj.q);
        }

    }, []);

    

    const handleSelectedRow = (e)=>{
       setReceiver(e.pdlNumber);
       setPdl(e.firstName + " " + e.lastName);
       setClientId(e.id);
       setOpenPDLList(false);

    }

    const parseData = async (q)=>{
        try {
            const decodedString = atob(q);
            const parsedData = JSON.parse(decodedString);
            setData(parsedData);

            setInterestRate(parsedData.interest_rate);
            setReceiver(parsedData.receiver_pdl_no);
            setPdl(parsedData.pdl);
            setDateTime(new Date(parsedData.transaction_date));

            setProcessedBy(parsedData.processed_by);

          } catch (error) {
            console.error('Failed to parse data:', error);
          }
    }

    return (<div>
        <PDLList
            token={localStorage.getItem('appToken')}
            isOpen={openPDLList}
            onClose={()=>{setOpenPDLList(false)}}
            selectedInfo={handleSelectedRow}
        ></PDLList>
        <AlertModal 
          show={showAlert} 
          title={titleAlert}
          message={messageAlert}
          onClose={()=>{ navigate('/floating-padala-reports'); }}
         />
         <ConfirmationDialog
          title={"Reprocess Padala Transaction"}
          show={showConfirm}
          message={"Are you sure you want to reprocess this transaction?"}
          onConfirm={()=>{
              setShowConfirm(false);
              setTitleAlert("Reprocess Padala Transaction");
              setMessageAlert("Transaction Successfully Reprocessed");
              setShowAlert(true);
          }}
          onCancel={()=>{ setShowConfirm(false); }}
        />
        <form className="padalaForm">
            <InputText1
                title="Sender Name"
                placeholder="Sender Name"
                name="firstname"
                value={data.sender}
                readonly={true}
              />
              <InputText1
                title={data.status === "On Hold" ? "PDL Name" : "PDL Name (Click to change PDL)"}
                placeholder="First Name"
                name="firstname"
                value={pdl}
                readonly={true}
                onClick={()=>{
                    if(data.status === "On Hold"){ return;}
                    setOpenPDLList(true);
                }}
              />
              <InputText1
                title="PDL Number"
                placeholder="PDL Number"
                name="middleName"
                value={receiver}
                readonly={true}
              />
              <InputText1
                title="Padala Type"
                placeholder="Padala Type"
                name="lastname"
                value={data.padala_type}
                readonly={true}
              />

              <InputText1
                title="Gross Amount"
                placeholder="0.00"
                value={data.gross_amount}
                readonly={true}
              />

              <InputNum
                title="Interate Rate (editable)"
                placeholder="0.00"
                value={interestRate}
                type='number'
                readonly={false}
                onChange={(e)=>{ 
                  let newValue = Number(e.target.value);
                  // alert(newValue);
                  if(newValue >= 100){
                    newValue = 100;
                    setInterestRate(newValue);
                  }
                  else if(newValue < 0){
                    newValue = 0;
                    setInterestRate(newValue);
                  }
                  setInterestRate(newValue);

                  

                 }}
                
              />

              <InputText1
                title="Net Amount"
                placeholder="0.00"
                value={data.gross_amount - (data.gross_amount *  ( parseFloat(interestRate) / 100))}
                readonly={true}
              />

              <InputText1
                title="Status"
                placeholder="Status"
                name="firstname"
                value={data.status}
                readonly={true}
              />

              <InputText1
                title="Transaction Date"
                placeholder="date"
                value={
                    dateTime.toLocaleString()

                }
                readonly={true}
              />
              

              <InputText1
                title="Processed By"
                placeholder="date"
                value={
                    processed_by

                }
                readonly={true}
              />

            </form>
            <Button2
                text={"Reprocess"}
                onClick={()=>{

                    var form = {
                        "id": data.id,
                        "refNo": data.ref_no,
                        "senderFname": data.sender,
                        "receiverPdlNo": receiver,
                        "padalaType": 4,
                        "grossAmount": data.gross_amount,
                        "interestRate": (data.gross_amount *  ( parseFloat(interestRate) / 100)),
                        "netAmount": (data.gross_amount - (data.gross_amount *  ( parseFloat(interestRate) / 100))),
                        "status": "Success",
                        'processedBy': processed_by
                    }

                    adminServices.updatePadala(form).then((r)=>{
                        if(r.status === 204){
                            setShowConfirm(true);
                            //window.location.href='/floating-padala-reports';
                            
                        }
                    }).catch((e)=>{
                        console.log(e);
                        alert("An error occured");
                    });

                }}
                style={{ width: "200px", backgroundColor: "#61dd48" }}
              />
    </div>);
}

export default withAuthorization (ReprocessPadala,8);

