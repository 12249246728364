import React, { useEffect } from "react";
import Modal from "react-modal";
import { useState } from "react";
import InputText1 from "../components/InputText1";
import Button2 from "../components/Button2";
import { ReactComponent as SuccessIcon } from "../icons/success.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { v4 as uuidv4 } from 'uuid';
import { adminServices } from "../services/AdminServices";
import PDLList from "../components/PDLList";
import AlertModal from "../components/AlertModal";
import ConfirmationDialog from "../components/ConfirmationDialog";
import MerchantList from "../components/MerchantList";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
document.title = 'PDL Administration | Fund Transfer';
const FundTransferPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [referenceNo, setReferenceNo] = useState('');
  const [token, setToken] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formSeqNo, setFormSeqNo] = useState('');
  const [pdlNumber, setPdlNumber] = useState('');
  const [amount, setAmount] = useState(0);
  const [pdlFName, setPdlFName] = useState('');
  const [pdlLName, setPdlLName] = useState('');
  const [openPDLList, setOpenPDLList] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(()=>{});
  const [merchantName, setMerchantName] = useState('');
  const [pdlName, setPdlName] = useState('');
  const [merchantId, setMerchantId] = useState(0);
  const [openMerchant, setOpenMerchant] = useState(false);
  const [merchantWallet, setMerchantWallet] = useState(0);
  const [pdlId, setPdlId] = useState(0);
  const [wallet_amount,setWalletAmount] = useState(0);

  let role = localStorage.getItem('currentRole');
  
  useEffect(()=>{
    setToken(localStorage.getItem('adminServicesToken'));
    generateReferenceNo();
  },[]);

  const generateReferenceNo = ()=>{
    let uuid = uuidv4();
    let refNo = uuid.split('-');
    setReferenceNo(refNo[4].toUpperCase());
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    //setModalIsOpen(false);
    window.location.href = "/fund-transfer";
  };

  const validateForm=()=>{
    
    if(merchantId === 0){
      setAlertMessage('Please select a Merchant');
      setShowAlert(true);
      return false;
    }

    if(pdlNumber === ""){
      setAlertMessage('Please select a PDL');
      setShowAlert(true);
      return false;
    }

    if(amount==="" || amount=== "0" || amount === 0){
      setAlertMessage('Please enter transfer amount');
      setShowAlert(true);
      return false;
    }

    if(formSeqNo === ''){
      setAlertMessage('Please enter form sequence number');
      setShowAlert(true);
      return false;
    }

    return true;
  }

  const handleSelectedRow2 = (e)=>{
    setMerchantName(e.merchant_name);
    setPdlName(e.pdl_name);
    setMerchantId(e.id);
    setMerchantWallet(e.wallet_amount);
    setOpenMerchant(false);
    setWalletAmount(e.wallet_amount);
  }

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const selectPDL = ()=>{
    setOpenPDLList(true);
  }

  const submitForm=()=>{
    if(validateForm()){
        if(isFormSubmitted) return;
        var payload = {
          "merchantId": merchantId,
          "merchantTransactionTypeId": 4,
          "refNo": referenceNo,
          "amount": (amount * -1),
          "remarks": "Fund Transfer To " + pdlNumber,
          "formNumber" : formSeqNo
        }
        setIsFormSubmitted(true);
        adminServices.postData('MerchantTransactions', payload).then((r)=>{
          if(r.status === 200){
            if(r.data.responseCode === "1000"){
              //alert(r.data.responseMsg);
              setIsFormSubmitted(false);
              return;
            }
            else{
              var payload2 = {
                "clientId": pdlId,
                "transactionTypeId": 7,
                "amount": amount,
                "remarks": "Fund Transfer From Merchant: " + merchantName
              }

              adminServices.postData('ClientTransaction',payload2).then((r)=>{
                if(r.status == 200){
                  openModal();
                  setIsFormSubmitted(false);
                }
              }).catch((e)=>{
                setAlertMessage("Fund Transfer To PDL");
                setShowAlert(true);
                setIsFormSubmitted(false);
              })
            }
          }
        }).catch((e)=>{
            setAlertMessage("Fund Transfer Error");
            setShowAlert(true);
            setIsFormSubmitted(false);
        })
    }
  }

  const handleSelectedRow = (e)=>{
    setPdlNumber(e.pdlNumber);
    setPdlFName(e.firstName);
    setPdlLName(e.lastName);
    setPdlId(e.id);
    setOpenPDLList(false);
  }


  return (
    <div className="mainContainer">
      
      <ConfirmationDialog
      show={showConfirm}
      title={"PDL Padala"}
      message={confirmMessage}
      onCancel={()=>{ setShowConfirm(false); }}
      onConfirm={()=>{ window.location.href='/dashboard'; }}
      >

      </ConfirmationDialog>
      <AlertModal
        show={showAlert}
        title="PDL Padala"
        message={alertMessage}
        onClose={handleCloseModal}
      />
      <PDLList
      token={localStorage.getItem('appToken')}
        isOpen={openPDLList}
        onClose={()=>{setOpenPDLList(false)}}
        selectedInfo={handleSelectedRow}
       />
      <MerchantList
      token={localStorage.getItem('appToken')}
        isOpen={openMerchant}
        onClose={()=>{setOpenMerchant(false)}}
        selectedInfo={handleSelectedRow2}
        />
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* Modal for Success */}
        <Modal
          className="successModal"
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          <CloseIcon
            color="white"
            height="25px"
            className="alertCloseButton"
            onClick={closeModal}
          />
          <div className="flexRow">
            <div style={{ flex: 2, display: "flex", justifyContent: "center" }}>
              <SuccessIcon height="200px" color="white" />
            </div>
            <div style={{ flex: 3, display: "flex", justifyContent: "center" }}>
              <p className="medium">FUND TRANSFER SUCCESS!</p>
            </div>
          </div>
        </Modal>
        {/* End Modal for Success */}
        <div>
          <p className="semibold xl t-darkgray">Fund Transfer Form</p>
          <div style={{ height: "5px" }}></div>

          <p className="regular r t-darkgray">
            Fill in the details below to complete the transfer
          </p>

          
        </div>

        <div className="spacer"></div>
        <div style={{ alignContent: "end" }}>
          <p>&nbsp;</p>
          <p className="sm">2024.06.14 Version1</p>
        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="flexRow" style={{ flex: "1" }}>
        {/* Column 1 */}
        <div className="column1">
          <div className="padalaSummaryCard">
            <div style={{ height: "15px" }}></div>
            <div className="padalaSummaryColumnContainer">
              <div className="padalaSummaryColumn">
                <p className="semibold r">Recipient information:</p>
                <div style={{marginBottom:5, marginTop: 5}}>
                  <input type="button" 
                    value={"Select a Merchant"}
                    onClick={()=>{ setOpenMerchant(true); }}
                    className="btn btn-primary btn-xs"
                    style={{
                      fontSize: 15,
                      fontVariant: 'all-petite-caps',
                    }}
                  />
              </div>
                <div style={{ height: "10px" }}></div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Merchant Name</p>
                  <p>{merchantName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">PDL Name</p>
                  <p>{pdlName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Wallet Amount</p>
                  <p>{"P " + merchantWallet.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                </div>
                
                <div style={{ margin: "5px", flex: "1" }}>
                  <InputText1
                  title="Form Sequence Number"
                  placeholder="Form Sequence Number"
                  name="formSeqNo"
                  value={formSeqNo}
                  onChange={(e)=>{
                    setFormSeqNo(e.target.value);
                  }}
                />
                </div>

                <div style={{ height: "20px" }}></div>
              </div>

              
            </div>
          </div>
        </div>
        {/* Column 2 */}
        <div className="column2">
          
          <div className="padalaSummaryCard">
            <p className="semibold l t-darkgray">Enter Amount:</p>
            <div style={{ height: "20px" }}></div>
            <div className="line2"></div>

            <div
              className="flexRow"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center'
              }}
            >
              <p className="xxl medium t-darkgray" style={{marginTop: 35}}>Php &nbsp;</p>
              <input
                style={{ textAlign: "center", fontSize: "3rem" }}
                className="xxl semibold t-darkgray padalaAmount"
                type="number"
                placeholder="Enter amount..."
                value={amount}
                min={0}
                onChange={(e)=>{
                  if(e.target.value >wallet_amount){
                    return;
                  }
                  setAmount(e.target.value);
                }}
              ></input>
            </div>

            <div className="flexRow"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                marginBottom: 20
              }}>
                {/* <b>Reference No:&nbsp;</b> {referenceNo} */}
            </div>

            <div style={{ height: "15px" }}></div>
            <div className="padalaSummaryColumnContainer">
              <div className="padalaSummaryColumn">
                <p className="semibold r">Recipient information:</p>
                <div style={{marginBottom:5, marginTop: 5}}>
                  <input type="button" 
                    value={"Select a PDL"}
                    onClick={selectPDL}
                    className="btn btn-primary btn-xs"
                    style={{
                      fontSize: 15,
                      fontVariant: 'all-petite-caps',
                    }}
                  />
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">PDL Number</p>
                  <p>{pdlNumber}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">First Name</p>
                  <p>{pdlFName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Last Name</p>
                  <p>{pdlLName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Transfer Amount</p>
                  <p>{amount}</p>
                </div>

                <div style={{ height: "20px" }}></div>
              </div>
            </div>

            <div className="flexRow" style={{marginTop: 20}}>
              <Button2
                text={"Cancel"}
                style={{ width: "200px", backgroundColor: "#cc2121" }}
                onClick={()=>{

                  setConfirmMessage("Are you sure you want to exit this page?");
                  setShowConfirm(true);

                } }
              />
              <div className="spacer"></div>

              <Button2
                text={"Proceed"}
                onClick={()=>{submitForm()}}
                style={{ width: "200px", backgroundColor: "#61dd48" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthorization(FundTransferPage,11);
