import React, { useEffect } from "react";
import Modal from "react-modal";
import { useState } from "react";
import InputText1 from "../components/InputText1";
import Button2 from "../components/Button2";
import { ReactComponent as SuccessIcon } from "../icons/success.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { v4 as uuidv4 } from 'uuid';
import { adminServices } from "../services/AdminServices";
import MerchantList from "../components/MerchantList";
import ConfirmationDialog from "../components/ConfirmationDialog";
import AlertModal from "../components/AlertModal";
import {formatCurrency} from "../tools/tool";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
document.title = 'PDL Administration';
const LoadMerchantPage = () => {
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [referenceNo, setReferenceNo] = useState('');
  const [token, setToken] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [IdPresented, setIdPresented] = useState('');
  const [IdNumber, setIdNumber] = useState('');
  const [pdlNumber, setPdlNumber] = useState('');
  const [amount, setAmount] = useState("");
  const [openMerchant, setOpenMerchant] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [pdlName, setPdlName] = useState('');
  const [merchantId, setMerchantId] = useState(0);
  const max = 1000000;
  useEffect(()=>{
    setToken(localStorage.getItem('adminServicesToken'));
    generateReferenceNo();
  },[]);

  

  const generateReferenceNo = ()=>{
    let uuid = uuidv4();
    let refNo = uuid.split('-');
    setReferenceNo(refNo[4].toUpperCase());
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const closeModal = () => {
    window.location.href='/load-merchant';
    setModalIsOpen(false);
  };

  const validateForm=()=>{
    if(firstName === ""){
      setAlertMessage('Sender First Name Required');
      setShowAlert(true);
      return false;
    }

    if(lastName === ""){
      setAlertMessage('Sender Last Name Required');
      setShowAlert(true);
      return false;
    }

    if(mobileNumber === ""){
      setAlertMessage('Mobile Number Required');
      setShowAlert(true);
      return false;
    }

    if(emailAddress === ""){
      setAlertMessage('Email Address Required');
      setShowAlert(true);
      return false;
    }

    if(amount===0){
      setAlertMessage('Please enter padala amount');
      setShowAlert(true);
      return false;
    }

    if(merchantName===""){
      setAlertMessage('Please select a Merchant');
      setShowAlert(true);
      return false;
    }

    return true;
  }

  const handleSelectedRow = (e)=>{
    setMerchantName(e.merchant_name);
    setPdlName(e.pdl_name);
    setMerchantId(e.id);
    setOpenMerchant(false);
  }

  const submitForm=()=>{
    if(validateForm()){
      // console.log(token);
      // return;
        if(isFormSubmitted) return;
        var payload = {
          refNo : referenceNo,
          senderFname  :  firstName,
          senderMname : middleName,
          senderLname : lastName,
          senderMobile : mobileNumber,
          senderEmail : emailAddress,
          senderRelationship : "",
          idPresented : IdPresented,
          idNo        : IdNumber,
          grossAmount : amount,
          merchantId : merchantId
        }
        setIsFormSubmitted(true);
        adminServices.sendMerchantPadala(token, payload).then((r)=>{
          if(r.status === 200){
            if(r.data.responseCode ==="1000"){
              alert(r.data.responseMsg);
              setIsFormSubmitted(false);
              return;
            }
            else{
              openModal();
              setIsFormSubmitted(false);
            }
          }
        }).catch((e)=>{
            console.log(e);

            if(e.response.status === 404){
              setAlertMessage("Invalid Merchant, please try again");
              setShowAlert(true);
            }

            setIsFormSubmitted(false);
        })
    }
  }

  return (
    <div className="mainContainer">
      <ConfirmationDialog
      show={showConfirm}
      title={"Merchant Padala"}
      message={confirmMessage}
      onCancel={()=>{ setShowConfirm(false); }}
      onConfirm={()=>{ window.location.href='/dashboard'; }}
      >

      </ConfirmationDialog>
      <AlertModal
        show={showAlert}
        title="PDL Padala"
        message={alertMessage}
        onClose={handleCloseModal}
      />
      <MerchantList
      token={localStorage.getItem('appToken')}
        isOpen={openMerchant}
        onClose={()=>{setOpenMerchant(false)}}
        selectedInfo={handleSelectedRow}
        ></MerchantList>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* Modal for Success */}
        <Modal
          className="successModal"
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          <CloseIcon
            color="white"
            height="25px"
            className="alertCloseButton"
            onClick={closeModal}
          />
          <div className="flexRow">
            <div style={{ flex: 2, display: "flex", justifyContent: "center" }}>
              <SuccessIcon height="200px" color="white" />
            </div>
            <div style={{ flex: 3, display: "flex", justifyContent: "center" }}>
              <p className="medium">PADALA SUCCESS!</p>
            </div>
          </div>
        </Modal>
        {/* End Modal for Success */}
        <div>
          <p className="semibold xl t-darkgray">Load Merchant Form</p>
          <div style={{ height: "5px" }}></div>

          <p className="regular r t-darkgray">
            Fill in the details below to complete the transfer
          </p>
        </div>

        <div className="spacer"></div>
        <div style={{ alignContent: "end" }}>
          {/* <Button2 text={"Padala"} onClick={()=>{ window.location.href='/load-money' }}></Button2>
          <Button2 text={"Fund Transfer"} onClick={()=>{ window.location.href='/fund-transfer' }}></Button2> */}
          <p>&nbsp;</p>
          <p className="sm">2024.06.14 Version1</p>
        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="flexRow" style={{ flex: "1" }}>
        {/* Column 1 */}
        <div className="column1">
          <div className="padalaFormCard">
            <p className="semibold xl t-darkgray">Sender information</p>
            <div style={{ height: "20px" }}></div>
            <div className="line2"></div>
            <div style={{ height: "15px" }}></div>
            <form className="padalaForm">
              <InputText1
                title="First Name"
                placeholder="First Name"
                name="firstname"
                value={firstName}
                onChange={(e)=>{
                  setFirstName(e.target.value);
                }}
              />
              <InputText1
                title="Middle Name"
                placeholder="Middle Name (Optional)"
                name="middleName"
                value={middleName}
                onChange={(e)=>{
                  setMiddleName(e.target.value);
                }}
              />
              <InputText1
                title="Last Name"
                placeholder="Last Name"
                name="lastname"
                value={lastName}
                onChange={(e)=>{
                  setLastName(e.target.value);
                }}
              />

              <InputText1
                title="Mobile Number"
                placeholder="639-XXX-XXX-XXXX"
                name="mobileNumber"
                value={mobileNumber}
                onChange={(e)=>{
                  setMobileNumber(e.target.value);
                }}
              />

              <InputText1
                title="Email Address"
                placeholder="Email Address"
                name="emailAddress"
                value={emailAddress}
                onChange={(e)=>{
                  setEmailAddress(e.target.value);
                }}
              />

              <InputText1
                title="ID Presented"
                placeholder="Optional"
                name="id_presented"
                value={IdPresented}
                onChange={(e)=>{
                  setIdPresented(e.target.value);
                }}
              />

              <InputText1
                title="ID Number"
                placeholder="ID Number"
                name="id_number"
                value={IdNumber}
                onChange={(e)=>{
                  setIdNumber(e.target.value);
                }}
              />
            </form>
          </div>

          <div style={{ height: "25px" }}></div>

          {/* <div className="padalaFormCard">
            <p className="semibold xl t-darkgray">Recipient information</p>
            
            <div className="line2"></div>

            <form className="padalaForm">
              <InputText1 title="PDL Number" placeholder="PDL Number" name="pdlNumner" value={pdlNumber}
                onChange={(e)=>{
                  setPdlNumber(e.target.value);
                }}
              />
            </form>
          </div> */}
        </div>

        {/* Column 2 */}
        <div className="column2">
          <div className="padalaSummaryCard">
            <p className="semibold l t-darkgray">Enter Amount:</p>
            <div style={{ height: "20px" }}></div>
            <div className="line2"></div>

            <div
              className="flexRow"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center'
              }}
            >
              <p className="xxl medium t-darkgray" style={{marginTop: 35}}>Php &nbsp;</p>
              <input
                style={{ textAlign: "center", fontSize: "3rem" }}
                className="xxl semibold t-darkgray padalaAmount"
                type="number"
                placeholder="Enter amount..."
                value={amount}
                min={0}
                max={max}
                
                onChange={(e)=>{
                  
                  let newValue = Number(e.target.value);
                  if (newValue < 0) { newValue = 0; }
                  newValue = Math.min(max, newValue);
                  setAmount(newValue);

                }}
              ></input>
              
            </div>

            <div className="flexRow"
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: 0,
                marginLeft: 55,
                marginBottom: 20,
                fontSize: 12
              }}>
              <b>Max Amount:&nbsp;</b> {formatCurrency(max)}
            </div>

            <div className="flexRow"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                marginBottom: 20
              }}>
                {/* <div><b>Reference No:&nbsp;</b> {referenceNo}</div> */}
            </div>


            <div style={{ height: "15px" }}></div>
            <div className="padalaSummaryColumnContainer">
              <div className="padalaSummaryColumn">
                <p className="semibold r">Sender information:</p>
                <div style={{ height: "10px" }}></div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">First Name</p>
                  <p>{firstName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Middle Name</p>
                  <p>{middleName} </p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Last Name</p>
                  <p>{lastName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Mobile Number</p>
                  <p>{mobileNumber}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Email Address</p>
                  <p>{emailAddress}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">ID Presented <b><i>*optional</i></b></p>
                  <p>{IdPresented}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">ID Number <b><i>*optional</i></b></p>
                  <p>{IdNumber}</p>
                </div>
              </div>

              <div className="padalaSummaryColumn">
                <p className="semibold r">Recipient information:</p>
                <div style={{marginBottom:5, marginTop: 5}}>
                  <input type="button" 
                    value={"Select a Merchant"}
                    onClick={()=>{ setOpenMerchant(true); }}
                    className="btn btn-primary btn-xs"
                    style={{
                      fontSize: 15,
                      fontVariant: 'all-petite-caps',
                    }}
                  />
              </div>
                <div style={{ height: "10px" }}></div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Merchant Name</p>
                  <p>{merchantName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">PDL Name</p>
                  <p>{pdlName}</p>
                </div>
                <div style={{ margin: "5px", flex: "1" }}>
                  <p className="semibold ">Padala Amount</p>
                  <p>{amount}</p>
                </div>

                <div style={{ height: "20px" }}></div>
              </div>
            </div>

            <div className="flexRow" style={{marginTop: 20}}>
              <Button2
                text={"Cancel"}
                style={{ width: "200px", backgroundColor: "#cc2121" }}
                onClick={()=>{

                  setConfirmMessage("Are you sure you want to exit this page?");
                  setShowConfirm(true);

                } }
              />
              <div className="spacer"></div>

              <Button2
                text={"Proceed"}
                onClick={submitForm}
                style={{ width: "200px", backgroundColor: "#61dd48" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthorization(LoadMerchantPage,10);
