import DataTable from "react-data-table-component";
import Dropdown from "../components/Dropdown";
import React, { useEffect, useState } from "react";
import InputText1 from "../components/InputText1";
import { adminServices } from "../services/AdminServices";
import EditableCell from "../components/EditableCell";
import Button2 from "../components/Button2";
import ConfirmationDialog from "../components/ConfirmationDialog";
import UnauthorizedComponent from "../components/UnauthorizedComponent";


const POPage = ()=>{
    const  [columns, setColumns] = useState([]);
    const  [suppliers, setSuppliers] = useState([]);
    const  [products, setProducts] = useState([]);
    const  [showConfirm, setShowConfirm]= useState(false);
    const  [confirmMessage, setConfirmMessage] = useState('');
    const  [supplierId, setSupplierId] = useState(0);
    const [rfqId, setRfqId] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    useEffect(()=>{
        getSuppliers();
    },[]);

    

    const getSuppliers = async ()=>{
        var form = new FormData();
        form.append("params",'');
        await adminServices.storedProcedure("rfq_without_po",form).then((r)=>{
            var options  = [{value: "", label: "Select a RFQ"}];
            r.data.forEach((element) => {
                var data = {value: element.rfq_id + "|" + element.supplier_id, label : element.rfq_id + " | " + element.supplier_name}
                options.push(data);
            });
            setSuppliers(options);
        }).catch((e)=>{
            if(e.response.status == 401){
                window.location.href="/";
            }
        })
    }

    const saveValue = (id, newValue) => {
        setProducts(products.map(item => item.id === id ? { ...item, quantity: newValue } : item));
        // var newTotal = int.pa
        // setProducts(products.map(item => item.id === id ? { ...item, total: newTotal } : item));
    };

    const saveValue2 = (id, newValue) => {
        setProducts(products.map(item => item.id === id ? { ...item, price: newValue } : item));
        // var newTotal = int.pa
        // setProducts(products.map(item => item.id === id ? { ...item, total: newTotal } : item));
    };

    const deleteRow = (row) => {
        //console.log(id);
        setProducts(products.filter(item => item.id !== row.id));
        console.log(products);
        console.log(products);
      };

    if(localStorage.getItem('currentRole') != 1) {
        return (<UnauthorizedComponent />);
    }

    return (<div>
        <ConfirmationDialog show={showConfirm}  message={confirmMessage} onCancel={()=>{ setShowConfirm(false)}} onConfirm={()=>{
            window.location.href = "/rfq-list";
        }} />
        <h3>Create Purchase Order</h3>
        <div style={{width: '30%'}}>
            <Dropdown title={"RFQ"} data={suppliers} onChange={ async (e)=>{
                var form = new FormData();

                form.append('params',e.target.value.split('|')[0]);
                setSupplierId(e.target.value.split('|')[1]);
                setRfqId(e.target.value.split('|')[0]);
                await adminServices.storedProcedure('rfq_po_details', form).then((r)=>{
                    setProducts(r.data);
                }).catch((r)=>{

                });
            }} />
        </div>
        <div class='spacer'></div>
        { (<div>
            <DataTable title="Product List" columns={[
                { name: '-', cell: row => <a style={{textDecoration:'none'}} href="javascript:void(0);" onClick={() => deleteRow(row)}>remove</a>, sortable: false },
                { name: 'Product Name', selector: data=>data.product_name, sortable: true },
                { name: 'Sku', selector: data=>data.sku, sortable: true },
                { name: 'Unit Of Measure', selector: data=>data.unitOfMeasure , sortable: true },
                { name: 'Price', cell: row => <EditableCell item={"price"} row={row} onSave={saveValue2} type="number" />, sortable: true },
                { name: 'Qty', cell: row => <EditableCell item={"quantity"} row={row} onSave={saveValue} type="number" />, sortable: true },
            ]}
            data={products} />
            <div className="flexRow" style={{marginTop: 20}}>
                <Button2
                    text={"Cancel"}
                    style={{ width: "200px", backgroundColor: "#cc2121" }}
                    onClick={()=>{

                    setConfirmMessage("Are you sure you want to exit this page?");
                    setShowConfirm(true);

                    } }
                />
                &nbsp;
                <Button2
                    text={"Submit"}
                    onClick={async ()=>{

                        if(!submitting){
                            setSubmitting(true);
                            var data = {
                                "rfqId": rfqId,
                                "supplierId": supplierId,
                                "productOrderStatusId": "1",
                                "productOrderDetails": []
                            }

                            products.forEach((e) => {
                                var pod = {
                                    "ipxWarehouseId": e.ipx_warehouse_id,
                                    "productId": e.product_id,
                                    "quantity": e.quantity,
                                    "price": e.price
                                    
                                }

                                data.productOrderDetails.push(pod);
                            });

                            await adminServices.addPo(data).then((r)=>{
                                if(r.status == 200){
                                    window.location.href= "/po-list";
                                }
                            }).catch((e)=>{
                                alert('Error submitting PO');
                            });
                        
                        console.log(products);
                        }
                        
                    }}
                    style={{ width: "200px", backgroundColor: "#61dd48" }}
                />
                {submitting && (<div style={{color:'green'}}>&nbsp;Submitting PO, please wait!</div>)}
            </div>
        </div>)}
    </div>);
}

export default POPage;