import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { adminServices } from "../services/AdminServices";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorLogin,setErrorLogin] = useState('');
  const [loading, setLoading] = useState('');
  const [enableReset, setEnableReset] = useState(false);
  const [submitReset, setSubmitReset] = useState(false);
  const [successful, setSuccessful] = useState(false);

  let emailAddr = localStorage.getItem("emailAddr");

  const handleUsername = (event)=>{
    setUserName(event.target.value);
  }

  const handleOtp = (event)=>{
    setOtp(event.target.value);
  }

  const Login = ()=>{
    setErrorLogin('');
    setLoading('Verifying OTP, please wait!');
    adminServices.verify(otp).then((r)=>{
      if(r.status == 200){
          setEnableReset(true);
          localStorage.setItem("appToken",r.data.token);
          setLoading('');
      }

      
    }).catch((e)=>{
      if(e.response.status == 409){
        setErrorLogin("Verification failed, please try again");
        setLoading('');
        return;
      }
    });

    

    // adminServices.getToken().then((r)=>{
    //   if(r.status==200){
    //     localStorage.setItem('adminServicesToken',r.data.token);
    //     window.location.href = '/dashboard';
    //   }
    // }).catch((e)=>{
    //   setErrorLogin('Error logging in, check your connection and try again.');
    // });

  }

  function togglePassword() {
    var passwordInput = document.getElementById("password");
    var showPasswordCheckbox = document.getElementById("showPassword");

    if (showPasswordCheckbox.checked) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }

  return (
    <div className="mainContainer">
      {/* Right Side */}
      <div className="loginPageContainer">
        <div className="loginPartition1">
          <div className="leftPart">
            {/* <div>
              <p>Login</p>
            </div>
            <div>
              <p>Sign Up</p>
            </div> */}
          </div>
        </div>

        {/* Left Side */}
        <div className="loginPartition2">

          
          <div className="rightPart">

            {/* Verify OTP */}
            {!enableReset && <div>
              <p className="xl medium">Verify OTP</p>
              <p className="r light">An email with a One Time Pin has been sent to your registered email.</p>
              <div style={{ height: "25px" }}></div>
              <div className="loginFields regular">
                <p>One Time Pin</p>
                <input
                  id="password"
                  className="m"
                  type="password"
                  placeholder="********"
                  value={otp}
                  onChange={handleOtp}
                ></input>
              </div>

              <div className="flexRow" style={{color:'#FF0000',marginBottom:15, justifyContent:'center'}}>
                {errorLogin}
              </div>

              <div className="flexRow" style={{color:'green',marginBottom:15, justifyContent:'center'}}>
                {loading}
              </div>

              <div className="flexRow">
                <div className="flexRow m">
                  <input
                    type="checkbox"
                    id="showPassword"
                    onChange={togglePassword}
                  ></input>
                  <p>&nbsp;Show One Time Pin</p>
                </div>
                <div className="spacer"></div>
              </div>
              <div style={{ height: "20px" }}></div>
              <div className="loginButton">
                <Link style={{ textDecoration: "none" }}  onClick={Login}>
                  <p className="r">Verify</p>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                
              </div>
            </div>}

            {/* Reset Password */}
            {enableReset && !successful && <div>
              <p className="xl medium">Reset Password</p>
              <p className="r light">Please enter your new password.</p>
              <div style={{ height: "25px" }}></div>
              <div className="loginFields regular">
                <p>New Password</p>
                <input
                  id="password"
                  className="m"
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={(e)=>{ setPassword(e.target.value) }}
                ></input>
              </div>

              <div className="loginFields regular">
                <p>Confirm Password</p>
                <input
                  id="password"
                  className="m"
                  type="password"
                  placeholder="********"
                  value={confirmPassword}
                  onChange={(e)=>{ 
                    setConfirmPassword(e.target.value) 
                    if(password != e.target.value) {
                      setErrorLogin("Passwords do not match");
                      setSubmitReset(false);
                      return;
                    } else {
                      setErrorLogin("");
                      setSubmitReset(true);
                    }  
                }}
                ></input>
              </div>

              <div className="flexRow" style={{color:'#FF0000',marginBottom:15, justifyContent:'center'}}>
                {errorLogin}
              </div>

              <div className="flexRow" style={{color:'green',marginBottom:15, justifyContent:'center'}}>
                {loading}
              </div>

              
              <div style={{ height: "20px" }}></div>
              <div className="loginButton">
                <Link style={{ textDecoration: "none" }}  onClick={async ()=>{
                  if(submitReset){

                    var form = new FormData();
                    form.append('params',`'${emailAddr}','${password}'`);
                    await adminServices.storedProcedure('ResetPassword',form).then(async (r)=>{
                        if(r.data[0].status == 200){
                          setSuccessful(true);
                        }
                    }).catch((e)=>{
                      if(e.status == 500){
                        setErrorLogin("Error resetting password, please try again");

                      }
                    })

                  }
                }}>
                  <p className="r">Reset Password</p>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                
              </div>
            </div>}

            {successful && <div>
              <p className="xl medium">Password Reset Successful</p>
              

              
              <div style={{ height: "20px" }}></div>
              <div className="loginButton">
                <Link style={{ textDecoration: "none" }}  onClick={()=>{
                  window.location.href = '/';
                }}>
                  <p className="r">Go to Login</p>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                
              </div>
            </div>}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
