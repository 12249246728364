import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { ReactComponent as BackIcon } from "../icons/double-chevron.svg";
import { ReactComponent as BellIcon } from "../icons/support.svg";
import { ReactComponent as SettingsIcon } from "../icons/settings.svg";
import { ReactComponent as LogOutIcon } from "../icons/logout.svg";
// import { ReactComponent as LogOutIcon } from "../icons/lo.svg";
import { ReactComponent as KeyIcon } from "../icons/key.svg";
import { jwtDecode } from "jwt-decode";
import SearchBar from "../components/SearchBar";
import PasswordResetForm from "./PasswordReset";

const Header = () => {
  const [role, setRole] = useState("");
  const [isAdmin,setIsAdmin] =  useState(false);
  const [showReset, setShowReset] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("appToken");
    setRole(jwtDecode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
    setIsAdmin(jwtDecode(token)["isAdmin"] === "1");
  });
  return (
    <div className="flexRow" style={{width: '100%',paddingRight:'30px'}}>
      <PasswordResetForm show={showReset} onClose={() => setShowReset(false)} />
      {/* <BackIcon className="headerIcon" /> */}
      {/* <SearchBar style={{ backgroundColor: "#f5f5f5" }} /> */}
     
      <div className="spacer"></div>
      <div className="img-placeholder"></div>
      
      {isAdmin && <BellIcon title="Logs" onClick={(()=>{ window.location.href ="/logs" })} style={{ width: "24px" }} className="t-darkblue" />}
      {isAdmin && <SettingsIcon title="Settings" onClick={(()=>{ window.location.href ="/settings" })} style={{ width: "24px" }} className="t-darkblue" />}
      <KeyIcon title="Reset Password" onClick={(()=>{ setShowReset(true) })} style={{ width: "24px" }} className="t-darkblue" />
      <LogOutIcon title="Logout" onClick={(()=>{ 
        localStorage.clear();
        window.location = "/";
       })} style={{ width: "24px" }} className="t-darkblue" />



    </div>
  );
};

export default Header;
