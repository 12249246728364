import DataTable from "react-data-table-component";
import Dropdown from "../components/Dropdown";
import React, { useEffect, useState } from "react";
import InputText1 from "../components/InputText1";
import { adminServices } from "../services/AdminServices";
import EditableCell from "../components/EditableCell";
import Button2 from "../components/Button2";
import ConfirmationDialog from "../components/ConfirmationDialog";
import UnauthorizedComponent from "../components/UnauthorizedComponent";


const ReceivingPage = ()=>{
    const  [columns, setColumns] = useState([]);
    const  [suppliers, setSuppliers] = useState([]);
    const  [products, setProducts] = useState([]);
    const  [showConfirm, setShowConfirm]= useState(false);
    const  [confirmMessage, setConfirmMessage] = useState('');
    const  [supplierId, setSupplierId] = useState(0);
    const [rfqId, setRfqId] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [receipt,setReceipt] = useState('');

    useEffect(()=>{
        getSuppliers();
    },[]);

    

    const getSuppliers = async ()=>{
        var form = new FormData();
        form.append("params",'');
        await adminServices.storedProcedure("available_po",form).then((r)=>{
            var options  = [{value: "", label: "Select a PO Number"}];
            r.data.forEach((element) => {
                var data = {value: element.product_order_id, label : element.po_number}
                options.push(data);
            });
            setSuppliers(options);
        }).catch((e)=>{
            if(e.response.status == 401){
                window.location.href="/";
            }
        })
    }

    const saveValue = (id, newValue) => {

        setProducts(products.map(item => item.id === id ? { ...item, receiving_qty: newValue } : item));
        console.log(id,newValue,products);
    };

    const deleteRow = (row) => {
        //console.log(id);
        setProducts(products.filter(item => item.id !== row.id));
        console.log(products);
        console.log(products);
      };

    if(localStorage.getItem('currentRole') != 1) {
        return (<UnauthorizedComponent />);
    }

    return (<div>
        <ConfirmationDialog show={showConfirm}  message={confirmMessage} onCancel={()=>{ setShowConfirm(false)}} onConfirm={()=>{
            window.location.href = "/rfq-list";
        }} />
        <h3>Receiving</h3>
        <div style={{width: '30%'}}>
            <Dropdown title={"Purchase Order Number"} data={suppliers} onChange={ async (e)=>{
                var form = new FormData();

                form.append('params',e.target.value);
                setRfqId(e.target.value);
                await adminServices.storedProcedure('receiving_item_po', form).then((r)=>{
                    setProducts(r.data);
                }).catch((r)=>{

                });
            }} />
        </div>
        <InputText1 title={"Receipt No"}
            value={receipt}
            placeholder={"Receipt No"}
            onChange={(e)=>{
                setReceipt(e.target.value);
            }}
        />
        <div class='spacer'></div>
        { (<div>
            <DataTable title="Product List" columns={[
                //{ name: '-', cell: row => <a style={{textDecoration:'none'}} href="javascript:void(0);" onClick={() => deleteRow(row)}>remove</a>, sortable: false },
                { name: 'Product Name', selector: data=>data.product_name, sortable: true },
                { name: 'Sku', selector: data=>data.sku, sortable: true },
                { name: 'Unit Of Measure', selector: data=>data.unitOfMeasure , sortable: true },
                { name: 'Price', selector: data=>data.price, sortable: true },
                { name: 'QTY', selector: data=>data.qty, sortable: true },
                { name: 'Delivered QTY', cell: row => row.qty ==0 ? "" : <EditableCell item={"receiving_qty"} row={row} onSave={saveValue} type="number" />, sortable: true },
            ]}
            data={products} />
            <div className="flexRow" style={{marginTop: 20}}>
                <Button2
                    text={"Cancel"}
                    style={{ width: "200px", backgroundColor: "#cc2121" }}
                    onClick={()=>{

                    setConfirmMessage("Are you sure you want to exit this page?");
                    setShowConfirm(true);

                    } }
                />
                &nbsp;
                <Button2
                    text={"Submit"}
                    onClick={async ()=>{

                        
                        if(receipt == ""){
                            alert("Receipt No Required");
                            return;
                        }

                        setSubmitting(true);
                        var data = {
                            "productOrderId": rfqId,
                            "receiptNo": receipt
                        }

                        await adminServices.addReceiving(data).then(async (r)=>{
                            if(r.status == 200){
                                Promise.all(products.map(async (e)=>{
                                    var form = {
                                        receivingId : r.data.id,
                                        productId : e.product_id,
                                        quantity : e.receiving_qty
                                    }

                                    await adminServices.addReceivingItems(form).then((r)=>{})
                                }));
                                var _form = new FormData();
                                _form.append('params',rfqId);
                                await adminServices.storedProcedure('update_po',_form).then((r)=>{
                                    alert('Delivery Receipt Success');
                                    window.location.href="/po-list";
                                })
                                
                            }
                        }).catch((e)=>{
                            alert('Error submitting');
                        });
                    
                        
                    }}
                    style={{ width: "200px", backgroundColor: "#61dd48" }}
                />
                {submitting && (<div style={{color:'green'}}>&nbsp;Submitting, please wait!</div>)}
            </div>
        </div>)}
    </div>);
}

export default ReceivingPage;