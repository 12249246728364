import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { adminServices } from "../services/AdminServices";
import { jwtDecode } from "jwt-decode";
let pageUrls = {
   1: "/dashboard",
   2: "/pdl-management",
   3: "/pdl-transactions",
   4: "padala-reports",
   5: "/kiosk-reports",
   6: "/void-transactions",
   7: "/floating-padala-reports",
   8: "/reprocess",
   9: "/load-money",
   10: "/load-merchant",
   11: "fund-transfer",
   12: "/inventory",
   13: "/merchant",
   14: "/merchant/add",
   15: "/merchant/edit",
   16: "/merchant-padala-reports",
   17: "/pos-sales-report",
   18: "/icarus-web",
   19: "/kiosk-status-reports",
   20: "/kiosk-add",
   21: "/kiosk-edit",
   22: "/pos-status-reports",
   23: "/ipx-transactions",
   24: "/cashout-requests",
   25: "/merchant-transactions"
};

const getUrlByPageId = (pageId) => {
  return pageUrls[pageId] || "/dashboard"; // Return a default URL if pageId is not found
};

const OtpPage = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState('');
  const [errorLogin,setErrorLogin] = useState('');
  const [loading, setLoading] = useState('');
  const [pageId, setPageId] = useState(0);
  const [token, setToken] = useState('');
  

  const handleUsername = (event)=>{
    setUserName(event.target.value);
  }

  const handlePassword = (event)=>{
    setPassword(event.target.value);
  }

  useEffect(() => {
    if(token){
      const r = jwtDecode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      getRolePages(r);
    }
  },[token]);

  useEffect(()=>{
    if(pageId){
      let url = getUrlByPageId(pageId);
      navigate(url);
    }
  },[pageId]);

  const Login = async ()=>{
    setErrorLogin('');
    setLoading('Verifying OTP, please wait!');
    await adminServices.verify(password).then((r)=>{
      if(r.status == 200){
          localStorage.setItem('appToken',r.data.token);
          setToken(r.data.token);
          
      }

      
    }).catch((e)=>{
      if(e.status == 409){
        setErrorLogin("Verification failed, please try again");
        setLoading('');
        return;
      }
    });
  }

  const getRolePages = async (r)=>{
      let form = new FormData();
  
      form.append('params',r);
  
      await adminServices.storedProcedure("getRolePages",form).then(response => {
          let pageIds = response.data.map(item => item.pageId);
          localStorage.setItem("pages", JSON.stringify(pageIds));
          setPageId(pageIds[0]);
      })
    }

  function togglePassword() {
    var passwordInput = document.getElementById("password");
    var showPasswordCheckbox = document.getElementById("showPassword");

    if (showPasswordCheckbox.checked) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }

  return (
    <div className="mainContainer">
      {/* Right Side */}
      <div className="loginPageContainer">
        <div className="loginPartition1">
          <div className="leftPart">
            {/* <div>
              <p>Login</p>
            </div>
            <div>
              <p>Sign Up</p>
            </div> */}
          </div>
        </div>

        {/* Left Side */}
        <div className="loginPartition2">
          <div className="rightPart">
            <div>
              <p className="xl medium">Verify OTP</p>
              <p className="r light">An email with a One Time Pin has been sent to your registered email.</p>
              <div style={{ height: "25px" }}></div>
              <div className="loginFields regular">
                <p>One Time Pin</p>
                <input
                  id="password"
                  className="m"
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={handlePassword}
                ></input>
              </div>

              <div className="flexRow" style={{color:'#FF0000',marginBottom:15, justifyContent:'center'}}>
                {errorLogin}
              </div>

              <div className="flexRow" style={{color:'green',marginBottom:15, justifyContent:'center'}}>
                {loading}
              </div>

              <div className="flexRow">
                <div className="flexRow m">
                  <input
                    type="checkbox"
                    id="showPassword"
                    onChange={togglePassword}
                  ></input>
                  <p>&nbsp;Show One Time Pin</p>
                </div>
                <div className="spacer"></div>
              </div>
              <div style={{ height: "20px" }}></div>
              <div className="loginButton">
                <Link style={{ textDecoration: "none" }}  onClick={Login}>
                  <p className="r">Verify</p>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
