import { useEffect, useState } from "react";
import InputText1 from "../components/InputText1";
import Button2 from "../components/Button2";
import AlertModal from "../components/AlertModal";
import { adminServices } from "../services/AdminServices";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import { useParams } from "react-router-dom";
import withAuthorization from "../components/withAuthorization";
import DisabledModal from "../components/DisabledModal";
import Button from "../components/Button";

const KioskPageEdit = ()=>{

    let {kioskId} = useParams();
    const [deviceId, setDeviceID] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [kioskName, setKioskName] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [kioskInfo, setKioskInfo] = useState({});
    const [kioskPin, setKioskPin] = useState('');
    const [quadrants, setQuadrants] = useState([]);

    useEffect(()=>{
        if(kioskId){
            kioskData();
        }
    },[]);

    const handleCheckboxChange = (value) => {
        setQuadrants((prev) => {
            if (prev.includes(value)) {
                return prev.filter((item) => item !== value); // Remove the item if it's already in the array
            } else {
                return [...prev, value]; // Add the item if it's not in the array
            }
        });
    };

    const kioskData = async ()=>{
        await adminServices.getKioskData(kioskId).then(r=>{
            if(r.status == 200){
                setKioskInfo(r.data);
                setDeviceID(r.data.appDeviceId);
                setSerialNumber(r.data.serialNumber);
                setKioskName(r.data.kioskName);
                setKioskPin(r.data.devicePin);
                setQuadrants(r.data.quadrants.split("|"));
            }
        }).catch(error=>{
            
            if(error.status == 401){
                window.location.href = "/";
            }
        })
    }

    return (<div style={{width:'100%'}}>
        <DisabledModal show={kioskInfo.deviceStatusId === 4} 
            message={"This kiosk is disabled. Please enable first to edit."}
            btnLabel={"Enable Kiosk"}
            onEnable={async ()=>{
                
                var form = new FormData();
                form.append("params",`${kioskId},1`);

                await adminServices.storedProcedure("setKioskStatus",form).then(r=>{
                    window.location.reload();
                }).catch(e=>{
                    if(e.status == 401){
                        window.location.href = "/";
                    }
                })

            }}
         />
        <h2>Edit Kiosk Terminal</h2>
        <AlertModal title={"Edit Kiosk Terminal"} show={showAlert} message={alertMessage} onClose={()=>{setShowAlert(false)}}></AlertModal>
        <form className="padalaForm">
        <InputText1 title={"Kiosk Device ID"}
            value={deviceId}
            placeholder={"App Device ID"}
            onChange={(e)=>{
                setDeviceID(e.target.value);
            }}
        />

        <InputText1 title={"Serial Number ID"}
            value={serialNumber}
            placeholder={"Serial Number"}
            onChange={(e)=>{
                setSerialNumber(e.target.value);
            }}
        />

        <InputText1 title={"Kiosk Name"}
            value={kioskName}
            placeholder={"Kiosk Name"}
            onChange={(e)=>{
                setKioskName(e.target.value);
            }}
        />

        <InputText1 title={"Kiosk Password"}
            value={kioskPin}
            type="password"
            placeholder={"Assign a Kiosk Password"}
            onChange={(e)=>{
                setKioskPin(e.target.value);
            }}
        />

        
        </form>

        <div className="w-100 d-flex justify-content-start mt-3 mb-3">
            <div className="row">
                <div className="col">
                    Not Applicable
                </div>
                <div className="col">
                    <input type="checkbox" checked={ quadrants.includes("N/A") ? true : false } value={"N/A"} onClick={() => handleCheckboxChange("N/A")} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Quadrant 1
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q1"} checked={ quadrants.includes("Q1") ? true : false } onClick={() => handleCheckboxChange("Q1")} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    Quadrant 2
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q2"} checked={ quadrants.includes("Q2") ? true : false } onClick={() => handleCheckboxChange("Q2")} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Quadrant 3
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q3"} checked={ quadrants.includes("Q3") ? true : false } onClick={() => handleCheckboxChange("Q3")} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    Quadrant 4
                </div>
                <div className="col">
                    <input type="checkbox" value={"Q4"} checked={ quadrants.includes("Q4") ? true : false }onClick={() => handleCheckboxChange("Q4")} />
                </div>
            </div>

            
        </div>

        <div className="btn-group">

            <Button variant={"success"} size={"lg"} onClick={async ()=>{
                    
                    if(deviceId === ''){
                        setAlertMessage('Kiosk Device ID Required');
                        setShowAlert(true);
                        return;
                    }
    
                    if(serialNumber === ''){
                        setAlertMessage('Serial Number Required');
                        setShowAlert(true);
                        return;
                    }
    
                    if(kioskName === ''){
                        setAlertMessage('Kiosk Name Required');
                        setShowAlert(true);
                        return;
                    }

                    if(kioskPin === ''){
                        setAlertMessage('Kiosk PIN Code Required');
                        setShowAlert(true);
                        return;
                    }
    
                    let form = kioskInfo;
    
                    form.appDeviceId = deviceId;
                    form.serialNumber = serialNumber;
                    form.kioskName = kioskName;
                    form.devicePin = kioskPin;
                    form.quadrants = quadrants.join("|");
    
                    await adminServices.updateKiosk(form).then((r)=>{
                        if(r.status == 201 || r.status == 204 || r.status == 200){
                            window.location.href="/kiosk-status-reports";
                        }
                    }).catch((e)=>{
                            if(e.status == 401){
                                window.location.href = "/";
                            }
    
                            if(e.status == 500){
                                setAlertMessage("An error occured, please check for duplicate entries");
                                setShowAlert(true);
                            }
                    });
                        
                    }}>Save Kiosk Info</Button>
            <Button variant={"danger"} size={"lg"}
            
                onClick={async ()=>{
                    var form = new FormData();
                    form.append("params",`${kioskId},4`);

                    await adminServices.storedProcedure("setKioskStatus",form).then(r=>{
                        window.location.reload();
                    }).catch(error=>{
                        if(error.status == 401){
                            window.location.href = "/";
                        }
                    })
                }}
            >Disable Kiosk</Button>
        </div>
    </div>);
}

export default withAuthorization(KioskPageEdit, 21);