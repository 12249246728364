import React, { useState, useEffect } from "react";
import CategoryComponent from "../components/services/Categories";
import withAuthorization from "../components/withAuthorization";

const Categories = () => {
    const [categories, setCategories] = useState([]);

    return (
        <CategoryComponent />
    );
}

export default withAuthorization(Categories,27);