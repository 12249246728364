import React from "react";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import { ReactComponent as AddIcon } from "../icons/add.svg";
import { ReactComponent as PencilIcon } from "../icons/pencil.svg";
import { ReactComponent as Add2Icon } from "../icons/add2.svg";
import { ReactComponent as TrashIcon } from "../icons/trash.svg";
import Button1 from "../components/Button1";
import DataTable from "react-data-table-component";
import { adminServices } from "../services/AdminServices";
import UnauthorizedComponent from "../components/UnauthorizedComponent";

const RFQList = ()=>{
    const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(()=>{
    getInventory();
  },[]);

  

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filteredRows = data.filter((row) =>
      row.supplier_name.toLowerCase().includes(query)
    );
    setFilteredData(filteredRows);
  };

  const conditionalRowStyles = [
    {
        when: row => row.current_quantity < row.critical_quantity,
        style: {
            backgroundColor: 'red',
            color: 'white',
        },
    },
];

  const getInventory = async()=>{
      var form = new FormData();
      form.append('params','');
      await adminServices.storedProcedure('rfq_list',form).then((r)=>{
          setData(r.data);
          setFilteredData(r.data);
          var _col = [
            { name: 'ID', selector: data=>data.rfq_id, sortable: true },
            { name: 'Supplier Name', selector: data=>data.supplier_name, sortable: true },
            { name: 'Total', selector: data=>data.total, sortable: true },
            { name: 'Date Requested', selector: data=>data.date_requested , sortable: true },
          ]
          setColumns(_col);
      }).catch((e)=>{
        if(e.response.status == 401){
          window.location.href= "/";
        }
      });
  }

  if(localStorage.getItem('currentRole') != 1) {
    return (<UnauthorizedComponent />);
  }

  return (
    <div className="mainContainer">
      <div className="flexRow contentHeader">
        {/* ADD PRODUCT MODAL */}
        
        <div className="flexColumn">
          <p className="xxl semibold" style={{ width: "100%" }}>
            Request for Quotations
          </p>
        </div>
        <div className="spacer"></div>
        <Button1 onClick={()=>{window.location.href="/rfq"}} text="Create RFQ" />
      </div>
      <div style={{ height: "25px" }}></div>
      <div className="flexRow filterDiv">
        <SearchBar
          placeholder="Search supplier"
          onChange={handleSearchChange}
        />
      </div>
      <div className="spacer-v"></div>
      <div className="tableContainer">
      <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          onRowClicked={()=>{}}
          conditionalRowStyles={conditionalRowStyles}
        >
          
        </DataTable>
      </div>
      {/* Table Footer */}
      
    </div>
  );
}


export default RFQList;