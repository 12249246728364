import axios, {AxiosResponse} from "axios";
let baseURL = process.env.REACT_APP_PUBLIC_URL;
let token = process.env.REACT_APP_IPX_TOKEN;
const IcarusServices = {
    storedProcedure
}

async function storedProcedure(sp,data){
    const config = {
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization : 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiUGFkYWxhIEFwcCIsIlBhZGFsYSI6InRydWUiLCJleHAiOjE4ODIyODYxODF9.mDn5AB58XgGuy7YvqYpmO07RaLonnb3lSpupW42cUiY',
        }
    }
    
    return await axios.post(`https://padala.ipxservices.com/api/sp/` + sp,data,config);
}

export default IcarusServices;