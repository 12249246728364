import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import Button1 from "../components/Button1";
import Modal from "react-modal";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";

const POSStatusReports = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(()=>{

    getPadala();

  },[]);

  setTimeout(()=>{
    getPadala();
  },60000);

  const getPadala = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('pos_machine_report',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'Username', selector: data=>data.username, sortable: true },
        { name: 'Merchant Name', selector: data=>data.merchant_name, sortable: true },
        { name: 'Device Status', selector: data=>data.online_status, sortable: true },
        { name: 'FP Scanner Status', selector: data=>data.scanner_status, sortable: true},
        { name: 'Last Update', selector: data=>data.as_of != null ? data.as_of +  " ago" : "", sortable: true },
        { name: 'Status', selector: data=>data.status == 1 ? "Active" : "Inactive", sortable: true},
    ]

    setColumns(_col);

    }).catch((e)=>{
      if(e.response.status == 401){
        window.location.href="/";
      }
    })

  }

  return (
    <div>
      <Modal show={true} />
      <h2>POS Status Report</h2>
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
      >
        
      </DataTable>
    </div>
  );
};

export default withAuthorization(POSStatusReports, 22);
