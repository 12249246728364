import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DataTable from 'react-data-table-component';
import { walletServices } from "../services/WalletService";
import InputText1 from "./InputText1";
import { adminServices } from "../services/AdminServices";
import { formatCurrency } from "../tools/tool";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex : 9999999
    }, 
    content: {
        width: '80%', margin: 'auto', 
        height : '90%'
      
    },
 };
const MerchantList = ({token,isOpen, onClose ,selectedInfo})=>{
    const [selectedData, setSelectedDate] = useState(null);
    const [clients, setClients] = useState(null);
    const [data, setData] = useState(null);
    const [columns, setColumns] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    useEffect(()=>{
        getMerchants();
    },[])

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filteredRows = data.filter((row) =>
      row.merchant_name.toLowerCase().includes(query) ||
      row.pdl_name.toLowerCase().includes(query) ||
      row.description.toLowerCase().includes(query)
    );
    setFilteredData(filteredRows);
  };

    


    const getMerchants = async ()=>{
        var form = new FormData();

        form.append('params','');
        await adminServices.storedProcedure('merchant_list',form).then((r)=>{
            setData(r.data);
            setFilteredData(r.data);
            var _col = [
                { name: 'ID', selector: data=>data.id, sortable: true },
                { name: 'Merchant Name', selector: data=>data.merchant_name, sortable: true },
                { name: 'Description', selector: data=>data.description, sortable: true },
                { name: 'Type', selector: data=>data.type, sortable: true },
                { name: 'PDL', selector: data=>data.pdl_name, sortable: true },
                { name: 'Wallet Balance', selector: data=>formatCurrency(data.wallet_amount), sortable: true, right:true },
            ]
            setColumns(_col);
        }).catch((r)=>{
            if(r.response.status === 401){
                window.location.href = "/";
            }
        });
    }

    const handleSelectedRow = (row)=>{
        console.log(row);
    }

    return (
        <div>
            <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            >

        <div style={{display:"flex",justifyContent:'space-between',zIndex:999}}>
                <h3>Merchant List</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
        </div>
                
                <div>
                    <div>
                        <input type="search" placeholder="Search" style={{
                            height:35,
                            width: '50%',
                            paddingLeft: 10
                        }}
                        onChange={handleSearchChange}
                        value={searchQuery}
                        ></input>
                    </div>
                    <DataTable 
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        onRowClicked={selectedInfo}
                    >

                    </DataTable>
                </div>

            </Modal>
        </div>
    )
}

export default MerchantList;