import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import { adminServices } from "../../services/AdminServices";
import InputText1 from '../InputText1';
import InputText2 from '../InputText2';
import Button from '../Button';
import LoadingModal from "../LoadingModal";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '80%', margin: 'auto', 
        height : '500px'
      
    },
 };

const UserFormComponent = ({show,onClose,data = null, onAfterSubmit, onError})=>{
    const [validateMessage, setValidateMessage] = useState("Please fill all fields");  
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [selectedRole, setSelectedRole] = useState(0);
    const [isAdmin, setIsAdmin] = useState(0);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [userId, setUserId] = useState(0);
    const [roles, setRoles] = useState([]);

    useEffect(()=>{
        getRoles();
    },[]);

    useEffect(()=>{
        if(data){
            setFirstName(data.FirstName);
            setLastName(data.LastName);
            setEmail(data.EmailAddress);
            setMobileNumber(data.MobileNumber);
            setIsAdmin(data.IsAdmin == 'Yes' ? 1 : 0);
            setSelectedRole(data.role_id);
            setUserId(data.user_id);
        }
        else{
            setFirstName("");
            setLastName("");
            setEmail("");
            setMobileNumber("");
            setIsAdmin(null);
            setSelectedRole(0);
            setValidated(false);
        }
    },[data]);

    const getRoles = async() => {
        let form = new FormData();
        form.append("params",'');
        await adminServices.storedProcedure("getUserRoles",form).then(response => {
          if(response.status === 200){
            setRoles(response.data);
          }
        }).catch(error => {
          if(error.status === 401){
            window.location.href = "/";
          }
        })
      };

    const validateForm = () => {
        if(firstName == "" || lastName == "" || email == "" || mobileNumber == "" || selectedRole == 0){
            setValidateMessage('Please fill all fields');
          return false;   
        }

        const mobileNumberPattern = /^0\d{10}$/; 
        if (!mobileNumberPattern.test(mobileNumber)) 
        { 
            setValidateMessage('Invalid Mobile Number');
        
            return false;
        }

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
        if (!emailPattern.test(email)) 
        { 
            setValidateMessage('Invalid Email Address');
            return false;
        }

        return true;
    }

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const emailCreds = async (newPass)=>{
        var formData = new FormData();
        formData.append("EmailAddress",email);
        formData.append("Password",newPass);
        setLoading(true);
        await adminServices.emailCredentials(formData).then(response => {
            if(response.status === 200){
                onAfterSubmit(newPass);
                onClose();
                setLoading(false);
            }
        }).catch(error => {
            if(error.status === 401){
                window.location = "/";
            }
            console.log(error);
        });
    }

    const handleSubmit = async () => {
        if(validateForm()){
            setLoading(true);
            let form = new FormData();
            
            let newPassword = generateRandomString(12);
            setPassword(newPassword);

            if(data == null){
                form.append("params",`'${firstName}','${lastName}','${email}','${newPassword}','${mobileNumber}',1,${selectedRole},${selectedRole},1,${isAdmin}`);    
                adminServices.storedProcedure("AddUser",form).then(response => {
                    if(response.data[0].message != "User already exists"){
                        emailCreds(newPassword);
                        
                    }else{
                        onAfterSubmit("409");
                        setLoading(false);
                    }
                }).catch(error => {
                    if(error.status == 401){
                        window.location = "/";
                    }
                    
                    onError();
                    onClose();

                })
            }
            else{
                form.append("params",`${userId},'${firstName}','${lastName}','${email}','${mobileNumber}',1,${isAdmin},${selectedRole}`);    
                adminServices.storedProcedure("UpdateUser",form).then(response => {
                    console.log(response);
                    if(response.data[0].message == "Successfully Updated"){
                        onClose();
                        onAfterSubmit("204");
                    }else{
                        onClose();
                        onAfterSubmit("406");

                    }
                }).catch(error => {
                    if(error.status == 401){
                        window.location = "/";
                    }
                    
                    onError();
                    onClose();

                }).finally(()=>{
                    setLoading(false);
                });
            }
            
        }
        else{
            setValidated(true);
        }
    }

    return (
        <Modal
            
            isOpen={show}
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            onRequestClose={onClose}
        >
         <LoadingModal show={loading} message={"Saving data..."} />
         <div style={{display:"flex",justifyContent:'space-between',zIndex:999}}>
                <h3>User Management</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <div className="flexRow">
                <InputText1 title={"First Name"} value={firstName} onChange={(e)=>{ setFirstName(e.target.value) }} />
                <InputText1 title={"Last Name"} value={lastName} onChange={(e)=>{ setLastName(e.target.value) }} />
                <InputText1 title={"Email Address"} value={email} onChange={(e)=>{ setEmail(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Mobile Number"} value={mobileNumber} onChange={(e)=>{ setMobileNumber(e.target.value) }} />
                <div style={{ margin: "20px", flex: "2" }}>
                    <p className="semibold ">{"Role"}</p>
                    <select className="form-control" style={{width:'200px'}} value={selectedRole} onChange={(e)=>{ setSelectedRole(e.target.value) }} >
                        <option value={0} selected disabled>Select a Role</option>
                        {roles && roles.map((role,index) => {
                            return <option key={index} value={role.role_id}>{role.role_name}</option>
                        })}
                    </select>
                </div>
                <div style={{ margin: "5px", flex: "2" }}>
                    <p className="semibold ">{"Is Super User?"}</p>
                    <select className="form-control" style={{width:'200px'}} value={isAdmin} onChange={(e)=>{ setIsAdmin(e.target.value) }} >
                        <option value={0}>No</option>
                        <option value={1}>Yes</option>
                    </select>
                </div>
                
            </div>

            <div className="flexRow" style={{justifySelf:'end'}}>
                    <div>
                        {validated ? <p style={{color:'red',marginTop:10,marginRight:10}}>{validateMessage}&nbsp;</p> : ""}
                    </div>
                    <Button variant={"success"} size={'md'} onClick={handleSubmit}>
                        Submit
                    </Button>
                    &nbsp;
                    <Button variant={"secondary"} size={'md'} onClick={onClose}>
                        Cancel
                    </Button>
            </div>
        </Modal>
    )
}

export default UserFormComponent;