import React,{ useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {ReactComponent as VoidIcon} from '../icons/void.svg';
import LoadingModal from "../components/LoadingModal";
import { adminServices } from "../services/AdminServices";
import SearchBar from "../components/SearchBar";
import Button from "../components/Button";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
import { formatCurrency } from "../tools/tool";
import InputText1 from "../components/InputText1";

const VoidTransactions = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    useEffect(() => {
        setLoading(true);
        getVoidTransactions();
    },[]);

    

    const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'VoidTransactions.csv'); link.click(); };

    const getVoidTransactions = async ()=>{
        var form  = new FormData();
        
        let module = dateFrom && dateTo ? "void_transaction_range" : "void_transactions";

        if(dateFrom && dateTo){
            form.append("params", `'${dateFrom}','${dateTo}'`);
        }
        else{
            form.append("params", "");
        }

        await adminServices.storedProcedure(module,form).then((r)=>{
            setLoading(false);
            setData(r.data);
            setFilteredData(r.data);
            let _col = [
                { name: 'Voided Date', selector: data=>new Date(data.voided_date).toLocaleString(), sortable: true },
                { name: 'Amount', selector: data=>formatCurrency(data.amount), sortable: true,right:true, headerStyle: {
                    textAlign: 'center',
                  }, },
                { name: 'Transaction Date', selector: data=>new Date(data.transaction_date).toLocaleString(), sortable: true },
                { name: 'Void Reason', selector: data=>data.reason, sortable: true },
                { name: 'Voucher/Reference No', selector: data=>data.ref_no, sortable: true },
                { name: 'PDL No', selector: data=>data.pdl_number, sortable: true },
                { name: 'PDL Name', selector: data=>data.pdl_name, sortable: true },
                { name: 'Transaction', selector: data=>data.transaction_type, sortable: true },
                { name: 'Voided By', selector: data=>data.voided_by, sortable: true },

                
                
            ]
            setColumns(_col);
        }).catch((e)=>{
            console.log(e);
        });
    }

    return (
        <div>
            <LoadingModal show={loading} message={"Loading Void Transactions..."} />
            <div className="sc-fPXMhL QAQQD" style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
                <VoidIcon style={{ width: "50px", height: "50px", marginRight: "10px" }} />
                <h3>Void Transactions</h3>
            </div>

            <div className="flexRow" style={{width:'50%'}}>
                <InputText1 title={"Date From"} type="date" value={dateFrom} onChange={(e)=>{
                    setDateFrom(e.target.value);
                }}  />

                <InputText1 title={"Date To"} type="date" value={dateTo} onChange={async(e)=>{
                    setDateTo(e.target.value);
                }}  />

                <div style={{marginTop:18}}>
                    <Button variant={"primary"} onClick={()=> { getVoidTransactions() }}>Search</Button>
                </div>

            </div>

            <div className="flexRow">
                <div className="flexRow filterDiv">
                <SearchBar
                    placeholder="Search Voided Transactions"
                    onChange={(e)=>{
                    const value = e.target.value.toLowerCase();
                    const filtered = data.filter(item => 
                        Object.keys(item).some(key => 
                        item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                        ) 
                    );
                    setFilteredData(filtered);

                    let gT = filtered.reduce((total, item) => total + item.gross_amount, 0)
                    // setGrandTotal( gT ) ;
                    }}
                />
                </div>
                &nbsp;&nbsp;
                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
                >
                </div>
                <div className="spacer"></div>
                <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
            </div>

            
            <br />
            <DataTable
                columns={columns}                
                data={filteredData}
                pagination={false}
                striped
                persistTableHead
                pointerOnHover
                highlightOnHover
            />
        </div>);

};

export default withAuthorization(VoidTransactions,6);