import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { adminServices } from "../services/AdminServices";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState('');
  const [errorLogin,setErrorLogin] = useState('');
  const [loading, setLoading] = useState('');
  

  const handleUsername = (event)=>{
    setUserName(event.target.value);
  }

  const handlePassword = (event)=>{
    setPassword(event.target.value);
  }

  const Login = ()=>{
    setErrorLogin('');
    setLoading('Verifying Account, please wait!');
    adminServices.forgoPassword(userName).then((r)=>{
      if(r.status == 200){
        localStorage.setItem("secretKey",r.data.secretKey);
        localStorage.setItem('emailAddr',userName);
        window.location.href = "/reset-password";
      }

      
    }).catch((e)=>{
      if(e.status == 404){
        setErrorLogin("User Account Not Found");
        setLoading('');
        return;
      }

      if(e.status == 500){
        setErrorLogin("Server Error, please try again later.");
        setLoading('');
        return;
      }
    });

    

    // adminServices.getToken().then((r)=>{
    //   if(r.status==200){
    //     localStorage.setItem('adminServicesToken',r.data.token);
    //     window.location.href = '/dashboard';
    //   }
    // }).catch((e)=>{
    //   setErrorLogin('Error logging in, check your connection and try again.');
    // });

  }

  function togglePassword() {
    var passwordInput = document.getElementById("password");
    var showPasswordCheckbox = document.getElementById("showPassword");

    if (showPasswordCheckbox.checked) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }

  return (
    <div className="mainContainer">
      {/* Right Side */}
      <div className="loginPageContainer">
        <div className="loginPartition1">
          <div className="leftPart">
            {/* <div>
              <p>Login</p>
            </div>
            <div>
              <p>Sign Up</p>
            </div> */}
          </div>
        </div>

        {/* Left Side */}
        <div className="loginPartition2">
          <div className="rightPart">
            <div>
              <p className="xl medium">Forgot Password</p>
              <p className="r light">Please enter your email address to reset your password.</p>
              <div style={{ height: "25px" }}></div>
              <div className="loginFields regular">
                <p>Email Address</p>
                <input
                  id="password"
                  className="m"
                  type="text"
                  placeholder="Email Address"
                  value={userName}
                  onChange={handleUsername}
                ></input>
              </div>

              <div className="flexRow" style={{color:'#FF0000',marginBottom:15, justifyContent:'center'}}>
                {errorLogin}
              </div>

              <div className="flexRow" style={{color:'green',marginBottom:15, justifyContent:'center'}}>
                {loading}
              </div>

             
              <div style={{ height: "20px" }}></div>
              <div className="loginButton">
                <Link style={{ textDecoration: "none" }}  onClick={Login}>
                  <p className="r">Reset Password</p>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
