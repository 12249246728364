import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import SearchBar from "../components/SearchBar";
import Modal from "react-modal";
import JSONPretty from "react-json-pretty";
import LoadingModal from "../components/LoadingModal";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import Button from "../components/Button";
import InputText1 from "../components/InputText1";

const LogReports = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState('');
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [viewData, setViewData] = useState(false);
  const [isJson, setIsJson] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '80%', margin: 'auto', 
        height : '500px'
      
    },
 };

  useEffect(()=>{
    setLoading(true);
    getLogs();

  },[]);

  const exportToCSV = (data) => {
    // Map the data to change keys for headers
    const mappedData = data.map(row => ({
        ID: row.audit_log_id,
        Timestamp: `${new Date(row.date_time).toLocaleDateString()} ${ new Date(row.date_time).toLocaleTimeString() }`,
        User: row.user,
        Event: row.action,
        Module: row.table,
        // Add more fields if necessary
    }));

    // Get CSV headers from the modified keys
    const csvHeaders = Object.keys(mappedData[0]).join(',');

    // Map rows to values
    const csvRows = mappedData.map(row => Object.values(row).join(','));

    // Combine headers and rows
    const csvContent = [csvHeaders, ...csvRows].join('\n');

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'Logs.csv');
    link.click();
};
  

  const getLogs = async ()=>{
    var form = new FormData();
    form.append('params','');
    try{
      let module = dateFrom && dateTo ? "get_log_range" : "get_logs";

      if(dateFrom && dateTo){
          form.append("params", `'${dateFrom}','${dateTo}'`);
      }
      else{
          form.append("params", "");
      }

      const response = await adminServices.storedProcedure(module, form);
      const logs = response.data;

      setKioskSales(null);
      setKioskSales(response.data);
      setData(response.data);
      setFilteredData(response.data);

      var _col = [
        { name: 'ID', selector: data=>data.audit_log_id, sortable: true, width: '80px' },
        { name: 'Timestamp', selector: data=>new Date(data.date_time).toLocaleString(), sortable: true,width: '250px' },
        { name: 'User', selector: data=>data.user, sortable: true },
        { name: 'Event', selector: data=>data.action, sortable: true },
        //{ name: 'Data', selector: data=>data.data, sortable: true },
        { name: 'Module', selector: data=>data.table, sortable: true },
    ]

      setColumns(_col);
      setLoading(false);
    }
    catch(e){

    }
  }

  const HTMLPrettyPrint = ({ html }) => (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );

  if(localStorage.getItem('currentRole') != 1) {
    return (<UnauthorizedComponent />);
  }

  return (
    <div >
      <LoadingModal show={loading} message={"Loading Logs Report..."} />
      <h2>Logs Report</h2>
      <Modal style={customStyles} isOpen={viewData} onRequestClose={()=>{ setViewData(false); setEventData(''); }}>
      <div style={{display:"flex",justifyContent:'end',position:'fixed',right:169,zIndex:999}}>
                    <button style={{backgroundColor:'#fff',
                        borderColor:'#fff',
                        color:'#555',
                        borderRadius:100,
                        fontSize:20,
                        fontWeight: 'bolder',
                        fontFamily: 'monospace',
                        borderWidth:0}}
                        onClick={()=>{ setViewData(false); setEventData(''); }}
                        >
                            x
                        </button>
                </div>
        <h2>Data Log</h2>
        <div>
          {isJson && <JSONPretty data={eventData} />}
          {!isJson && <HTMLPrettyPrint html={eventData} />}
        </div>
      </Modal>
      <div className="flexRow" style={{width:'50%'}}>
          <InputText1 title={"Date From"} type="date" value={dateFrom} onChange={(e)=>{
              setDateFrom(e.target.value);
          }}  />

          <InputText1 title={"Date To"} type="date" value={dateTo} onChange={async(e)=>{
              setDateTo(e.target.value);
          }}  />

          <div style={{marginTop:18}}>
              <Button variant={"primary"} onClick={()=> { getLogs() }}>Search</Button>
          </div>

      </div>
      <div className="flexRow filterDiv">
        <SearchBar
          placeholder="Search"
          onChange={(e)=>{
            
            const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);

          }}
        />
        <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
      </div>
      <div style={{ height: "25px" }}></div>
      <div style={{maxWidth:1330, overflow:'auto'}}>
        <DataTable 
            columns={columns}
            data={filteredData}
            pagination={false}
            highlightOnHover
            style={{maxWidth:1330}}
            onRowClicked={(e)=>{
                setViewData(true);
                
                try {
                  JSON.parse(e.data);
                  let j = JSON.parse(e.data);
                  
                  setEventData(j);

                  setIsJson(true);
                } catch (error) {
                  setEventData(e.data);
                  setIsJson(false);
                }
              
            }}
        >
          
        </DataTable>
      </div>
    </div>
  );
};

export default LogReports;
