import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { adminServices } from "../services/AdminServices";
import {ReactComponent as BackIcon} from "../icons/back.svg";
import LoadingModal from "../components/LoadingModal";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import Button from "../components/Button";
import Modal from "react-modal";
import ReactModal from "react-modal";
import { v4 as uuidv4 } from 'uuid';
import InputText1 from "../components/InputText1";
import AlertModal from "../components/AlertModal";
import withAuthorization from "../components/withAuthorization";
import SearchBar from "../components/SearchBar";

const customStyles = { 
  overlay: { 
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
  }, 
  content: {
      width: '30%', margin: 'auto', 
      height : '60%',
      backgroundColor:'transparent',
      border: 0
  },
};

const MerchantTransactions = () => {
  const navigate = useNavigate();
  const [merchantId, setMerchantId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [params, setParams] = useState(null);
  const paramsFromQuery = localStorage.getItem("selectedMerchant");
  const [loading, setLoading] = useState(false);
  const [makeTransfer, setMakeTransfer] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [formSeqNo, setFormSeqNo] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isValidated, setIsValidate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getQueryParams(atob(paramsFromQuery));
  }, []); 

  

  const handleTransactionClick = (id) => {
    navigate(`/transactions/${id}`);
  };

  const getQueryParams = async (str) => {
   setLoading(true);
    const json = JSON.parse(str);
    setParams(json);
    await getMerchantTransactions(json.id);
    
  }
  const getMerchantTransactions = async (id) => {
    setMerchantId(id);
    var form = new FormData();
    form.append('params',id);
    adminServices.storedProcedure('get_merchant_transactions_by_id',form).then((response)=>{
        setTransactions(response.data);
        setFilteredData(response.data);
        var _col = [
            { name: 'Transaction Date', selector: data=>data.timestamp, sortable: true },
            { name: 'Reference Number', selector: data=>data.ref_no, sortable: true },
            { name: 'PDL Number', selector: data=>data.pdl_number , sortable: true },
            { name: 'PDL', selector: data=>data.first_name + " " + data.last_name, sortable: true },
            { name: 'Amount', selector: data=>data && data.amount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }), sortable: true, right: true},
            { name: 'Remarks', selector: data=>data.remarks, sortable: true }
          ]
        setColumns(_col);
        setLoading(false);
    }).catch((error)=>{
        console.log(error);
        if(error.status === 401){
            navigate('/');
        }
    })
  }


  const handleTransfer = ()=>{
      setMakeTransfer(true);
  }

  const validateForm = ()=>{
    
    if(transferAmount <=0){
      setIsValidate(false);
      setValidationMessage("Transfer Amount should be at least 1");
      return false;
    }

    if(formSeqNo === ""){
      setIsValidate(false);
      setValidationMessage("Form Sequence Number Required.");
      return false;
    }

    return true;
  }

  const handleSubmit = async()=>{
      

      if(validateForm() && isValidated){
        const formData = new FormData();

        let uuid = uuidv4();
        let refNo = uuid.split('-')[4];
        let remarks = "Order Payment to IPX";
        formData.append('params',`${merchantId},4,'${refNo.toUpperCase()}',${transferAmount},'${formSeqNo}','${remarks}'`);
        
        await adminServices.storedProcedure('TransferMerchantFunds',formData).then(response => {
          if(response.data[0].Status == "202"){
            setIsSuccess(true);
            setShowAlert(true);
            setAlertMessage("Merchant Fund Transfer to IPX is successful. Transaction Ref: " + refNo.toUpperCase());
          }
          else{
            setShowAlert(true);
            setAlertMessage("Error while transferring funds to IPX. Please try again later.");
            setIsSuccess(false);
          }
        }).catch(error => {
          if(error.status === 401){
            window.location.href = "/";
          }
        }).finally(()=>{
          setMakeTransfer(false);
        });
      }

      
  }
  
  const handleClose = ()=>{  
    
    setShowAlert(false);

    if(isSuccess){ 
      window.location.href = "/merchant";
    }
  }
  

  return (
    <div>
        <AlertModal show={showAlert} title="Transfer Funds to IPX" message={alertMessage} onClose={handleClose} />
        <Modal style={customStyles} isOpen={makeTransfer} onRequestClose={()=>{ setMakeTransfer(false); }}>
            <div className="card">
                <div className="card-header">Make a Transfer to IPX</div>
                <div className="card-body d-flex flex-column align-item-center">
                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Amount to Transfer"} onChange={(e)=>{
                          setValidationMessage("");
                          setIsValidate(true);
                          if(e.target.value == "e"){
                            setIsValidate(false);
                            setValidationMessage("Invalid Amount");
                          }

                          if(e.target.value == ""){
                            setIsValidate(false);
                            setValidationMessage("Transfer Amount should be at least 1");
                          }

                          if(e.target.value <=0){
                            setIsValidate(false);
                            setValidationMessage("Transfer Amount should be at least 1");
                          }

                          if(e.target.value > params.wallet_amount){
                            setIsValidate(false);
                            setValidationMessage("Transfer Amount cannot be greater than merchant wallet");
                          }

                          setTransferAmount(e.target.value);

                      }} value={transferAmount} type="number" />
                    </div>
                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Form Sequence Number"} onChange={(e)=>{
                          const regex = /^[a-zA-Z0-9]*$/;
                          if (regex.test(e.target.value)) {
                            setFormSeqNo(e.target.value.toUpperCase());
                          }
                          
                      }} value={formSeqNo} />
                    </div>

                    {/* <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Remarks"} onChange={(e)=>{
                        setRemarks(e.target.value);
                      }} value={remarks} type="text" />
                    </div> */}

                    <div className="text-danger">
                        {!isValidated && validationMessage}
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                    <Button variant={"success"} onClick={handleSubmit}>Submit</Button>
                    &nbsp;
                    <Button variant={"danger"} onClick={()=>{ 
                       setMakeTransfer(false);
                    }}>Cancel</Button>
                </div>
            </div>
        </Modal>


        <LoadingModal show={loading} message={"Loading Merchant Transactions..."} />
        <div style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', marginBottom: '20px'}}>
            <div className="d-flex align-items-center">
              <BackIcon style={{cursor: 'pointer', marginRight: '10px',width: '50px', height: '50px'}} onClick={()=>{
                  navigate('/merchant');
              }}  />
              <h2>Merchant Transactions ({params && params.merchant_name} - Php {params && params.wallet_amount !== undefined ?  params.wallet_amount.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
              }) : "0.00" }) </h2>
            </div>

            <div className="d-flex justify-content-end">
               <Button variant={"primary"} size={"lg"} onClick={handleTransfer}> Transfer </Button>
            </div>
        </div>

        <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Transactions"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = transactions.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
       
      </div>
      <br></br>
        
        <DataTable 
            columns={columns}
            data={filteredData}
            pagination={false}
            persistTableHead={true}
        />
     </div>);
};

export default withAuthorization(MerchantTransactions,25);