import React from "react";
import {ReactComponent as UnauthorizedIcon} from "../icons/locked.svg";

const UnauthorizedComponent = () => {
  return (
    <div className="text-center">
      <UnauthorizedIcon style={{width: '100',height:'100',marginTop:'50px',marginBottom:'50px'}}/>
      <h1>Unauthorized Access</h1>
      <br />
      <h6>Contact your system administrator for access.</h6>
    </div>
  );
};

export default UnauthorizedComponent;