import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable from "react-data-table-component";
import {ReactComponent as TransactionIcon} from '../icons/transactions.svg';
import { useNavigate } from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import SearchBar from "../components/SearchBar";
import Button from "../components/Button";
import {formatCurrency} from '../tools/tool';
import InputText1 from "../components/InputText1";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
const PadalaReports = () => {
  const navigate = useNavigate();
  const [padalaList, setPadalaList] = useState(null);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  useEffect(()=>{
    setLoading(true);
    getPadala();
  },[]);

  let columns = [
    { name: 'Transaction Date', selector: data=>data.reg_date, sortable: true, defaultSortAsc: false },
    { name: 'PDL Name', selector: data=>data.pdlName, sortable: true },
    { name: 'PDL Number', selector: data=>data.pdl_number, sortable: true },
    { name: 'Net Amount', selector: data=>formatCurrency(data.net_amount), sortable: true, right: true,
    headerStyle: {
      textAlign: 'center',
    }, },
    { name: 'Interest Rate', selector: data=>formatCurrency(data.interest_rate), sortable: true, right: true,
    headerStyle: {
      textAlign: 'center',
    }, },
    { name: 'Gross Amount', selector: data=>formatCurrency(data.gross_amount), sortable: true, right: true,
    headerStyle: {
      textAlign: 'center',
    }, },
    
    { name: 'Reference' , selector: data=>data.bank_reference, sortable: true },
    { name: 'Sender', selector: data=>data.sender, sortable: true },
    { name: 'Cash In From', selector: data=>data.cash_in_from, sortable: true },
    { name: 'Processed By', selector: data=>data.processed_by, sortable: true }
  ];

  

  const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'PadalaReport.csv'); link.click(); };

  const getPadala = async ()=>{
    var form = new FormData();
    form.append('params','');
    await adminServices.storedProcedure('padala_report',form).then((r)=>{
      setPadalaList(null);
      setPadalaList(r.data);
      setData(r.data);
      setFilteredData(r.data);

      let gT = r.data.reduce((total, item) => total + item.gross_amount, 0)
      setGrandTotal( gT ) ;

      
      setLoading(false);

    }).catch((e)=>{
      if(e.status == 401){
        window.location.href = '/';
      }
    });
    

  }

  const PadalaRange = async ()=>{
    var form = new FormData();
    form.append('params',`'${dateFrom}','${dateTo}'`);
    await adminServices.storedProcedure('padala_report_range',form).then((r)=>{
      setPadalaList(null);
      setPadalaList(r.data);
      setData(r.data);
      setFilteredData(r.data);

      let gT = r.data.reduce((total, item) => total + item.gross_amount, 0)
      setGrandTotal( gT ) ;
      setLoading(false);

    }).catch((e)=>{
      if(e.status == 401){
        window.location.href = '/';
      }
    });
    

  }

  return (
    <div>
      <LoadingModal show={loading} message={"Loading Padala Report"} />
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
          Padala Report ({formatCurrency(grandTotal)})
      </div>

      <div className="flexRow" style={{width:'50%'}}>
          <InputText1 title={"Date From"} type="date" value={dateFrom} onChange={(e)=>{
            setDateFrom(e.target.value);
          }}  />

         <InputText1 title={"Date To"} type="date" value={dateTo} onChange={async(e)=>{
            setDateTo(e.target.value);
          }}  />

          <div style={{marginTop:18}}>
            <Button variant={"primary"} onClick={PadalaRange}>Search</Button>
          </div>

      </div>

      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Padala Report"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);

              let gT = filtered.reduce((total, item) => total + item.gross_amount, 0)
              setGrandTotal( gT ) ;
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
      </div>
      
      <br />
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination={false}
          persistTableHead
          persistTableBody
          highlightOnHover
          style={{zIndex:0}}
          defaultSortFieldId="reg_date"
      />
    </div>
  );
};

export default withAuthorization(PadalaReports,4);
