import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import { adminServices } from "../../services/AdminServices";
import InputText1 from '../InputText1';
import InputText2 from '../InputText2';
import Button from '../Button';
import LoadingModal from "../LoadingModal";
import ConfirmationDialog from "../ConfirmationDialog";
import DisabledModal from "../DisabledModal";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '50%', margin: 'auto', 
        height : '450px'
      
    },
 };

const ServiceFormComponent = ({show,onClose,data = null, onAfterSubmit, onError})=>{
    const [serviceName, setServiceName] = useState('');
    const [price, setPrice] = useState(0);
    const [unit, setUnit] = useState('');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [validateMessage, setValidatMessage] = useState("Please fill all the required fields");
    const [status, setStatus] = useState(1);

    useEffect(()=>{
        if(data){
            console.log(data);
            setServiceName(data.serviceName);
            setPrice(data.price);
            setUnit(data.unit);
            setValidated(false);
            setStatus(data.status);
        }
        else{
            setServiceName();
            setPrice("");
            setUnit("");
            setValidated(false);
            setStatus(1);
        }
    },[data]);

    const validateForm = () => {
        if(serviceName == "" || price == 0 || unit == ""){
            setValidatMessage("Please fill all the required fields ")
            return false;
        }

        if(price < 0){
            setValidatMessage("Price cannot be lower than 0 ");
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {
        if(validateForm()){
            setLoading(true);
            
            if(data.serviceName == ""){
                let formData = new FormData();
                formData.append('params',`${data.merchantId},${data.categoryId},'${serviceName.replace(/'/g, "''")}','${price}','${unit.replace(/'/g, "''")}'`)

                adminServices.storedProcedure('AddService',formData).then((response)=>{
                    onClose();
                    onAfterSubmit("add");
                }).catch((error)=>{

                    if(error.status == 401){
                        window.location.href = "/";
                        return;
                    }

                    setLoading(false);
                    onError();
                    onClose();
                }).finally(()=>{
                    setLoading(false);
                });
            }
            else{
                let form = new FormData();
                form.append('params',`${data.serviceId},'${serviceName.replace(/'/g, "''")}','${price}','${unit.replace(/'/g, "''")}'`);

                adminServices.storedProcedure('UpdateService',form).then((response)=>{
                    onClose();
                    onAfterSubmit("edit");
                }).catch((error)=>{
                    setLoading(false);
                    onError();
                    onClose();
                }).finally(()=>{
                    setLoading(false);
                });;
            }
            
        }
        else{
            setValidated(true);
        }
    }

    const handleDelete = () => {
        let formData = new FormData();
        formData.append('params',`${data.serviceId}`);

        adminServices.storedProcedure('DeleteService',formData).then((response)=>{
            onClose();
            if(response.data[0].message == "200"){
                onAfterSubmit("delete");
            }
            else{
                onAfterSubmit("disabled");
            }
            
        }).catch((error)=>{
            setLoading(false);
            onError();
            onClose();
        }).finally(()=>{
            setLoading(false);
            setShowConfirmation(false);
        });
    }

    const handleEnable = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('params',`${data.serviceId}`);

        await adminServices.storedProcedure('EnableService',formData).then((response)=>{
            setStatus(1);
        }).catch((error)=>{
            if(error.status == 401){
                window.location.href = "/";
            }
        }).finally(()=>{
            setLoading(false);
        });
    }

    return (
        <Modal
            
            isOpen={show}
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            onRequestClose={onClose}
        >
            <LoadingModal show={loading} message={"Saving data..."} />
            
            <ConfirmationDialog show={showConfirmation} title={"Delete Service?"} message={"Are you sure you want to delete this service?"}  onCancel={()=>{setShowConfirmation(false)}} onConfirm={handleDelete} />
         <div style={{display:"flex",justifyContent:'space-between',zIndex:999}}>
                <h3>Service Management</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <div className="flexRow">
                <InputText1 title={"Service Name/Product Name"} value={serviceName} onChange={(e)=>{ setServiceName(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Price"} value={price} type={"number"} onChange={(e)=>{ setPrice(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Unit"} value={unit} onChange={(e)=>{ setUnit(e.target.value) }} />
            </div>

            <div className="flexRow" style={{justifySelf:'end'}}>
                    <div>
                        {validated ? <p style={{color:'red',marginTop:10}}>{validateMessage}&nbsp;</p> : ""}
                    </div>
                    {status == 0 &&  <Button variant={"primary"} size={'md'} onClick={handleEnable}>
                        Enable Service
                    </Button>}
                    { status == 1 &&  <Button variant={"success"} size={'md'} onClick={handleSubmit}>
                        Submit
                    </Button>}
                    &nbsp;
                    { status == 1 && (data && data.serviceId != null) &&   <Button variant={"danger"} size={'md'} onClick={()=>{
                        setShowConfirmation(true);
                    }}>
                        Delete / Disable
                    </Button>}
                    &nbsp;
                    <Button variant={"secondary"} size={'md'} onClick={onClose}>
                        Cancel
                    </Button>

            </div>
        </Modal>
    )
}

export default ServiceFormComponent;