import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import { adminServices } from "../../services/AdminServices";
import InputText1 from '../InputText1';
import InputText2 from '../InputText2';
import Button from '../Button';
import LoadingModal from "../LoadingModal";
import Dropdown from "../Dropdown";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '80%', margin: 'auto', 
        height : '300px'
      
    },
 };

const CategoryFormComponent = ({show,onClose,data = null, onAfterSubmit, onError})=>{
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(null);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(data){
            setCategory(data.category);
            setDescription(data.description);
            setStatus(data.status_id);
        }
        else{
            setCategory("");
            setDescription("");
            setStatus(null);
            setValidated(false);
        }
    },[data]);

    const validateForm = () => {
        if(category == "" || description == "" || status == null){
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {
        if(validateForm()){
            setLoading(true);
            let payload = {
                category: category,
                description: description,
                statusId: status
            }

            if(data == null){
                adminServices.postData('ServiceCategories',payload).then((response)=>{
                    setLoading(false);
                    if(response.status == 201 || response.status == 200){
                        onClose();
                        onAfterSubmit();
                    }
                }).catch((error)=>{
                    setLoading(false);
                    onError();
                    onClose();
                });
            }
            else{
                payload = data;
                data.category = category;
                data.description = description;
                data.statusId = status;
                payload.regDate = data.reg_date;
                adminServices.updateData('servicecategories/' + data.id,payload).then((response)=>{
                    if(response.status == 201 || response.status == 204){
                        setLoading(false);
                        onClose();
                        onAfterSubmit();
                    }
                }).catch((error)=>{
                    setLoading(false);
                    onError();
                    onClose();
                });
            }
            
        }
        else{
            setValidated(true);
        }
    }

    return (
        <Modal
            
            isOpen={show}
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            onRequestClose={onClose}
        >
        <LoadingModal show={loading} message={"Saving data..."} />
         <div style={{display:"flex",justifyContent:'space-between',zIndex:999}} className="mb-3">
                <h3>Category Management</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <div className="flexRow">
                <InputText1 title={"Category"} value={category} onChange={(e)=>{ setCategory(e.target.value) }} />
                <InputText1 title={"Description"} value={description} onChange={(e)=>{ setDescription(e.target.value) }} />
                <Dropdown title={"Status"} value={status} onChange={(e)=>{ setStatus(e.target.value) }} 
                    data= {[
                        {value:null,label:"Please select status"},
                        {value:1,label:"Active"},
                        {value:0,label:"Inactive"}
                    ]}
                  />
            </div>

            <div className="flexRow" style={{justifySelf:'end'}}>
                    <div>
                        {validated ? <p style={{color:'red',marginTop:10}}>Please fill all fields&nbsp;</p> : ""}
                    </div>
                    <Button variant={"success"} size={'md'} onClick={handleSubmit}>
                        Submit
                    </Button>
                    &nbsp;
                    <Button variant={"secondary"} size={'md'} onClick={onClose}>
                        Cancel
                    </Button>
            </div>
        </Modal>
    )
}

export default CategoryFormComponent;