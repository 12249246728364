import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import { ReactComponent as TransactionIcon } from "../icons/receipt.svg";
import LoadingModal from "../components/LoadingModal";
import Button from '../components/Button';
import SearchBar from "../components/SearchBar";
import { formatCurrency } from "../tools/tool";
import InputText1 from "../components/InputText1";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import {v4 as uuidv4} from 'uuid';
import Modal from "react-modal";
import AlertModal from "../components/AlertModal";
import withAuthorization from "../components/withAuthorization";
import { jwtDecode } from "jwt-decode";
import ConfirmationDialog from "../components/ConfirmationDialog";

const CashOutRequests = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [historyData,setHistoryData] = useState([]);

  const [IsAdmin, setIsAdmin] = useState(false);
  
  const [showHistory, setShowHistory] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [cashOutStatus, setCashOutStatus] = useState('');
  const [pendingData, setPendingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [adminName, setAdminName] = useState("");
  const [referenceNo, setReferenceNo] = useState('');

  const [selected, setSelected] = useState("pending");

  // Confirmation Dialog
  const [showConfirm, setShowConfirm] = useState(false);

  const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '90%', margin: 'auto', 
        height : '100%',
        backgroundColor:'transparent',
        border: 0
    },
  };

let columns = [
    { name: 'Transaction Date', selector: row=>new Date(row.timestamp).toLocaleString(), sortable: true,width:'200px' },
    { name: 'Reference No', selector: row=>row.ref_no, sortable: true,width:'150px' },
    { name: 'Amount', selector: row=>formatCurrency(Math.abs(row.amount)), sortable: true,width:'150px' },
    { name: 'PDL Number', selector: row=>row.pdl_number, sortable: true,width:'150px' },
    { name: 'PDL Name', selector: row=>row.pdlName, sortable: true,width:'150px' },
    { name: 'Status', selector: row=>row.current_status, sortable: true,width:'150px' },
    { name: 'Remarks', selector: row=>row.remarks, sortable: true,width:'460px' },
  ]

  useEffect(()=>{
    setLoading(true);
    CORequests();

    var token = localStorage.getItem("appToken");
    setIsAdmin(jwtDecode(token)["isAdmin"] === "1");
    setAdminName(jwtDecode(token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]);

  },[]);

  

  const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'CashOutRequests.csv'); link.click(); };

  const CORequests = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('cash_out_request',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);

      let completed = r.data.filter(item => item.current_status === "Completed");
      let pending = r.data.filter(item => item.current_status != "Completed");

      setPendingData(pending);
      setCompletedData(completed);

      setFilteredData(pending);

      let gT = pending.reduce((total, item) => total + item.amount, 0)
      setGrandTotal( gT ) ;

      
    // setColumns(_col);
    setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }

  const CORequestRange = async ()=>{
    var form = new FormData();
    form.append('params',`'${dateFrom}','${dateTo}'`);
    await adminServices.storedProcedure('cash_out_requests_range',form).then((r)=>{
      setKioskSales(null);
      
      let completed = r.data.filter(item => item.current_status === "Completed");
      let pending = r.data.filter(item => item.current_status != "Completed");

      setPendingData(pending);
      setCompletedData(completed);

      setKioskSales(pending);
      setData(pending);
      setFilteredData(pending);

      let gT = pending.reduce((total, item) => total + item.amount, 0)
      setGrandTotal( gT ) ;

      
      setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }

  const COHistory = async (ref_no)=>{
    setLoading(true);
    var form = new FormData();
    setReferenceNo(ref_no);
    form.append('params',`'${ref_no}'`);
    await adminServices.storedProcedure('cash_out_request_history',form).then((r)=>{
      

      if(r.data.length > 0){
        setHistoryData(r.data);
        setCashOutStatus(r.data[r.data.length-1].description);
        return;
      }
      setHistoryData([]);
      setCashOutStatus("Pending");

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    }).finally(()=>{
      setLoading(false);
    })

  }

  const handleClose = ()=>{  
    
    setShowAlert(false);

    if(isSuccess){ 
      window.location.href = "/cashout-requests";
    }
  }

  const handleRowClicked = async (row) => {
      await COHistory(row.ref_no);
      setSelectedRow(row);
      setShowHistory(true);
  };

  return (
    <div>
      <ConfirmationDialog show={showConfirm} title={"Cancel Cash Out Request"} message={
        "Are you sure you want to cancel this cash out request?"
      } onCancel={()=>{
        setShowConfirm(false);
      }} onConfirm={ async ()=>{
        setLoading(true);

        var formData = new FormData();
        formData.append('params',`'${referenceNo}','${adminName}'`);

        await adminServices.storedProcedure('ProcessTransaction',formData).then((r)=>{
          setLoading(false);
          setShowConfirm(false);
          if(r.data[0].code == 409){
            setAlertMessage(r.data[0].message);
            setShowAlert(true);
            
            setIsSuccess(false);
            return;
          }

          setShowAlert(true);
          setAlertMessage("Cash Out Request has been cancelled successfully.");
          setIsSuccess(true);
          
        }).catch(e=>{
          if(e.status == 401){
            window.location.href = "/";
          }
        })


      }} />
      <AlertModal show={showAlert} title="Cash Out Requests" message={alertMessage} onClose={handleClose} />
      <LoadingModal show={loading} message={"Loading..."}/>
      <Modal 
        isOpen={showHistory}
        style={customStyles}
        onRequestClose={()=>setShowHistory(false)}
      >
        <div className="card">
          <div className="card-header">Cash Out Request {selectedRow && selectedRow.pdlName}</div>
          <div className="card-body">
            <div className="row">
                <div className="col-md-6 fw-bold">Requested Date</div>
                <div className="col-md-6">{selectedRow && new Date(selectedRow.timestamp).toLocaleString()}</div>
            </div>
            <div className="row">
                <div className="col-md-6 fw-bold">Reference No</div>
                <div className="col-md-6">{selectedRow && selectedRow.ref_no}</div>
            </div>
            <div className="row">
                <div className="col-md-6 fw-bold">Current Status</div>
                <div className="col-md-6">{selectedRow && historyData.length == 0 ? "Pending" : cashOutStatus}</div>
            </div>
            <div className="row">
                <div><hr/></div>
            </div>
            <div className="row">
                <DataTable
                    columns={[
                      {name: 'Transaction Date', selector: row=>new Date(row.timestamp).toLocaleString(), sortable: true,width:'200px' },
                      {name: 'Description', selector: row=>row.description, sortable: true,width:'150px' },
                      {name: 'Remarks', selector: row=>row.remarks, sortable: true }
                    ]}
                    striped
                    pointerOnHover
                    data={historyData}
                 />
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <div className="btn-group">
              {IsAdmin && cashOutStatus &&  cashOutStatus == "Pending" &&  <Button variant={"danger"} size={"sm"} onClick={()=>
                {
                  setShowConfirm(true);
                }
              }>
                Cancel Cash Out Request
              </Button>}
              <Button variant={"primary"} size={"sm"} onClick={()=>setShowHistory(false)}>Close</Button>
            </div>
            
          </div>
        </div>
      </Modal>
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'space-between',alignItems:'center'}}>
        <div className="d-flex align-items-center">
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
         PDL Cash Out Requests ({formatCurrency(Math.abs(grandTotal))})
        </div>

         <div className="d-flex justify-content-end">
            {/* <Button variant={"primary"} size={"lg"} onClick={handleTransfer}> Cash Out Request </Button> */}
          </div>
      </div>

      <div className="flexRow" style={{width:'50%'}}>
          <InputText1 title={"Date From"} type="date" value={dateFrom} onChange={(e)=>{
            setDateFrom(e.target.value);
          }}  />

         <InputText1 title={"Date To"} type="date" value={dateTo} onChange={async(e)=>{
            setDateTo(e.target.value);
          }}  />

          <div style={{marginTop:18}}>
            <Button variant={"primary"} onClick={CORequestRange}>Search</Button>
          </div>

      </div>

      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Cash Out Requests"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);

              let gT = filtered.reduce((total, item) => total + item.amount, 0)
              setGrandTotal( gT ) ;
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <div>
          <Button variant={selected == "pending" ? "primary" : "secondary"} size={'xs'} onClick={()=>{

            setFilteredData(pendingData);
            setData(pendingData);
            let gT = pendingData.reduce((total, item) => total + item.amount, 0)
            setGrandTotal( gT ) ;

            setSelected("pending");

          }}> Pending / Cash Out Approved </Button>&nbsp;
          <Button variant={selected == "completed" ? "primary" : "secondary"} size={'xs'} onClick={()=>{

            setFilteredData(completedData);
            setData(completedData);
            let gT = completedData.reduce((total, item) => total + item.amount, 0)
            setGrandTotal( gT ) ;

            setSelected("completed");

          }}> Completed </Button>&nbsp;
          <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
        </div>
      </div>
        <br/>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination={false}
          persistTableHead
          highlightOnHover
          style={{zIndex:0}}
          pointerOnHover
          striped
          onRowClicked={handleRowClicked}

        />
      
    </div>
  );
};

export default withAuthorization(CashOutRequests,24);
