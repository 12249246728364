import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import { ReactComponent as TransactionIcon } from "../icons/receipt.svg";
import LoadingModal from "../components/LoadingModal";
import Button from '../components/Button';
import SearchBar from "../components/SearchBar";
import { formatCurrency } from "../tools/tool";
import InputText1 from "../components/InputText1";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";
const KioskReports = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  
  let columns = [
    { name: 'ID', selector: row=>row.sequence, sortable: true, width: "70px" },
    { name: 'Transaction Date', selector: row=>new Date(row.reg_date).toLocaleString(), sortable: true },
    { name: 'Voucher Number', selector: row=>row.voucher_number, sortable: true },
    { name: 'PDL Name', selector: row=>row.pdl_name, sortable: true },
    { name: 'Service', selector: row=>row.service, sortable: true, width: "150px" },
    { name: 'Net Amount', selector: row=>formatCurrency(row.price), sortable: true, right: true,
    headerStyle: {
      textAlign: 'center',
    }, },
    { name: 'Service Fee', selector: row=>formatCurrency(row.service_fee), sortable: true, right: true,
      headerStyle: {
        textAlign: 'center',
      }, },
    { name: 'Gross Amount', selector: row=>formatCurrency(row.total_price), sortable: true, right: true,
      headerStyle: {
        textAlign: 'center',
      }, },
    
    { name: 'Merchant Name', selector: row=>row.merchant_name, sortable: true }
]
  

  useEffect(()=>{
    setLoading(true);
    KioskSales();

  },[]);

  

  const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'KioskSalesReport.csv'); link.click(); };

  const KioskSales = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('kiosk_sales_report',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      let gT = r.data.reduce((total, item) => total + item.total_price, 0)
      setGrandTotal( gT ) ;

    setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }

  const KioskRange = ()=>{
    var form = new FormData();
    form.append('params',`'${dateFrom}','${dateTo}'`);
    adminServices.storedProcedure('kiosk_sales_report_range',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      let gT = r.data.reduce((total, item) => total + item.total_price, 0)
      setGrandTotal( gT ) ;

    setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }

  return (
    <div>
      <LoadingModal show={loading} message={"Loading Kiosk Sales Report..."}/>
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
         Kiosk Sales Report ({formatCurrency(grandTotal)})
      </div>

      <div className="flexRow" style={{width:'50%'}}>
          <InputText1 title={"Date From"} type="date" value={dateFrom} onChange={(e)=>{
            setDateFrom(e.target.value);
          }}  />

         <InputText1 title={"Date To"} type="date" value={dateTo} onChange={async(e)=>{
            setDateTo(e.target.value);
          }}  />

          <div style={{marginTop:18}}>
            <Button variant={"primary"} onClick={KioskRange}>Search</Button>
          </div>

      </div>

      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Kiosks Sales Report"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);

              let gT = filtered.reduce((total, item) => total + item.total_price, 0)
              setGrandTotal( gT ) ;
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
      </div>
        <br/>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination={false}
          persistTableHead
          persistTableBody
          striped
          
          highlightOnHover
          style={{zIndex:0}}
        />
      
    </div>
  );
};

export default withAuthorization(KioskReports,5);
