import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import { ReactComponent as TransactionIcon } from "../icons/receipt.svg";
import LoadingModal from "../components/LoadingModal";
import Button from '../components/Button';
import SearchBar from "../components/SearchBar";
import { formatCurrency } from "../tools/tool";
import InputText1 from "../components/InputText1";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import {v4 as uuidv4} from 'uuid';
import Modal from "react-modal";
import AlertModal from "../components/AlertModal";
import withAuthorization from "../components/withAuthorization";
import MerchantList from "../components/MerchantList";
const IPXTransactions = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  
  const [makeTransfer, setMakeTransfer] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [formSeqNo, setFormSeqNo] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isValidated, setIsValidate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [pdlNumber, setPdlNumber] = useState('');
  const [remark, setRemark] = useState('');

  const [merchantName, setMerchantName] = useState('');
  const [pdlName, setPdlName] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [openMerchant, setOpenMerchant] = useState(false);

  const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        
    }, 
    content: {
        width: '30%', margin: 'auto', 
        height : '90%',
        backgroundColor:'transparent',
        border: 0
    },
  };

  useEffect(()=>{
    setLoading(true);
    IpxTransactions();

  },[]);

  

  const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'IpxTransactions.csv'); link.click(); };

  const IpxTransactions = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('ipx_transactions_report',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      let gT = r.data.reduce((total, item) => total + item.amount, 0)
      setGrandTotal( gT ) ;

      var _col = [
        { name: 'Transaction Date', selector: row=>new Date(row.transaction_date).toLocaleString(), sortable: true,width:'250px' },
        { name: 'Reference No', selector: row=>row.ref_no, sortable: true,width:'150px' },
        { name: 'Amount', selector: row=>formatCurrency(row.amount), sortable: true,width:'200px',right:true },
        { name: 'Remarks', selector: row=>row.remarks, sortable: true },
      ]
    setColumns(_col);
    setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }
  

  const KioskRange = ()=>{
    var form = new FormData();
    form.append('params',`'${dateFrom}','${dateTo}'`);
    adminServices.storedProcedure('ipx_transactions_report_range',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      let gT = r.data.reduce((total, item) => total + item.amount, 0)
      setGrandTotal( gT ) ;

      var _col = [
        { name: 'Transaction Date', selector: row=>new Date(row.transaction_date).toLocaleString(), sortable: true,width:'250px' },
        { name: 'Reference No', selector: row=>row.ref_no, sortable: true,width:'150px' },
        { name: 'Amount', selector: row=>formatCurrency(row.amount), sortable: true,width:'200px',right:true },
        { name: 'Remarks', selector: row=>row.remarks, sortable: true },
      ]
    setColumns(_col);
    setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }

  const handleSelectedRow = (e)=>{
    setMerchantName(e.merchant_name);
    setPdlName(e.pdl_name);
    setMerchantId(e.id);
    setOpenMerchant(false);
  }

  const handleTransfer = ()=>{
        setMakeTransfer(true);
    }
  
    const validateForm = ()=>{

      if(merchantId === ""){
        setIsValidate(false);
        setValidationMessage("Select a merchant to transfer funds");
        return false;
      }
      
      if(transferAmount <= 0){
        setIsValidate(false);
        setValidationMessage("Transfer Amount should be at least 1");
        return false;
      }
  
      if(formSeqNo === ""){
        setIsValidate(false);
        setValidationMessage("Form Sequence Number Required.");
        return false;
      }
  
      return true;
    }
  
    const handleSubmit = async()=>{
        
  
        if(validateForm()){
          setLoading(true);
          const formData = new FormData();
  
          let uuid = uuidv4();
          let refNo = uuid.split('-')[4];
          let remarks = `Transfer to Merchant; Remarks:${remark}`;
          formData.append('params',`${merchantId},'${refNo.toLocaleUpperCase()}',${transferAmount},'${formSeqNo}','${remarks}'`);
          
          await adminServices.storedProcedure('IPXFundTransfer',formData).then(async response => {
            if(response.data[0].STATUS == "202"){
              
              setIsSuccess(true);
              setShowAlert(true);
              setAlertMessage("Transfer Funds to Merchant Success. Transaction Ref: " + refNo.toUpperCase());
            }
            else{
              setShowAlert(true);
              setAlertMessage("Error while transferring funds to IPX. Please try again later.");
              setIsSuccess(false);
            }
          }).catch(error => {
            if(error.status === 401){
              window.location.href = "/";
            }
          }).finally(()=>{
            setMakeTransfer(false);
            setLoading(false);
          });
        }
  
        
    }
    
    const handleClose = ()=>{  
      
      setShowAlert(false);
  
      if(isSuccess){ 
        window.location.href = "/ipx-transactions";
      }
    }

  return (
    <div>
      <MerchantList
      token={localStorage.getItem('appToken')}
        isOpen={openMerchant}
        onClose={()=>{setOpenMerchant(false)}}
        selectedInfo={handleSelectedRow}
        />
      <AlertModal show={showAlert} title="IPX Transactions" message={alertMessage} onClose={handleClose} />
      <LoadingModal show={loading} message={"Loading Kiosk Sales Report..."}/>
      <Modal style={customStyles} isOpen={makeTransfer} onRequestClose={()=>{ setMakeTransfer(false); }}>
            <div className="card">
                <div className="card-header">Transfer to Merchant Wallet</div>
                <div className="card-body d-flex flex-column align-item-center">

                    <div className=" d-flex flex-column justify-content-center mb-5 mt-3">
                        <Button className="w-50" variant={"primary"} onClick={()=>{ setOpenMerchant(true); }}>Select Merchant</Button>

                        <div className="text-primary justify-content-center mt-3">
                          {merchantName}
                        </div>
                    </div>

                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Amount to Transfer"} onChange={(e)=>{
                          setValidationMessage("");
                          setIsValidate(true);
                          if(e.target.value == "e"){
                            setIsValidate(false);
                            setValidationMessage("Invalid Amount");
                          }

                          if(e.target.value == ""){
                            setIsValidate(false);
                            setValidationMessage("Transfer Amount should be at least 1");
                          }

                          if(e.target.value <=0){
                            setIsValidate(false);
                            setValidationMessage("Transfer Amount should be at least 1");
                          }

                          if(e.target.value > grandTotal){
                            setIsValidate(false);
                            setValidationMessage("Transfer Amount cannot be greater than the wallet");
                          }

                          setTransferAmount(e.target.value);

                      }} value={transferAmount} type="number" />
                    </div>

                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Form Sequence Number"} onChange={(e)=>{
                          const regex = /^[a-zA-Z0-9]*$/;
                          if (regex.test(e.target.value)) {
                            setFormSeqNo(e.target.value.toUpperCase());
                          }
                          
                      }} value={formSeqNo} />
                    </div>

                    <div className=" d-flex flex-column justify-content-center">
                      <InputText1 title={"Remarks"} onChange={(e)=>{
                          setRemark(e.target.value);
                      }} value={remark} placeholder={"(Optional)"} />
                    </div>

                    <div className="text-danger">
                        {!isValidated && validationMessage}
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                    <Button variant={"success"} onClick={handleSubmit}>Submit</Button>
                    &nbsp;
                    <Button variant={"danger"} onClick={()=>{ 
                       setMakeTransfer(false);
                    }}>Cancel</Button>
                </div>
            </div>
        </Modal>
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'space-between',alignItems:'center'}}>
        <div className="d-flex align-items-center">
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
         IPX Transactions ({formatCurrency(grandTotal)})
        </div>

         <div className="d-flex justify-content-end">
            <Button variant={"primary"} size={"lg"} onClick={handleTransfer}> Transfer to Merchant </Button>
          </div>
      </div>

      <div className="flexRow" style={{width:'50%'}}>
          <InputText1 title={"Date From"} type="date" value={dateFrom} onChange={(e)=>{
            setDateFrom(e.target.value);
          }}  />

         <InputText1 title={"Date To"} type="date" value={dateTo} onChange={async(e)=>{
            setDateTo(e.target.value);
          }}  />

          <div style={{marginTop:18}}>
            <Button variant={"primary"} onClick={KioskRange}>Search</Button>
          </div>

      </div>

      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search IPX Transactions"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);

              let gT = filtered.reduce((total, item) => total + item.amount, 0)
              setGrandTotal( gT ) ;
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
      </div>
        <br/>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
        />
      
    </div>
  );
};

export default withAuthorization(IPXTransactions,23);
