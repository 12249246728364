import React from "react";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import {ReactComponent as ItemIcon} from "../icons/item.svg";
import {ReactComponent as SupplierIcon} from "../icons/supplier.svg";
import {ReactComponent as PurchaseIcon} from "../icons/purchase.svg";
import {ReactComponent as MeasureIcon} from "../icons/measure.svg"; 
import {ReactComponent as DeliveryIcon} from "../icons/delivery.svg";
import Button1 from "../components/Button1";
import DataTable from "react-data-table-component";
import { adminServices } from "../services/AdminServices";
import Button2 from "../components/Button2";
import ItemsComponent from "../components/inventory/Items";
import SupplierComponent from "../components/inventory/Suppliers";
import UnitsOfMeasuresComponent from "../components/inventory/UnitsOfMeasures";
import PurchaseRequestComponent from "../components/inventory/PurchaseRequests";
import UnauthorizedComponent from "../components/UnauthorizedComponent";
import withAuthorization from "../components/withAuthorization";

const InventoryPage = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPage, setSelectedPage] = useState("items");

  useEffect(()=>{

  },[]);


  return (
    <div className="mainContainer">
      <div className="flexRow contentHeader">
        {/* ADD PRODUCT MODAL */}
        
        <div className="flexColumn">
          <p className="xxl semibold" style={{ width: "100%" }}>
            Inventory
          </p>
          <p className="r regular">
            Manage items, suppliers, purchase requests , and purchase orders.
          </p>
        </div>
        <div className="spacer"></div>
        <SupplierIcon onClick={()=>{ setSelectedPage('suppliers') }} class={selectedPage === 'suppliers'? 'inv-icon active' : 'inv-icon'} title="Suppliers" />
        <MeasureIcon onClick={()=>{ setSelectedPage('measurements') }} class={selectedPage === 'measurements'? 'inv-icon active' : 'inv-icon'} title="Unit of Measure" />
        <ItemIcon onClick={()=>{ setSelectedPage('items') }} class={selectedPage === 'items'? 'inv-icon active' : 'inv-icon'} title="Items" />
        <PurchaseIcon onClick={()=>{ setSelectedPage('purchase') }} class={selectedPage === 'purchase'? 'inv-icon active' : 'inv-icon'} title="Purchase Order" />
        <DeliveryIcon onClick={()=>{ setSelectedPage('deliveries') }} class={selectedPage === 'deliveries'? 'inv-icon active' : 'inv-icon'} title="Deliveries" />
      </div>
      <div style={{ height: "25px" }}></div>

      {selectedPage === 'items' && <ItemsComponent />}
      {selectedPage === 'suppliers' && <SupplierComponent />}
      {selectedPage === 'measurements' && <UnitsOfMeasuresComponent />}
      {selectedPage === 'purchase' && <PurchaseRequestComponent />} 
      
      {/* Table Footer */}
      
    </div>
  );
};

export default withAuthorization(InventoryPage,12);
